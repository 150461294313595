import { React, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./TextEditor.scss";

const TextEditor = ({
  name,
  handleFieldChange,
  value,
  isInvalid,
  placeholder,
  allowMedia = true,
  height = "200px",
}) => {
  const refEditor = useRef();
  const focusTextEditor = () => {
    refEditor.current.focus();
  };

  let toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],
    ["clean"], // remove formatting button
  ];

  if (allowMedia) {
    toolbarOptions.push(["link", "image", "video"]);
  }

  const modules = {
    toolbar: toolbarOptions,
  };

  return (
    <div onClick={focusTextEditor}>
      <ReactQuill
        ref={refEditor}
        theme="snow"
        placeholder={placeholder ? placeholder : ""}
        name={name ?? "text-editor"}
        value={value}
        onChange={(value, _, __) => {
          handleFieldChange(null, {
            action: "text-editor",
            name: name,
            value: value,
          });
        }}
        modules={modules}
        className={`custom-text-editor d-flex flex-column ${
          isInvalid ? "is-invalid" : ""
        }`}
        style={{
          minHeight: height,
          ...(isInvalid ? { border: "1px solid #dc3545" } : {}),
        }}
      />
    </div>
  );
};

export default TextEditor;
