import { removeHtmlTagsFromString, stringLimiter } from "helpers/utils";
import getTaskStatusWithProps from "module/common/helpers/getTaskStatusWithProps";
import UserProfile from "module/common/Offcanvas/UserProfile/UserProfile";
import { GetAcodaxPermission } from "module/common/Permissions/AcodaxPermission";
import React, { useState } from "react";
import { Badge, Col, Row } from "react-bootstrap";
import { AiOutlineCodeSandbox } from "react-icons/ai";
import { BiTask } from "react-icons/bi";
import { FaCalendarAlt, FaUserGraduate } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function CommonTaskRows({
  key,
  task,
  handleTaskInfo,
  isAlert = false,
  isMargin = true,
  usedFor = "employee",
}) {
  const permission = {
    showProject: GetAcodaxPermission("PROJECT", "show"),
  };
  let navigate = useNavigate();
  const [assigneeData, setAssigneeData] = useState({
    show: false,
    id: "",
  });

  const handleNavigation = (type) => {
    if (type === "project" && permission?.showProject) {
      navigate(`/project/info/${task?.project_id}?tab=overview`);
    } else if (type === "assignee") {
      setAssigneeData({
        show: true,
        id: task?.assigned_to,
      });
    }
  };

  return (
    <>
      <Row
        key={key}
        className={`shadow m-0 p-0 mb-${isMargin ? "3" : ""}`}
        style={{
          color: "#000",
          borderRadius: "0.5rem",
          borderLeft: `${
            isAlert
              ? `5px solid ${task.is_passed_end_date ? "#fc353c" : "#00D27A"}`
              : `5px solid #FB8B24`
          }`,
        }}
      >
        <Col
          lg={usedFor === "ticket" ? 3 : 4}
          md={usedFor === "ticket" ? 3 : 4}
          sm={usedFor === "ticket" ? 3 : 4}
          className="p-2"
          style={{
            color: "#000",
            borderRadius: "0.5rem 0 0 0.5rem",
          }}
        >
          <div>
            <span className="custom-text-black-crm">
              <BiTask size={15} color="#FFA38F" className="mb-1 me-1" />
              Task
            </span>
            <div
              className="fw-bold custom-text-black-crm cursor-pointer link-like-text"
              onClick={() => {
                task?.id && handleTaskInfo(task);
              }}
            >
              #{task?.task_number} {stringLimiter(task?.title, 28)}
            </div>
            <div className="fs--2 text-wrap">
              {usedFor === "ticket" ? (
                <Badge
                  bg="none"
                  className="fw-bold cursor-pointer text-capitalize"
                  style={{
                    fontSize: "11px",
                    backgroundColor: getTaskStatusWithProps(task?.status),
                  }}
                >
                  {task?.status?.replace("_", " ")}
                </Badge>
              ) : (
                <div
                  className="setInnerHtml"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {removeHtmlTagsFromString(task?.description)}
                </div>
              )}
            </div>
          </div>
        </Col>
        {usedFor === "ticket" && (
          <Col
            lg={2}
            md={2}
            sm={2}
            className="p-2"
            style={{
              color: "#000",
              borderRadius: "0.5rem 0 0 0.5rem",
            }}
          >
            <div>
              <span className="custom-text-black-crm">
                <FaUserGraduate
                  size={15}
                  color="#FFB22C"
                  className="mb-1 me-1"
                />
                Assignee
              </span>
              <div
                className="fw-bold custom-text-black-crm cursor-pointer link-like-text"
                onClick={() => {
                  task?.assigned_to && handleNavigation("assignee");
                }}
              >
                {task?.assignee_full_name}
              </div>
            </div>
          </Col>
        )}
        <Col
          lg={usedFor === "ticket" ? 3 : 4}
          md={usedFor === "ticket" ? 3 : 4}
          sm={usedFor === "ticket" ? 3 : 4}
          className="p-2"
          style={{
            color: "#000",
          }}
        >
          <div>
            <span className="custom-text-black-crm">
              <AiOutlineCodeSandbox
                size={17}
                color="#E4003A"
                className="mb-1 me-1"
              />
              Project
            </span>
            <div
              className="fw-bold custom-text-black-crm cursor-pointer link-like-text"
              onClick={() => {
                task?.project_id && handleNavigation("project");
              }}
            >
              {task?.project_title}
            </div>
          </div>
        </Col>
        <Col
          lg={2}
          md={2}
          sm={2}
          className="p-2"
          style={{
            color: "#000",
          }}
        >
          <div>
            <span className="custom-text-black-crm">
              <FaCalendarAlt size={12} className="me-1 mb-1" color="#0A6847" />
              Start Date
            </span>
            {!usedFor === "ticket" ? (
              <div className="fw-bold custom-text-black-crm">
                {task.start_date_formatted}
              </div>
            ) : (
              <div className="fw-bold custom-text-black-crm">
                {task.start_date}
              </div>
            )}
          </div>
        </Col>
        <Col
          lg={2}
          md={2}
          sm={2}
          className="p-2"
          style={{
            color: "#000",
            borderRadius: "0 0.5rem 0.5rem 0",
          }}
        >
          <div>
            <span className="custom-text-black-crm">
              <FaCalendarAlt size={12} className="me-1 mb-1" color="#FF6D28" />
              End Date
            </span>
            <div>
              {task.is_passed_end_date && isAlert ? (
                <Badge
                  bg="danger"
                  className="fw-bold"
                  style={{ fontSize: "13px", padding: "4px" }}
                  title="Passed the end date"
                >
                  {task.end_date_formatted}
                </Badge>
              ) : (
                <>
                  {!usedFor === "ticket" ? (
                    <div className="fw-bold custom-text-black-crm">
                      {task.end_date_formatted}
                    </div>
                  ) : (
                    <div className="fw-bold custom-text-black-crm">
                      {task.end_date}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </Col>
      </Row>
      <UserProfile
        show={assigneeData?.show}
        handleClose={() =>
          setAssigneeData({
            show: false,
            id: "",
          })
        }
        id={assigneeData?.id}
      />
    </>
  );
}

export default CommonTaskRows;
