import { AuthWizardContext } from "context/Context";
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";

const RequireGuest = ({ children }) => {
  let { user } = useContext(AuthWizardContext);
  return user ? (
    user?.customer_id ? (
      <Navigate to="/project" />
    ) : (
      <Navigate to="/dashboard" />
    )
  ) : (
    children
  );
};

RequireGuest.propTypes = {
  children: PropTypes.element.isRequired,
};

export default RequireGuest;
