import { React, useState } from "react";
import { Card, Col, ListGroup, Row, Tab, Tabs } from "react-bootstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegAddressCard } from "react-icons/fa";
import { IoMdContact } from "react-icons/io";
import { AiOutlineCodeSandbox } from "react-icons/ai";
import { apiCall } from "helpers/apiCalls";
import qs from "qs";
import { BsDatabaseFillExclamation } from "react-icons/bs";
import LoadingCommon from "components/common/loading-screen/LoadingCommon";
import ContactCard from "./ContactCard";
import CustomerProjectCard from "./CustomerProjectCard";
import useAxisproTranslate from "hooks/useAxisproTranslate";

const CustomerDetailsTab = ({ data, itemId }) => {
  const Translate = useAxisproTranslate();
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [projectsTabClicked, setProjectsTabClicked] = useState(false);

  const getProjectData = async (page = 1) => {
    setLoading(true);

    const params = {
      page: page,
      requested_from: "account",
      account_id: itemId,
    };

    try {
      const projectsData = await apiCall({
        url: `crm/project`,
        params: params,
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      });
      setProjects(projectsData);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleProjectsTabClick = () => {
    if (!projectsTabClicked) {
      setProjectsTabClicked(true);
      getProjectData();
    }
  };

  return (
    <Tabs
      defaultActiveKey={data.address ? 1 : 2}
      className="custom-lead-offcanvas-header-tabs mt-1"
      variant="tabs"
    >
      {data.address ? (
        <Tab
          eventKey={1}
          title={
            <div className="d-flex align-items-center">
              <FaRegAddressCard size={15} />
              <span className="ms-2">{Translate("Address")}</span>
            </div>
          }
        >
          <ul className="p-3  m-0 customer-info-tab-content">
            <li className="d-flex flex-row">
              <FontAwesomeIcon
                icon="address-card"
                size="sm"
                className="me-2 mt-1"
                color="#00d27a"
              />
              <div>
                <h5>{Translate("BILLING ADDRESS")}</h5>
                <p className="text-muted mt-2">{data.address}</p>
              </div>
            </li>
            <li className="d-flex flex-row">
              <FontAwesomeIcon
                icon="shipping-fast"
                size="sm"
                className="me-2 mt-1"
                color="#f5803e"
              />
              <div>
                <h5>{Translate("SHIPPING ADDRESS")}</h5>
                <p className="text-muted mt-2">{data.address}</p>
              </div>
            </li>
          </ul>
        </Tab>
      ) : (
        " "
      )}
      <Tab
        eventKey={2}
        title={
          <div className="d-flex align-items-center">
            <IoMdContact size={15} color="#FFA38F"/>
            <span className="ms-1">{Translate("Contacts")}</span>
          </div>
        }
      >
        <div
          className={`rounded-0 p-3`}
          style={{
            height: "58vh",
            overflow: "auto",
          }}
        >
          <Row className="w-100 gy-3 gx-3">
            {data.contacts.length > 0 ? (
              data.contacts.map((item, index) => (
                <ContactCard key={index} item={item} />
              ))
            ) : (
              <div className="h-100 d-flex align-items-center justify-content-center">
                No contact Information! 🙂
              </div>
            )}
          </Row>
        </div>
      </Tab>
      <Tab
        eventKey={3}
        title={
          <div className="d-flex align-items-center">
            <AiOutlineCodeSandbox size={17} color="#E4003A"/>{" "}
            <span className="ms-1 fw-bold">{Translate("Projects")}</span>
          </div>
        }
        onEnter={handleProjectsTabClick}
      >
        <div
          className="p-3 pb-2"
          style={{
            height: "60vh",
            overflow: "auto",
          }}
        >
          {!loading ? (
            <Row className="gy-3 gx-3">
              {projects?.data?.length > 0 ? (
                projects.data.map(
                  (el, index) =>
                    index < 6 && (
                      <CustomerProjectCard
                        key={index}
                        item={el}
                        index={index}
                      />
                    )
                )
              ) : (
                <Col className="p-0 m-0 mt-2">
                  <Card className="rounded-1 shadow-none">
                    <Card.Body>
                      <ListGroup.Item className="rounded-0 border">
                        <div className="h-100 p-3 d-flex flex-column justify-content-center align-items-center">
                          <BsDatabaseFillExclamation
                            size={40}
                            className="text-mute"
                          />
                          <span>{Translate("No Projects Found!")}</span>
                        </div>
                      </ListGroup.Item>
                    </Card.Body>
                  </Card>
                </Col>
              )}
            </Row>
          ) : (
            <div
              className="h-100 d-flex justify-content-center 
              align-items-center"
            >
              <LoadingCommon loadingText="Loading Projects" />
            </div>
          )}
        </div>
      </Tab>
    </Tabs>
  );
};

CustomerDetailsTab.propTypes = {
  data: PropTypes.object,
  activity: PropTypes.any,
  activityLoading: PropTypes.bool,
  setActivity: PropTypes.func,
  links: PropTypes.any,
  setLinks: PropTypes.func,
};

export default CustomerDetailsTab;
