import { React, useEffect, useState, useContext } from "react";
import {
  createSearchParams,
  useParams,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import { Card, Tab, Tabs } from "react-bootstrap";
import { AiOutlineCodeSandbox, AiOutlineTransaction } from "react-icons/ai";
import { apiCall } from "helpers/apiCalls";
import { AuthWizardContext } from "context/Context";
import OverViewTab from "./OverviewTab/OverViewTab";
import CommentTab from "./CommentTab/CommentTab";
import LoadingCommon from "components/common/loading-screen/LoadingCommon";
import useAxisproPermission from "hooks/useAxisproPermission";
import defaultTaskStatusFilter from "module/common/helpers/defaultTaskStatusFilterKeys";
import SprintTable from "module/Sprints/SprintTable/SprintTable";
import TransactionTab from "./TransactionTab/TransactionTab";
import ContractTab from "./ContractTab/ContractTab";
import AttachmentsTab from "module/common/Attachments/AttachmentsTab";
import { CustomBackButton } from "module/common/Buttons/AppButtons";
import { MODULES } from "constants/Constants";
import TaskTable from "module/Task/TaskTable/TaskTable";
import { MdAttachFile } from "react-icons/md";
import { FaWpforms } from "react-icons/fa";
import { BiComment, BiSupport, BiTask } from "react-icons/bi";
import { BsCalendar2Range } from "react-icons/bs";
import { GrOverview } from "react-icons/gr";
import { RxActivityLog } from "react-icons/rx";
import Activity from "module/Leads/Lead/LeadInfo/Overview/Activity";
import TicketTable from "../../Tickets/TicketTable/TicketTable";

function ProjectInfo() {
  const user = useContext(AuthWizardContext);
  const loggedUsCustomer = user?.user?.customer_id ? true : false;
  const [queryParams] = useSearchParams();
  const { itemInfoId } = useParams();
  const activeTab = queryParams.get("tab");
  const [isLoading, setIsLoading] = useState(false);
  const [project, setProject] = useState({});
  const [todo, setTodo] = useState([]);
  const [taskApiCalling, setTaskApiCalling] = useState(false);
  const [membersList, setMembersList] = useState([]);
  const navigate = useNavigate();
  const axisProPermission = useAxisproPermission();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const actualRoute = localStorage.getItem("actualRoute");
 

  const getData = async () => {
    setIsLoading(true);
    try {
      const data = await apiCall({
        url: `crm/project/${itemInfoId}`,
      });
      setProject(data);
      setIsLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getTask = async () => {
    setTaskApiCalling(true);
    try {
      const data = await apiCall({
        url: `crm/task`,
        params: {
          project_id: itemInfoId,
          "status_filter[0]": "to_do",
        },
      });
      setTodo(data?.data);
      setTaskApiCalling(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getData();
    axisProPermission("show-task") && getTask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabSwitch = (tab) => {
    let navigateUrl;
    if (tab === "tasklist") {
      let adjustedDefaultFilterTasks = {
        ...allQueryParams,
        tab: "tasklist",
        status_filter: defaultTaskStatusFilter.join("/"),
        status_filter_ref: JSON.stringify(
          defaultTaskStatusFilter.map((item) => ({
            label: item?.replaceAll("_", " "),
            value: item,
          }))
        ),
        page: 1,
      };

      if (loggedUsCustomer) {
        delete adjustedDefaultFilterTasks.status_filter;
        delete adjustedDefaultFilterTasks.status_filter_ref;
      }

      navigateUrl = `?${createSearchParams(adjustedDefaultFilterTasks)}`;
    } else if (tab === "tickets") {
      let adjustedDefaultFilterTickets = {
        ...allQueryParams,
        tab: "tickets",
        status: "all",
      };
      delete adjustedDefaultFilterTickets.status_filter;
      delete adjustedDefaultFilterTickets.status_filter_ref;

      navigateUrl = `?${createSearchParams(adjustedDefaultFilterTickets)}`;
    } else {
      navigateUrl = `?tab=${tab}`;
    }
    activeTab !== tab && navigate(navigateUrl);
  };

  useEffect(() => {
    !axisProPermission("show-task") &&
      loggedUsCustomer &&
      handleTabSwitch("tickets");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBackRouting = () => {
    if (actualRoute) {
      navigate(actualRoute);
      localStorage.removeItem("actualRoute"); 
    } else {
      navigate(-1); 
    }
  };

  return (
    <Card className="flex-fill mt-3 shadow-none">
      {!isLoading ? (
        project &&
        Object.keys(project).length > 0 && (
          <>
            <Card.Header className="border-bottom">
              <div className="d-flex justify-content-between align-items-center">
                <div className="fs-1 text-uppercase text-dark d-flex align-items-center">
                  <span>
                    <AiOutlineCodeSandbox size={25} className="me-1" />
                  </span>
                  {project.title}
                </div>
                {loggedUsCustomer ? null : (
                  <div>
                    <CustomBackButton
                      variant="falcon-default"
                      title="Back"
                      onClick={handleBackRouting}
                      className={"ms-2"}
                    />
                  </div>
                )}
              </div>
            </Card.Header>
            <Card.Body style={{ backgroundColor: "#F9F9F9" }} className="p-0">
              <Tab.Container id="left-tabs-example">
                <Tabs
                  defaultActiveKey="overview"
                  id="custom-header-tabs"
                  className="custom-header-tabs p-2 bg-white"
                  variant="tabs"
                  activeKey={activeTab}
                  onSelect={handleTabSwitch}
                >
                  {axisProPermission("show-task") && (
                    <Tab
                      eventKey="overview"
                      title={
                        <div className="d-flex align-items-center">
                          <GrOverview size={14} color="#3FA2F6" />
                          <span className="ms-1">Overview</span>
                        </div>
                      }
                      className="custom-empl-header-tab"
                    >
                      {activeTab === "overview" && (
                        <OverViewTab
                          project={project}
                          todo={todo}
                          loading={isLoading}
                          taskApiCalling={taskApiCalling}
                          membersList={membersList}
                          setMembersList={setMembersList}
                        />
                      )}
                    </Tab>
                  )}
                  {axisProPermission("list-sprint") ? (
                    <Tab
                      eventKey="sprintlist"
                      title={
                        <div className="d-flex align-items-center">
                          <BsCalendar2Range size={12} color="#FFA38F" />
                          <span className="ms-1">Sprints</span>
                        </div>
                      }
                      className="custom-empl-header-tab"
                    >
                      {activeTab === "sprintlist" && (
                        <SprintTable
                          projectId={itemInfoId}
                          renderFrom={MODULES.PROJECT}
                        />
                      )}
                    </Tab>
                  ) : null}
                  {axisProPermission("show-task") ? (
                    <Tab
                      eventKey="tasklist"
                      title={
                        <div className="d-flex align-items-center">
                          <BiTask size={14} color="#E4003A" />
                          <span className="ms-1">Tasks</span>
                        </div>
                      }
                      className="custom-empl-header-tab"
                    >
                      {activeTab === "tasklist" && (
                        <TaskTable
                          showHeader={true}
                          renderFrom={MODULES.PROJECT}
                        />
                      )}
                    </Tab>
                  ) : null}
                  {axisProPermission("list-ticket") ? (
                    <Tab
                      eventKey="tickets"
                      title={
                        <div className="d-flex align-items-center">
                          <BiSupport size={15} color="#FFB22C" />
                          <span className="ms-1">Tickets</span>
                        </div>
                      }
                      className="custom-empl-header-tab"
                    >
                      {activeTab === "tickets" && (
                        <TicketTable
                          renderFrom={MODULES.PROJECT}
                          projectId={project?.id}
                        />
                      )}
                    </Tab>
                  ) : null}
                  <Tab
                    eventKey="comments"
                    title={
                      <div className="d-flex align-items-center">
                        <BiComment size={14} color="#B692C2" />
                        <span className="ms-1">Comments</span>
                      </div>
                    }
                    className="custom-empl-header-tab"
                  >
                    {activeTab === "comments" && (
                      <CommentTab project={project} membersList={membersList} />
                    )}
                  </Tab>
                  {axisProPermission("report-customer-balance-statements") ? (
                    <Tab
                      eventKey="transactions"
                      title={
                        <div className="d-flex align-items-center">
                          <AiOutlineTransaction size={16} color="#88D66C" />{" "}
                          <span className="ms-1">Transactions</span>
                        </div>
                      }
                      className="custom-empl-header-tab"
                    >
                      {activeTab === "transactions" && (
                        <TransactionTab customer_id={project?.customer_id} />
                      )}
                    </Tab>
                  ) : null}
                  {axisProPermission("show-ct") ? (
                    <Tab
                      eventKey="contracts"
                      title={
                        <div className="d-flex align-items-center">
                          <FaWpforms size={15} color="#433D8B" />{" "}
                          <span className="ms-1">Contracts</span>
                        </div>
                      }
                      className="custom-empl-header-tab"
                    >
                      {activeTab === "contracts" && (
                        <ContractTab customer_id={project?.customer_id} />
                      )}
                    </Tab>
                  ) : null}
                  <Tab
                    eventKey="attachments"
                    title={
                      <div className="d-flex align-items-center">
                        <MdAttachFile size={14} color="#3FA2F6" />{" "}
                        <span className="ms-1">Attachments</span>
                      </div>
                    }
                    className="custom-empl-header-tab"
                  >
                    <AttachmentsTab
                      id={project?.id}
                      fetchData={getData}
                      media={project?.media}
                      type={"PROJECT"}
                    />
                  </Tab>
                  <Tab
                    eventKey="activity"
                    title={
                      <div className="d-flex align-items-center">
                        <RxActivityLog size={12} color="#B692C2" />{" "}
                        <span className="ms-2">Activity</span>
                      </div>
                    }
                  >
                    {activeTab === "activity" && (
                      <div className="mt-2">
                        <Activity itemId={project?.id} type="PROJECT" />
                      </div>
                    )}
                  </Tab>
                </Tabs>
              </Tab.Container>
            </Card.Body>
          </>
        )
      ) : (
        <div
          className="flex-fill d-flex align-items-center justify-content-center"
          style={{ height: "85vh" }}
        >
          <LoadingCommon loadingText="Looking for Project Details" />
        </div>
      )}
    </Card>
  );
}

export default ProjectInfo;
