import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import Avatar from "components/common/Avatar";
import Flex from "components/common/Flex";
import IconButton from "components/common/IconButton";
import React, { Fragment } from "react";
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { apiCall } from "helpers/apiCalls";
import Comments from "module/common/ViewPages/Common/Comments/Comments";
import CommonTaskRows from "module/Employee/EmployeeOverview/TabComponents/CommonTaskRows";
import TaskInfo from "module/Task/TaskInfo/TaskInfo";
import CustomerDetails from "components/customer-details/CustomerDetails";
import UserProfile from "module/common/Offcanvas/UserProfile/UserProfile";
import UpdateAssigneeListDropDown from "../UpdateAssigneeListDropDown";

const File = ({ icon, title, action, actionIcon, className, link, color }) => {
  return (
    <div
      className={classNames(
        "border p-2 rounded-3 d-flex bg-white dark__bg-1000 fs--1",
        className
      )}
    >
      <FontAwesomeIcon icon={icon} className="fs-1" style={{ color: color }} />
      <span className="ms-2 me-3">{title}</span>
      <OverlayTrigger
        placement="right"
        overlay={<Tooltip style={{ position: "fixed" }}>{action}</Tooltip>}
      >
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          className="text-300 ms-auto"
          download
        >
          <FontAwesomeIcon icon={actionIcon} />
        </a>
      </OverlayTrigger>
    </div>
  );
};

const NewColumnItem = ({
  title,
  id,
  ticketId,
  value,
  show = true,
  icon,
  edit,
  getData,
}) => {
  let navigate = useNavigate();

  const [customerData, setCustomerData] = useState({
    showCustomerInfo: false,
    value: "",
    label: "",
  });
  const [assigneeData, setAssigneeData] = useState({
    show: false,
    id: "",
  });

  const handleNavigation = () => {
    if (title === "Project:" && id) {
      navigate(`/project/info/${id}?tab=overview`);
    } else if (title === "Client:" && id) {
      setCustomerData({
        showCustomerInfo: !customerData?.showCustomerInfo,
        value: id,
        label: value,
      });
    } else if (title === "Assignee:" || title === "Created user:") {
      setAssigneeData({
        show: true,
        id: id,
      });
    }
  };

  const updateAssigneeURL = `crm/ticket/${ticketId}/update-assignee`;

  return (
    show && (
      <Col md={3} sm={6} xs={12}>
        <div className="d-flex">
          <div className="me-1 text-secondary">{icon}</div>
          <div className={`${id && "cursor-pointer"}`}>
            <div className="text-dark d-flex gap-1 align-items-center">
              {title}
              {edit && ticketId && (
                <div style={{ marginTop: "-4px" }}>
                  <UpdateAssigneeListDropDown
                    url={updateAssigneeURL}
                    getData={getData}
                    active={id}
                  />
                </div>
              )}
            </div>
            <div
              onClick={id && handleNavigation}
              className={`text-dark fw-bold text-capitalize ${
                id && "link-like-text"
              }`}
            >
              {value ? value : "N/A"}
            </div>
          </div>
        </div>

        <CustomerDetails
          show={customerData?.showCustomerInfo}
          onHide={() => {
            setCustomerData({ showCustomerInfo: false, value: "", label: "" });
          }}
          value={customerData}
          showCustomerDetailsArea={customerData?.showCustomerInfo}
        />
        <UserProfile
          show={assigneeData?.show}
          handleClose={() =>
            setAssigneeData({
              show: false,
              id: "",
            })
          }
          id={assigneeData?.id}
        />
      </Col>
    )
  );
};

const TicketOverViewConversation = ({ data, columnData, getData }) => {
  const { overviewId } = useParams();
  const [downloadLoading, setdownloadLoading] = useState(false);
  const [taskInfoData, setTaskInfoId] = useState();
  const [showTaskInfo, setShowTaskInfo] = useState(false);
  const location = useLocation();

  const handleBulkDownload = async (id, type) => {
    setdownloadLoading(true);
    const data = await apiCall({
      url: `documents/zip-download/${type}/${id}`,
    });
    const link = document.createElement("a");
    if (Object.keys(data).length > 0) {
      link.href = data.download_url;
      link.setAttribute("download", "");
      link.click();
    }
    setdownloadLoading(false);
  };

  const handleTaskInfo = (data) => {
    if (data?.id) {
      setTaskInfoId(data?.id);
      setShowTaskInfo(true);
      localStorage.setItem(
        "actualRoute",
        `${location.pathname}${location.search}`
      );
    } else {
      setTaskInfoId();
      setShowTaskInfo(false);
    }
  };

  return (
    <>
      <Card className="flex-fill">
        <Card.Header className="bg-light">
          <span className="mb-0 text-capitalize fs-1">
            <FontAwesomeIcon
              icon={
                data.ticket_origin === "email"
                  ? "envelope"
                  : data.ticket_origin === "phone"
                  ? "mobile-screen-button"
                  : "globe"
              }
              className="me-2"
            />
            {data.title}
          </span>
        </Card.Header>
        <Card.Body>
          <div>
            {data?.created_user && (
              <div className="d-md-flex d-xl-inline-block d-xxl-flex align-items-center justify-content-between mb-x1">
                <Flex alignItems="center" className="gap-2">
                  <Link to="/support-desk/contact-details">
                    <Avatar src={data.created_user.image} size="2xl" />
                  </Link>
                  <p className="mb-0">
                    <Link
                      to="/support-desk/contact-details"
                      className="fw-semi-bold mb-0 text-800"
                    >
                      {data.created_user_full_name}
                    </Link>
                    <span className="fs--2 text-800 fw-normal mx-2">
                      via {data.ticket_origin}
                    </span>
                    <Link className="mb-0 fs--1 d-block text-500">
                      {data.created_user.email}
                    </Link>
                  </p>
                </Flex>
                <p className="mb-0 fs--2 fs-sm--1 fw-semi-bold mt-2 mt-md-0 mt-xl-2 mt-xxl-0 ms-5">
                  {data.created_at}
                  <FontAwesomeIcon icon="star" className="ms-2 text-warning" />
                </p>
              </div>
            )}

            <div>
              <h6 className="mb-3 fw-semi-bold text-1000">
                {data.description}
              </h6>

              <Row className="m-0 gy-3">
                {columnData.map((item, index) => (
                  <>
                    {item?.value && (
                      <NewColumnItem key={index} {...item} getData={getData} />
                    )}
                  </>
                ))}
              </Row>

              {data?.task && (
                <Row className="px-3">
                  <div className="my-3 p-0 position-relative text-center">
                    <hr className="position-absolute top-50 border-300 w-100 my-0" />
                    <span className="position-relative bg-white dark__bg-card-dark">
                      <Button
                        size="sm"
                        variant="outline-secondary"
                        className="rounded-pill border-300"
                      >
                        Converted Task
                      </Button>
                    </span>
                  </div>
                  <div className="m-0 px-0">
                    {data?.task && (
                      <CommonTaskRows
                        task={data?.task}
                        handleTaskInfo={handleTaskInfo}
                        isAlert={false}
                        isMargin={false}
                        usedFor={"ticket"}
                      />
                    )}
                  </div>
                </Row>
              )}

              {Object.keys(data.media).length > 0 && (
                <Card body className="p-x1 bg-light rounded-3 mt-3 rounded-0">
                  <div className="d-inline-flex flex-column">
                    {data.media.map((media, key) => (
                      <Fragment key={key}>
                        <File
                          title={`${media.file_name} (${media.human_readable_size})`}
                          icon={
                            media.extension === "xlsx"
                              ? "file-excel"
                              : media.extension === "pdf"
                              ? "file-pdf"
                              : media.extension === "doc"
                              ? "file-word"
                              : media.extension === "txt"
                              ? "file-lines"
                              : media.extension === "csv"
                              ? "file-csv"
                              : media.extension === "png" ||
                                media.extension === "jpeg"
                              ? "file-image"
                              : "file-archive"
                          }
                          color={
                            media.extension === "xlsx"
                              ? "#007f3eb5"
                              : media.extension === "pdf"
                              ? "#fb3939b8"
                              : media.extension === "doc"
                              ? "#285293"
                              : media.extension === "txt"
                              ? "#285293"
                              : media.extension === "csv"
                              ? "#41a754cc"
                              : media.extension === "png" ||
                                media.extension === "jpeg"
                              ? "#4f88e2"
                              : "#4f88e2"
                          }
                          action="Download"
                          actionIcon="arrow-down"
                          className="mb-2"
                          link={media.file_url}
                        />
                      </Fragment>
                    ))}
                  </div>
                  <hr className="my-x1" />
                  <Row className="flex-between-center gx-4 gy-2">
                    <Col xs="auto">
                      <p className="fs--1 text-1000 mb-sm-0 font-sans-serif fw-medium mb-0">
                        <FontAwesomeIcon icon="link" className="me-2" />
                        {Object.keys(data.media).length} files attached
                      </p>
                    </Col>
                    <Col xs="auto">
                      {downloadLoading ? (
                        <IconButton variant="falcon-default" size="sm">
                          <Spinner size="sm" />
                        </IconButton>
                      ) : (
                        <IconButton
                          icon="arrow-down"
                          variant="falcon-default"
                          size="sm"
                          onClick={() => {
                            handleBulkDownload(overviewId, "TICKET");
                          }}
                        >
                          <span className="ms-1">Download all</span>
                        </IconButton>
                      )}
                    </Col>
                  </Row>
                </Card>
              )}
            </div>
          </div>
          <Comments id={overviewId} comment_type="ticket" />
        </Card.Body>
      </Card>
      <TaskInfo
        taskInfoId={taskInfoData}
        showTaskInfo={showTaskInfo}
        handleCloseTaskInfo={() => setShowTaskInfo(false)}
        getData={getData}
      />
    </>
  );
};

export default TicketOverViewConversation;
