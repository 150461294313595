import React from "react";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function TableOverlayTrigger({
  content,
  id,
  length = 25,
  redirect,
  redirectedTo,
}) {
  return content ? (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip
          id={id}
          className="text-uppercase"
          style={{ fontSize: "10px" }}
        >
          {content}
        </Tooltip>
      }
    >
      <div>
        {content?.length > length ? (
          redirect ? (
            <Link to={redirectedTo}>
              <h5 className="mb-0 fs--1">
                {content.substring(0, length) + "..."}
              </h5>
            </Link>
          ) : (
            content.substring(0, length) + "..."
          )
        ) : redirect ? (
          <Link to={redirectedTo}>
            <h5 className="mb-0 fs--1">{content}</h5>
          </Link>
        ) : (
          content
        )}
      </div>
    </OverlayTrigger>
  ) : (
    "-"
  );
}

export default TableOverlayTrigger;
