import React from "react";
import { Chart, ArcElement } from "chart.js";
import "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
Chart.register(ArcElement);

const DoughnutChart = ({ height, width, data, options, className }) => {
  return (
    <div
      className={`doughnut-chart-container ${className ? className : ""}`}
      style={{ height: height, width: width }}
    >
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default DoughnutChart;
