const removeEmptyFields = (data, additionalField = "", hasZero = false) => {
  if (typeof data === "object" && !Array.isArray(data)) {
    const keysArray = Object.keys(data);
    const filteredObject = keysArray.reduce((result, key) => {
      if (
        data[key] !== undefined &&
        data[key] !== null &&
        data[key] !== "" &&
        (typeof data[key] !== "object" || Array.isArray(data[key])
          ? data[key].length !== 0
          : true) &&
        key !== additionalField &&
        (!hasZero || data[key] !== 0)
      ) {
        result[key] = data[key];
      }
      return result;
    }, {});
    return filteredObject;
  }
  return data;
};

export default removeEmptyFields;
