import axios from 'axios';
import { useEffect, useRef } from 'react';
import { useState } from 'react';

export default function useAutoCompleteApiCallHandler({
  url,
  setParams,
  setOptions,
  onFocus,
  onSuccess,
  onError,
  staticOptions,
  dependencyKeyToResetDefaultOptions
}) {
  if (typeof url !== "string" || url?.length < 1) {
    throw new Error("invalid url prop, Please provide a valid url.");
  }

  if (!["undefined", "function"].includes(typeof setParams)) {
    throw new Error("invalid setParams prop, this should be a function.");
  }

  if (!["undefined", "function"].includes(typeof setOptions)) {
    throw new Error("invalid setOptions prop, this should be a function.");
  }

  if (!["undefined", "function"].includes(typeof onSuccess)) {
    throw new Error("invalid onSuccess prop, this should be a function.");
  }

  if (!["undefined", "function"].includes(typeof onError)) {
    throw new Error("invalid onError prop, this should be a function.");
  }

  if (!["undefined", "function"].includes(typeof onFocus)) {
    throw new Error("invalid onFocus prop, this should be a function.");
  }

  if (staticOptions && !Array.isArray(staticOptions)) {
    throw new Error(
      "invalid staticOptions prop, this should be an array of items."
    );
  }

  const [loading, setloading] = useState(false);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const apiCallDelayRef = useRef(null);

  const getOptions = (inputValue, action) =>
    new Promise((resolve, reject) => {
      try {
        if (apiCallDelayRef?.current) clearTimeout(apiCallDelayRef.current);
        apiCallDelayRef.current = setTimeout(
          async () => {
            const response = await axios.get(url, {
              params:
                setParams &&
                setParams(typeof inputValue === 'string' ? inputValue : null)
            });

            let result = response?.data?.data;
             result = result?.data ? result?.data : result

            let options = [];
            if (Array.isArray(result)) {
              if (staticOptions) {
                result = [...staticOptions, ...result];
              }
              if (setOptions) {
                options = result?.map((item) => setOptions(item));
              }
            }

            onSuccess &&
              onSuccess({
                options: options,
                response: response,
                action: action,
              });

            resolve(options);
          },
          inputValue?.length > 0 ? 1200 : 0
        );
      } catch (error) {
        onError && onError(error);
        reject(error);
      }
    });

  async function fetchData(inputValue) {
    const options = await getOptions(inputValue, "fetchData");
    return options;
  }

  async function setDefaultResult(inputValue) {
    if (onFocus && inputValue?.target) onFocus(inputValue);
    if (defaultOptions?.length > 0) return;
    setloading(true);
    const options = await getOptions(inputValue, "setDefaultData");
    setDefaultOptions(options);
    setloading(false);
  }

  useEffect(() => {
    if (dependencyKeyToResetDefaultOptions?.length > 0) {
      setDefaultOptions([]);
    }
  }, [dependencyKeyToResetDefaultOptions]);

  return {
    fetchData,
    setDefaultResult,
    loading,
    setloading,
    defaultOptions,
    setDefaultOptions
  };
}
