import { AuthWizardContext } from "context/Context";
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";

const RequireAuth = ({ children }) => {
  let { user } = useContext(AuthWizardContext);
  return !user ? <Navigate to="/authentication/login" /> : children;
};

RequireAuth.propTypes = {
  children: PropTypes.element.isRequired,
};

export default RequireAuth;
