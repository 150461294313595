import { React } from "react";
import { Card, Col, ProgressBar, Row } from "react-bootstrap";
import { BiGridAlt } from "react-icons/bi";
import Flex from "components/common/Flex";
import "assets/scss/style.scss";

const ProjectOverview = () => {
  return (
    <Card className="h-100 card-main">
      <Card.Header
        className="fw-bold border-bottom"
        style={{ fontSize: "14px", padding: "10px" }}
      >
        <span style={{ fontSize: "21px", marginRight: "3px" }}>
          <BiGridAlt />
        </span>
        Projects Overview
      </Card.Header>
      <Card.Body>
        <Row>
          <Col lg={4} md={4} className="text-center p-3">
            <Card.Title className="text-success fs-1 fw-bold pb-1">
              0
            </Card.Title>
            <Card.Title>
              <span style={{ fontSize: "15px" }}>Open</span>
            </Card.Title>
          </Col>
          <Col lg={4} md={4} className="text-center p-3">
            <Card.Title className="text-danger fs-1 fw-bold pb-1">0</Card.Title>
            <Card.Title>
              <span style={{ fontSize: "15px" }}>Completed</span>
            </Card.Title>
          </Col>
          <Col lg={4} md={4} className="text-center p-3">
            <Card.Title className="text-warning fs-1 fw-bold pb-1">
              0
            </Card.Title>
            <Card.Title>
              <span style={{ fontSize: "15px" }}>Hold</span>
            </Card.Title>
          </Col>
        </Row>
        <Flex alignItems="center">
          <ProgressBar
            className="progress-bar-label"
            variant="success"
            now={60}
            label="Progression 60%"
            style={{
              width: "100%",
              backgroundColor: "white",
              border: "1px solid #28a745",
              height: 18,
              margin: "10px 5px",
              borderRadius: "20px",
            }}
          ></ProgressBar>
        </Flex>
      </Card.Body>
    </Card>
  );
};

export default ProjectOverview;
