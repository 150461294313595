import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "helpers/initFA";
import { BrowserRouter as Router } from "react-router-dom";
import AuthProvider from "providers/AuthProvider";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Main from "Main";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Main>
      <Router basename={process.env.PUBLIC_URL}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </Router>
    </Main>
  </React.StrictMode>
);
