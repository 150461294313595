import React, { useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import logo from "../../assets/img/logos/ACCODAX1.png";
import { Link } from "react-router-dom";
import { BsFillBackspaceFill } from "react-icons/bs";
import "./Invitation.scss";
import { registerFormKeys } from "helpers/formKeys";
import generateFormDataHelper from "helpers/generateFormDataHelper";
import removeRefData from "helpers/removeRefData";
import axios from "axios";
import { showToast } from "module/common/Toast/toast";
import { useEffect } from "react";

const InvitationRegister = () => {
  const [section, setSection] = useState(1);
  const [formData, setFormData] = useState(registerFormKeys);
  const [formError, setFormError] = useState({});
  
  const [onSave, setOnSave] = useState(false);
  const handleSection = (section) => {
    setSection(section);
  };

  const handleFieldChange = (e, action) =>
    setFormData(generateFormDataHelper(e, action, formData));

  useEffect(() => {
    if (formError.first_name || formError.last_name || formError.email) {
      setSection(1);
    }
  }, [formError]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (onSave) return;
    setFormError({});
    setOnSave(true);

    let formDataWithoutRefkeys = removeRefData(formData);

    axios({
      method: "post",
      url: `user/register`,
      data: formDataWithoutRefkeys,
    })
      .then((response) => {
        if (response.data.success === true) {
          showToast(response.data.message, "success");
          setFormData({
            first_name: "",
            last_name: "",
            email: "",
            username: "",
            password: "",
            c_password: "",
            branch_id: "",
            branch_id_ref: { label: "", value: "" },
          });
          setSection(1);
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      })
      .catch((error) => {
        if (error.response.data && error.response.data.message) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      });
  };

  return (
    <div
      className={"d-flex justify-content-center align-items-center min-vh-100"}
      style={{
        background: "linear-gradient(to right, #00416a, #e4e5e6)",
      }}
    >
      <Card
        style={{
          height: "55vh",
          width: "100vh",
          backgroundColor: "rgba(0,0,0,0.1)",
        }}
        className="rounded-5"
      >
        <Card.Body className="p-0">
          <Row className="h-100 gx-0">
            <Col md={8} sm={8} className="">
              <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                <span className="fs-1 fw-bold mb-3 pt-3 text-light">
                  Let's Get Started
                </span>
                <Form className="w-50">
                  {section === 1 ? (
                    <>
                      <Form.Group className="mb-3" controlId="formBasic1">
                        <Form.Label className="text-light">
                          First Name
                        </Form.Label>
                        <Form.Control
                          className="custom-input-invitation"
                          type="text"
                          placeholder="Enter First Name"
                          name="first_name"
                          onChange={handleFieldChange}
                          value={formData.first_name}
                          isInvalid={!!formError.first_name}
                        />
                        <Form.Control.Feedback
                          type="invalid"
                          style={{ color: "#ff5271" }}
                        >
                          {formError.first_name}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasic2">
                        <Form.Label className="text-light">
                          Last Name
                        </Form.Label>
                        <Form.Control
                          className="custom-input-invitation"
                          type="text"
                          placeholder="Enter Last Name"
                          name="last_name"
                          onChange={handleFieldChange}
                          value={formData.last_name}
                          isInvalid={!!formError.last_name}
                        />
                        <Form.Control.Feedback
                          type="invalid"
                          style={{ color: "#ff5271" }}
                        >
                          {formError.last_name}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasic3">
                        <Form.Label className="text-light">
                          Email address
                        </Form.Label>
                        <Form.Control
                          className="custom-input-invitation"
                          type="email"
                          placeholder="Enter email"
                          name="email"
                          onChange={handleFieldChange}
                          value={formData.email}
                          isInvalid={!!formError.email}
                        />
                        <Form.Control.Feedback
                          type="invalid"
                          style={{ color: "#ff5271" }}
                        >
                          {formError.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasic3">
                        <div className="d-flex justify-content-end">
                          <Button
                            variant="warning"
                            size="sm"
                            onClick={() => {
                              handleSection(2);
                            }}
                            disabled={
                              !formData.first_name.trim() ||
                              !formData.last_name.trim() ||
                              !formData.email.trim()
                            }
                          >
                            Next
                          </Button>
                        </div>
                      </Form.Group>
                    </>
                  ) : (
                    <>
                      <Form.Group className="mb-3" controlId="formBasic4">
                        <Form.Label className="text-light">Username</Form.Label>
                        <Form.Control
                          className="custom-input-invitation"
                          type="text"
                          placeholder="Enter Username"
                          name="username"
                          onChange={handleFieldChange}
                          value={formData.username}
                          isInvalid={!!formError.username}
                        />
                        <Form.Control.Feedback
                          type="invalid"
                          style={{ color: "#ff5271" }}
                        >
                          {formError.username}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasic5">
                        <Form.Label className="text-light">Password</Form.Label>
                        <Form.Control
                          className="custom-input-invitation"
                          type="password"
                          placeholder="Password"
                          name="password"
                          onChange={handleFieldChange}
                          value={formData.password}
                          isInvalid={!!formError.password}
                        />
                        <Form.Control.Feedback
                          type="invalid"
                          style={{ color: "#ff5271" }}
                        >
                          {formError.password}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasic5">
                        <Form.Label className="text-light">
                          Confirm Password
                        </Form.Label>
                        <Form.Control
                          className="custom-input-invitation"
                          type="password"
                          placeholder="Confirm Password"
                          name="c_password"
                          onChange={handleFieldChange}
                          value={formData.c_password}
                          isInvalid={!!formError.c_password}
                        />
                        <Form.Control.Feedback
                          type="invalid"
                          style={{ color: "#ff5271" }}
                        >
                          {formError.c_password}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasic6">
                        <Form.Label className="text-light">Branch</Form.Label>
                        <Form.Control
                          className="custom-input-invitation"
                          type="text"
                          placeholder="Branch"
                          name="branch_id"
                          onChange={handleFieldChange}
                          value={formData.branch_id}
                          isInvalid={!!formError.branch_id}
                        />
                        <Form.Control.Feedback
                          type="invalid"
                          style={{ color: "#ff5271" }}
                        >
                          {formError.branch_id}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasic3">
                        <div className="d-flex justify-content-between">
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => {
                              handleSection(1);
                            }}
                          >
                            <BsFillBackspaceFill />
                          </Button>
                          <Button
                            variant="success"
                            size="sm"
                            onClick={handleSubmit}
                            disabled={
                              !formData.first_name.trim() ||
                              !formData.last_name.trim() ||
                              !formData.email.trim() ||
                              !formData.username.trim() ||
                              !formData.password.trim() ||
                              !formData.c_password.trim() ||
                              !formData.branch_id.trim() ||
                              onSave
                            }
                          >
                            Register
                          </Button>
                        </div>
                      </Form.Group>
                    </>
                  )}
                  <span className="text-light">
                    Have an account already?{" "}
                    <Link
                      className="text-light fw-bold"
                      to="/authentication/login"
                    >
                      Login
                    </Link>
                  </span>
                </Form>
              </div>
            </Col>
            <Col md={4} sm={4} className="">
              <Card className="h-100 rounded-5">
                <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                  <img
                    src={logo}
                    alt="logo"
                    className="mb-3"
                    style={{ width: "15rem" }}
                  />
                  <div className="text-center">
                    <span className="fs-1 fw-bold mb-3">Sign Up</span>
                    <br />
                    <span className="fs--2">
                      Join our services to unlock exciting experiences.
                    </span>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default InvitationRegister;
