import React, { useEffect, useState } from "react";
import {
  Badge,
  Col,
  Image,
  ListGroup,
  ListGroupItem,
  Row,
} from "react-bootstrap";
import SimpleBarReact from "simplebar-react";
import PropTypes from "prop-types";
import { apiCall } from "helpers/apiCalls";
import ProfileImage from "../../../../assets/img/Avathar/ProfileImage.jpg";
import DashboardTileLoader from "module/common/Loading/DashboardTileLoader";
const Activity = ({ activity }) => {
  return (
    <ListGroup.Item className="border-0 border-bottom rounded-0">
      <Row>
        <Col md={12} className="">
          <div className="d-flex flex-column">
            <span className="d-block">
              <Image
                className="custom-image-crm me-1"
                src={activity.causer_image ?? ProfileImage}
                roundedCircle
                alt="?"
                loading="lazy"
                height={30}
                width={30}
              />
              <p className="fw-bolder d-inline">{activity.causer_name}</p>
              <p className="d-inline ms-1 custom-text-muted-crm">
                | {activity.created_at}
              </p>
            </span>
            <span className="d-block pt-1 ms-4">
              <Badge
                bg={
                  activity.event === "updated"
                    ? "warning"
                    : activity.event === "commented"
                    ? "secondary"
                    : "success"
                }
                text="dark"
                className="d-inline text-capitalize"
                pill
              >
                <span className="text-white"> {activity.event}</span>
              </Badge>
              <p className="d-inline ps-2">{activity.description}</p>
            </span>
          </div>
        </Col>
      </Row>
    </ListGroup.Item>
  );
};

const RecentActivity = ({ projectId, loading }) => {
  const [recentActivities, setRecentActivities] = useState({});
  const getActivityLog = async () => {
    const data = await apiCall({
      url: `activity-log`,
      params: {
        subject_id: projectId,
      },
    });
    setRecentActivities(data);
  };

  useEffect(() => {
    getActivityLog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ListGroup className="shadow-sm h-100">
      <ListGroup.Item className="pt-3 pb-3 border-0">
        <div>
          <h6 className="mb-0 fw-semi-bold text-muted">Recent Activity</h6>
        </div>
      </ListGroup.Item>
      <SimpleBarReact
        style={{ maxHeight: "25.1rem", overflowX: "hidden" }}
        className="bg-light"
      >
        {recentActivities?.data?.length > 0 && !loading ? (
          recentActivities.data.map((activity, index) => (
            <Activity key={index} activity={activity} />
          ))
        ) : (
          <ListGroupItem style={{ height: "22.1rem" }} className="border-0">
            {loading ? (
              <DashboardTileLoader column={4} />
            ) : (
              <span className="d-flex justify-content-center align-items-center h-100">
                No Activity
              </span>
            )}
          </ListGroupItem>
        )}
      </SimpleBarReact>
    </ListGroup>
  );
};

Activity.propTypes = {
  activity: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    text: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    time: PropTypes.string,
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }),
};

RecentActivity.propTypes = {
  projectId: PropTypes.string.isRequired,
};

export default RecentActivity;
