import { React, useContext, useEffect, useState } from "react";
import {
  createSearchParams,
  Outlet,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Badge, Card } from "react-bootstrap";
import PropTypes from "prop-types";
import { AuthWizardContext } from "context/Context";
import { DeleteButton, EditButton } from "module/common/Buttons/AppButtons";
import { GetAcodaxPermission } from "module/common/Permissions/AcodaxPermission";
import AppPagination from "components/common/app-pagination/AppPagination";
import LoadingScreenCover from "components/common/loading-screen/LoadingScreenCover";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import SimpleBar from "simplebar-react";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import CustomerDetails from "components/customer-details/CustomerDetails";
import TableOverlayTrigger from "components/table-overlay-trigger/TableOverlayTrigger";
import LoadingCommon from "components/common/loading-screen/LoadingCommon";
import "../ProjectTableView.scss";
import LoadingOverlay from "components/common/LoadingOverlay/LoadingOverlay";
import ProjectOffcanvas from "module/Project/ProjectOffcanvas/ProjectOffcanvas";
import CommonStatusUpdateDropDown from "module/common/ViewPages/Common/Meetings/CommonStatusUpdateDropDown";
import getProjectStatusWithProps from "module/common/helpers/getProjectStatusWithProps";
import { FaCircle } from "react-icons/fa";

function List({ getData, data, handleDelete, openFrom, filterVisibility }) {
  const location = useLocation();
  let user = useContext(AuthWizardContext);
  const Translate = useAxisproTranslate();
  const [queryParams] = useSearchParams();
  const { itemInfoId, client_id, empId } = useParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const [showCustomerDetailsArea, setCustomerDetailsArea] = useState(false);
  const [customerData, setCustomerData] = useState({});
  const [projectInfo, setProjectInfo] = useState({
    show: false,
    id: "",
  });

  const handleCustomerDetailsArea = (name, id) => {
    setCustomerData({ label: name, value: id });
    setCustomerDetailsArea(!showCustomerDetailsArea);
    localStorage.setItem(
      "actualRouteFromProject",
      `${location.pathname}${location.search}`
    );
  };
  const permission = {
    export: GetAcodaxPermission("PROJECT", "export"),
    store: GetAcodaxPermission("PROJECT", "store"),
    show: GetAcodaxPermission("PROJECT", "show"),
    update: GetAcodaxPermission("PROJECT", "update"),
    delete: GetAcodaxPermission("PROJECT", "delete"),
    show_customer: GetAcodaxPermission("ACCOUNT", "show"),
  };
  const isFromEmployee = openFrom === "employee";
  const isFromAccount = openFrom === "account";
  const [statusLoading, setStatusLoading] = useState(false);

  const handleShowProjectInfo = (data) => {
    if (permission?.show) {
      setProjectInfo({
        show: true,
        id: data?.id,
      });
    }
  };

  const columns = [
    {
      accessor: "project_code",
      Header: Translate("PROJECT CODE"),
      isMandatory: true,
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-0",
      },
      retrieveData: handleShowProjectInfo,
      Cell: (rowData) => {
        const { project_code } = rowData.row.original;
        return (
          <>
            {project_code ? (
              <Badge className="fs--2" bg="danger">
                {project_code}
              </Badge>
            ) : (
              <>-</>
            )}
          </>
        );
      },
    },
    {
      accessor: "title",
      Header: Translate("PROJECT TITLE"),
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-0",
        style: { minWidth: "20rem" },
      },
      retrieveData: handleShowProjectInfo,
      Cell: (rowData) => {
        const { title, id } = rowData.row.original;
        return <TableOverlayTrigger content={title} id={id} length={34} />;
      },
    },
    ...(user?.user?.customer_id
      ? []
      : [
          {
            accessor: "name",
            Header: Translate("CLIENT"),
            headerProps: { className: "pe-1" },
            cellProps: {
              className: "py-0",
              style: { minWidth: "14rem" },
            },
            Cell: (rowData) => {
              const { customer } = rowData.row.original;
              return (
                <div
                  className={`d-flex align-items-center ${
                    permission?.show_customer
                      ? "custom-clickable-link-crm "
                      : ""
                  }`}
                  onClick={() => {
                    permission?.show_customer &&
                      !isFromAccount &&
                      handleCustomerDetailsArea(
                        customer?.name ?? "",
                        customer?.id ?? ""
                      );
                  }}
                >
                  <TableOverlayTrigger
                    content={customer?.name}
                    id={customer?.id}
                    length={23}
                  />
                </div>
              );
            },
          },
        ]),
    {
      accessor: "product",
      Header: Translate("PRODUCTS"),
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-0",
        style: { minWidth: "10rem" },
      },
      Cell: (rowData) => {
        const { product, id } = rowData.row.original;
        const productString = product?.join(", ");

        return (
          <>
            {product?.length > 0 ? (
              <>
                <TableOverlayTrigger
                  content={productString}
                  id={id}
                  length={16}
                />
              </>
            ) : (
              <div className="d-flex align-items-center gap-1">-</div>
            )}
          </>
        );
      },
    },
    {
      accessor: "start_date",
      Header: Translate("START DATE"),
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-0",
      },
      retrieveData: handleShowProjectInfo,
      Cell: (rowData) => {
        const { start_date_formated } = rowData.row.original;
        return (
          <h5 className="mb-0 fs--1">
            {start_date_formated ? start_date_formated : "-"}
          </h5>
        );
      },
    },
    {
      accessor: "end_date",
      Header: Translate("END DATE"),
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-0",
      },
      retrieveData: handleShowProjectInfo,
      Cell: (rowData) => {
        const { end_date_formated } = rowData.row.original;
        return (
          <h5 className="mb-0 fs--1">
            {end_date_formated ? end_date_formated : "-"}
          </h5>
        );
      },
    },
    {
      accessor: "price",
      Header: Translate("PRICE"),
      headerProps: { className: "pe-1 text-end" },
      cellProps: {
        className: "py-0",
        style: { minWidth: "10rem" },
      },
      retrieveData: handleShowProjectInfo,
      Cell: (rowData) => {
        const { price_formatted } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{price_formatted}</h5>;
      },
    },
    {
      accessor: "status",
      Header: Translate("STATUS"),
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-0",
      },
      Cell: (rowData) => {
        const { status, id } = rowData.row.original;
        return (
          <div>
            {permission?.update ? (
              <CommonStatusUpdateDropDown
                getData={getData}
                activeStatus={status}
                fetchUrl="crm/project-status"
                method="patch"
                postUrl={`crm/project/${id}/status-update`}
                width="170px"
                setLoading={setStatusLoading}
              />
            ) : (
              <span className="text-capitalize">
                <FaCircle color={getProjectStatusWithProps(status)} size={10} />
                <span className="ms-1">{status.replace("_", " ")}</span>
              </span>
            )}
          </div>
        );
      },
    },
    {
      accessor: "none",
      Header: "",
      headerProps: {
        className: `py-3 custom-table-head-th-action ${
          permission?.update || permission?.delete ? "" : "d-none"
        }`,
      },
      disableSortBy: true,
      cellProps: {
        className: `custom-table-body-td-action ${
          permission?.update || permission?.delete ? "" : "d-none"
        }`,
      },
      Cell: (rowData) => {
        const { id } = rowData.row.original;
        return (
          <div className="major-div-element">
            {permission?.update && (
              <EditButton
                route={
                  openFrom
                    ? `project/edit/${id}?${createSearchParams({
                        ...allQueryParams,
                      })}`
                    : `edit/${id}?${createSearchParams({
                        ...allQueryParams,
                      })}`
                }
              />
            )}
            {permission?.delete && (
              <DeleteButton onClick={() => handleDelete(id)} />
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (itemInfoId) {
      setProjectInfo({
        show: false,
        id: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemInfoId]);

  return (
    <>
      <Outlet context={[getData]} />
      {statusLoading && <LoadingOverlay />}
      {!itemInfoId ? (
        <>
          {data?.data ? (
            <>
              <SimpleBar
                className={
                  !filterVisibility
                    ? openFrom
                      ? "simplebar-style-with-pagination-for-tab simplebar-custom"
                      : "simplebar-style-with-pagination simplebar-custom"
                    : openFrom
                    ? "simplebar-style-with-pagination-and-filter-on-for-tab simplebar-custom"
                    : "simplebar-style-with-pagination-and-filter-on simplebar-custom"
                }
              >
                <AdvanceTableWrapper
                  columns={columns}
                  data={data.data}
                  sortable
                >
                  <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle position-sticky top-0 z-1"
                    rowClassName="align-middle white-space-nowrap  custom-table-row"
                    tablebodyClassName="custom-table-body position-sticky"
                    tableprops={{
                      size: "sm",
                      striped: true,
                      className: "fs--1 mb-0 overflow-hidden",
                    }}
                  />
                </AdvanceTableWrapper>
              </SimpleBar>
              <Card.Footer className="border-top p-2 footer">
                {data && data.links && (
                  <AppPagination
                    data={data}
                    url={
                      isFromAccount
                        ? `/account/overview/${client_id}`
                        : isFromEmployee
                        ? `/employee/overview/${empId}`
                        : "/project"
                    }
                    search={allQueryParams}
                  />
                )}
              </Card.Footer>
            </>
          ) : (
            <LoadingScreenCover
              className={`flex-fill ${!filterVisibility ? "border-top" : ""}`}
            >
              <LoadingCommon loadingText="Looking for Opportunity" />
            </LoadingScreenCover>
          )}
        </>
      ) : (
        ""
      )}
      <CustomerDetails
        show={showCustomerDetailsArea}
        onHide={handleCustomerDetailsArea}
        value={customerData}
        showCustomerDetailsArea={showCustomerDetailsArea}
      />
      {!itemInfoId && (
        <ProjectOffcanvas
          show={projectInfo?.show}
          id={projectInfo?.id}
          onHide={() => setProjectInfo({ show: false, id: "" })}
        />
      )}
    </>
  );
}

List.propTypes = {
  projectId: PropTypes.string,
  setComponent: PropTypes.func,
};

export default List;
