import React from "react";
import { createSearchParams } from "react-router-dom";
import { Card, Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";

import bg1 from "assets/img/icons/spot-illustrations/corner-1.png";
import bg2 from "assets/img/icons/spot-illustrations/corner-3.png";

import useAxisproPermission from "hooks/useAxisproPermission";
import InfoTile from "./InfoTile";
import DashboardTileLoader from "module/common/Loading/DashboardTileLoader";
import Background from "components/common/Background";

function QuickInfoTile({ project, loading }) {
  const axisProPermission = useAxisproPermission();
  const statsData = [
    {
      title: "Project Members",
      value: project?.total_members ?? "0",
      decimal: false,
      suffix: "",
      prefix: "",
      valueClassName: "text-warning",
      badgeBg: "warning",
      badgeText: "100.0%",
      link: null,
      linkText: "See all",
      add_members: true,
      image: bg1,
    },
    {
      title: "Total Task",
      value: project?.total_tasks ?? "0",
      decimal: false,
      suffix: "",
      prefix: "",
      valueClassName: "text-info",
      badgeBg: "info",
      badgeText: "100.0%",
      link: axisProPermission("show-task")
        ? `?${createSearchParams({
            filters: JSON.stringify({
              column: "project_id",
              operator: "=",
              type: "string",
              value: project.id,
              value_ref: {
                label: project.title,
                value: project.id,
              },
            }),
          })}&tab=tasklist`
        : null,
      linkText: "All Task",
      add_members: false,
      image: bg2,
    },
  ];

  return (
    <Row className="g-2 rounded-0">
      {project
        ? statsData.map((stat, key) => (
            <Col xs={6} key={key} className="rounded-0">
              {!loading ? (
                <InfoTile className="rounded-0" stat={stat} />
              ) : (
                <Card className={"overflow-hidden rounded-0 card-main"}>
                  <Background
                    image={key === 0 ? bg1 : bg2}
                    className="bg-card rounded-0"
                  />
                  <Card.Body className="position-relative rounded-0">
                    <DashboardTileLoader column={1} className="rounded-0" />
                  </Card.Body>
                </Card>
              )}
            </Col>
          ))
        : statsData.map((stat, key) => (
            <Col className="rounded-0" xs={6} key={key}>
              <InfoTile stat={stat} className="rounded-0" />
            </Col>
          ))}
    </Row>
  );
}

QuickInfoTile.propTypes = {
  data: PropTypes.any,
  project: PropTypes.any,
};

export default QuickInfoTile;
