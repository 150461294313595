import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as SiIcons from "react-icons/si";
import * as TbIcons from "react-icons/tb";
import * as BiIcons from "react-icons/bi";
import * as MdIcons from "react-icons/md";
import * as RiIcons from "react-icons/ri";
import * as BsIcons from "react-icons/bs";
import * as FiIcons from "react-icons/fi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const generateIconComponent = (iconName, size = 16, color = "#212529") => {
  const match = iconName?.match(/^[a-z]/);
  const library = match ? "fontawesome" : "";

  if (library === "fontawesome") {
    return (
      <FontAwesomeIcon
        icon={iconName}
        style={{ fontSize: size }}
        color={color}
      />
    );
  } else {
    const iconLibraries = [
      FaIcons,
      AiIcons,
      SiIcons,
      TbIcons,
      BiIcons,
      MdIcons,
      RiIcons,
      BsIcons,
      FiIcons,
    ];
    for (const icons of iconLibraries) {
      if (icons.hasOwnProperty(iconName)) {
        return React.createElement(icons[iconName], {
          style: { fontSize: size },
          color: color,
        });
      }
    }
    console.error(`Icon "${iconName}" not found`);
    return null;
  }
};

export default generateIconComponent;
