import React from "react";
import { Link } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import PropTypes from "prop-types";

import { MdDensitySmall } from "react-icons/md";

function DashboardListHeading({ heading, route }) {
  return (
    <Card className="mb-1 card-main rounded-0">
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center">
          <span className="fs-1 text-dark">{heading}</span>
          <span>
            <Button
              size="sm"
              variant="transparent"
              className="p-0 custom-transperent-common-button"
              as={Link}
              to={route}
              title={"List All"}
            >
              <MdDensitySmall size={18} className="text-dark" />
            </Button>
          </span>
        </div>
      </Card.Body>
    </Card>
  );
}

DashboardListHeading.propTypes = {
  heading: PropTypes.string,
  route: PropTypes.any,
};

export default DashboardListHeading;
