import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, ListGroup, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { apiCall } from "helpers/apiCalls";
import QuickInfoTile from "./QuickInfoTile/QuickInfoTile";
import ReportForThisWeek from "./ReportForThisWeek/ReportForThisWeek";
import RecentActivity from "./RecentActivity";
import ProjectChart from "./ProjectChart";
import ProjectInfo from "./ProjectInfo";
import TotalWorkHours from "./TotalWorkHours";
import ProjectCost from "./ProjectCost";
import ProjectDescription from "./ProjectDescription";
import ToDoList from "./TodoList";
import ProjectMembers from "./ProjectMembers";
import Flex from "components/common/Flex";
import SimpleBarReact from "simplebar-react";
import ListLoading from "module/common/ListLoading";
import { FiPackage } from "react-icons/fi";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import { GetAcodaxPermission } from "module/common/Permissions/AcodaxPermission";

function OverViewTab({
  project,
  todo,
  loading,
  taskApiCalling,
  membersList,
  setMembersList,
}) {
  const Translate = useAxisproTranslate();
  const { itemInfoId } = useParams();
  const [dashboardData, setDashboardData] = useState({});
  const [dashboardLoding, setDashboardLoding] = useState(false);
  const [dataProcessing, setDataProcessing] = useState(false);

  const permissions = {
    showTasks: GetAcodaxPermission("TASK", "show"),
    showLogHistory: GetAcodaxPermission("LOG_HISTORY", "show"),
    listProjectMembers: GetAcodaxPermission("P_MEMBERS", "list"),
  };

  const getDashboardData = async () => {
    setDashboardLoding(true);
    try {
      const data = await apiCall({
        url: `crm/project-dashboard-summary/${itemInfoId}`,
      });
      setDashboardData(data);
      setDashboardLoding(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getDashboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemInfoId]);

  return (
    <>
      <Row className="g-2 mb-2 mt-2 d-flex flex-wrap">
        <Col xs={12} md={3}>
          <ProjectChart
            taskAnalytics={dashboardData?.task_analytics}
            loading={dashboardLoding}
          />
        </Col>
        <Col xs={12} md={4}>
          <ProjectInfo
            projectAnalytics={dashboardData?.project_analytics}
            loading={dashboardLoding}
          />
        </Col>
        <Col xs={12} md={5} className="d-flex flex-column gap-2">
          <Row className="g-2">
            <Col xs={6}>
              <TotalWorkHours
                hour={project?.total_working_hr}
                loading={loading}
              />
            </Col>
            <Col xs={6}>
              <ProjectCost price={project?.price} loading={loading} />
            </Col>
            <Col lg={12}>
              <QuickInfoTile
                project={project}
                loading={dataProcessing}
                taskApiCalling={taskApiCalling}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="g-2 mb-2">
        <Col xs={12} md={4}>
          <ProjectDescription
            description={project?.description}
            loading={loading}
          />
        </Col>
        <Col xs={12} md={6} sm={12}>
          <Row className="g-2 h-100">
            <Col lg={12} className="h-100">
              <ReportForThisWeek
                reportData={dashboardData?.last_two_weeks_project_time_tracker}
                loading={dashboardLoding}
              />
            </Col>
          </Row>
        </Col>
        {permissions?.listProjectMembers && (
          <Col xs={12} md={2}>
            <ProjectMembers
              projectId={project.id}
              setDataProcessing={setDataProcessing}
              dataProcessing={dataProcessing}
              membersList={membersList}
              setMembersList={setMembersList}
            />
          </Col>
        )}
      </Row>
      <Row className="g-2">
        <Col xs={12} md={2}>
          <ListGroup
            className="h-100 shadow-sm"
            style={{ backgroundColor: "white" }}
          >
            <ListGroup.Item className="pt-3 pb-3 border-0 border-bottom rounded-0">
              <div className="d-flex align-items-center">
                <h6 className="mb-0 fw-semi-bold text-dark">
                  {Translate("PRODUCTS")}
                </h6>
              </div>
            </ListGroup.Item>
            <SimpleBarReact style={{ height: "18rem", overflowX: "hidden" }}>
              {project?.products?.length > 0 && !dataProcessing ? (
                project?.products.map((el, index) => (
                  <ListGroup.Item
                    key={index}
                    className="border-0 border-bottom rounded-0"
                  >
                    <Flex
                      alignItems="center"
                      className="position-relative flex-wrap"
                    >
                      <div className="flex-1 w-25">
                        <h6 className="mb-0 fw-semi-bold">
                          <div
                            className="text-dark"
                            style={{
                              textDecoration: "none",
                              cursor: "pointer",
                            }}
                          >
                            {el?.name}
                          </div>
                        </h6>
                        <p className="fs--2 mb-0 text-muted">
                          {el?.description}
                        </p>
                      </div>
                    </Flex>
                  </ListGroup.Item>
                ))
              ) : (
                <ListGroup.Item className="h-100 border-0">
                  {dataProcessing ? (
                    <ListLoading
                      style={{ height: "28vh", overflow: "hidden" }}
                    />
                  ) : (
                    <div className="h-100 p-3 d-flex flex-column justify-content-center align-items-center">
                      <FiPackage size={35} className="text-dark mb-1" />
                      <span>{Translate("No Products found!")}</span>
                    </div>
                  )}
                </ListGroup.Item>
              )}
            </SimpleBarReact>
          </ListGroup>
        </Col>
        {permissions?.showLogHistory && (
          <Col xs={12} md={4}>
            <RecentActivity projectId={project.id} loading={dashboardLoding} />
          </Col>
        )}
        {permissions?.showTasks && (
          <Col xs={12} md={permissions?.showLogHistory ? 6 : 10}>
            <ToDoList todo={todo} loading={taskApiCalling} />
          </Col>
        )}
      </Row>
    </>
  );
}

OverViewTab.propTypes = {
  loading: PropTypes.bool,
};

export default OverViewTab;
