import { React } from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import useAutoCompleteApiCallHandler from "hooks/useAutoCompleteApiCallHandler";

const MultiSelectProjectMembers = ({
  onChange,
  name,
  error,
  value,
  style,
  placeholder,
  excludeUser,
  assignee_id,
  onFocus,
}) => {
  const { fetchData, setDefaultResult, loading, defaultOptions } =
    useAutoCompleteApiCallHandler({
      url: "user/users-autocomplete",
      setParams: (searchKey) => ({
        exclude_user:
          typeof excludeUser === "string"
            ? excludeUser
            : assignee_id
            ? assignee_id
            : "",
        name: searchKey,
      }),
      setOptions: (option) => setOptionData(option),
      onFocus: onFocus,
      dependencyKeyToResetDefaultOptions:
        typeof excludeUser === "string"
          ? excludeUser
          : assignee_id
          ? assignee_id
          : "",
    });

  const setOptionData = (option) => {
    if (typeof excludeUser === "object") {
      let tempExcludeUsers = new Set(excludeUser.map(({ user_id }) => user_id));

      if (tempExcludeUsers.has(option.id)) {
        return null;
      }
    }
    return {
      label: option.full_name,
      value: option.id,
    };
  };

  const filteredDefaultOptions = (defaultOptions || []).filter(
    (option) => option !== null
  );

  const loadOptions = async (inputValue) => {
    const options = await fetchData(inputValue);
    return (options || []).filter((option) => option !== null);
  };


  return (
      <AsyncSelect
        isMulti
        cacheOptions
        loadOptions={loadOptions}
        defaultOptions={filteredDefaultOptions}
        isLoading={loading}
        onFocus={setDefaultResult}
        isClearable
        name={name ? name : "Select"}
        value={value}
        placeholder={placeholder ?? ""}
        onChange={onChange}
        isSearchable={true}
        className={`custom-select text-capitalize ${
          error && "form-control ps-0 py-0 is-invalid"
        }`}
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "#696c96" : "inherit",
            "&:hover": {
              backgroundColor: state.isSelected ? "#192E49" : "#e1e2ea",
            },
          }),
          control: (provided) => ({
            ...provided,
            height: "fit-content",
            minHeight: 32,
            ...style,
          }),
        }}
      />
  );
};

MultiSelectProjectMembers.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.any,
  keyName: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
};

export default MultiSelectProjectMembers;
