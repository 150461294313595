import BackButton from "components/common/BackButton";
import SelectUsers from "components/form/SelectUsers";
import { SaveButton } from "module/common/Buttons/CommonButton";
import PropTypes from "prop-types";
import { Button, Form, Row } from "react-bootstrap";
const AddAssigneForm = ({
  handleFieldChange,
  formData,
  formError,
  onSave,
  handleSubmit,
  handleClose,
}) => {
  return (
    <Form>
      <Row>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Group className="mb-3">
            <Form.Label className="require-data">Assignee</Form.Label>
            <SelectUsers
              name="assinee"
              value={formData.assinee_ref}
              handleFieldChange={handleFieldChange}
              error={formError.assinee}
            />
            <Form.Control.Feedback type="invalid">
              {formError.assinee}
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Group>
      </Row>
      <Form.Group className="d-flex justify-content-end gap-1 mt-3">
        <SaveButton handleSubmit={handleSubmit} onSave={onSave} />
        {!onSave && (
          <>
            {handleClose ? (
              <Button variant={"danger"} size={"sm"} onClick={handleClose}>
                Cancel
              </Button>
            ) : (
              <BackButton variant={"danger"} size={"sm"}>
                Cancel
              </BackButton>
            )}
          </>
        )}
      </Form.Group>
    </Form>
  );
};
AddAssigneForm.prototype = {
  handleFieldChange: PropTypes.func,
  formData: PropTypes.any,
  formError: PropTypes.any,
  onSave: PropTypes.bool,
  handleSubmit: PropTypes.func,
};
export default AddAssigneForm;
