import React from "react";
import { Badge, Card, Col, Row } from "react-bootstrap";
import Logo from "assets/img/logos/Accodax-3.png";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import { stringLimiter } from "helpers/utils";

const CustomerProjectCard = ({ item, index }) => {
  const Translate = useAxisproTranslate();

  const getStatusColor = (value) => {
    switch (value) {
      case "open":
        return "info";
      case "cancelled":
        return "danger";
      case "hold":
        return "warning";
      default:
        return "success";
    }
  };

  return (
    <Col xs={6} key={index} className="d-flex">
      <Card
        style={{
          backgroundColor: "whitesmoke",
        }}
        className="flex-fill"
      >
        <Card.Header className="account-card-header p-0 ps-1 pe-4 mb-1 mt-2">
          <Card.Img
            variant="top"
            className="account-card-header-img"
            src={item?.logo ? item?.logo : Logo}
          />
        </Card.Header>
        <Card.Body
          className="d-flex align-items-center 
        flex-column p-1 pt-2 text-decoration-none mb-3"
        >
          <div className="p-2 mb-2 w-100 text-center">
            <h1 className="fw-bolder mb-0 text-uppercase">{stringLimiter(item?.title, 35)}</h1>
          </div>
          <Row className="justify-content-center w-100 mb-2">
            <Col xs={5} className="p-0">
              <h1 className="m-0 opacity-75">{Translate("Progress")}:</h1>
            </Col>
            <Col xs={6} className="p-0">
              <h1 className="m-0 fw-bold">{index * 10}%</h1>
            </Col>
          </Row>
          <Row className="justify-content-center w-100 mb-2">
            <Col xs={5} className="p-0">
              <h1 className="m-0 opacity-75">{Translate("Start Date")}:</h1>
            </Col>
            <Col xs={6} className="p-0">
              <h1 className="m-0 fw-bold">{item?.start_date}</h1>
            </Col>
          </Row>
          <Row className="justify-content-center  w-100 mb-2">
            <Col xs={5} className="p-0">
              <h1 className="m-0 opacity-75">{Translate("End Date")}:</h1>
            </Col>
            <Col xs={6} className="p-0">
              <h1 className="m-0 fw-bold">{item?.end_date}</h1>
            </Col>
          </Row>
          <Row className="justify-content-center  w-100 mb-2">
            <Col xs={5} className="p-0">
              <h1 className="m-0 opacity-75">{Translate("Price")}:</h1>
            </Col>
            <Col xs={6} className="p-0">
              <h1 className="m-0 fw-bold">{item?.price}</h1>
            </Col>
          </Row>
          <Row className="justify-content-center  w-100 mb-2">
            <Col xs={5} className="p-0">
              <h1 className="m-0 opacity-75">{Translate("Status")}:</h1>
            </Col>
            <Col xs={6} className="p-0">
              <h1 className="m-0">
                <Badge
                  className="text-uppercase rounded fw-bolder"
                  bg={getStatusColor(item?.status)}
                >
                  {item?.status}
                </Badge>
              </h1>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default CustomerProjectCard;
