import { GetAcodaxPermission } from "module/common/Permissions/AcodaxPermission";
import QuickLeadForm from "module/Leads/Lead/QuickLeadForm";
import TaskForm from "module/Task/TaskForm/TaskForm";
import TicketForm from "module/Tickets/TicketForm/TicketForm";
import { React, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { BiSupport, BiTask } from "react-icons/bi";
import { BsPatchPlusFill } from "react-icons/bs";
import { SiGoogleadmob } from "react-icons/si";

const QuickLinkDropdown = ({ iconColor }) => {
  const [showTaskForm, setShowTaskForm] = useState(false);
  const [showTicketForm, setShowTicketForm] = useState(false);
  const [showQuickLeadForm, setShowQuickLeadForm] = useState(false);

  const permission = {
    createTask: GetAcodaxPermission("TASK", "create"),
    createLead: GetAcodaxPermission("LEAD", "store"),
    createTicket: GetAcodaxPermission("TICKET", "store"),
  };

  return (
    <>
      {showQuickLeadForm && (
        <QuickLeadForm
          handleCloseForm={() => setShowQuickLeadForm(false)}
          show={showQuickLeadForm}
          openFrom="quick_link"
        />
      )}
      {showTaskForm && (
        <TaskForm
          show={showTaskForm}
          handleCloseForm={() => setShowTaskForm(false)}
          openFrom="quick_link"
        />
      )}
      {showTicketForm && (
        <TicketForm
          show={showTicketForm}
          handleCloseForm={() => setShowTicketForm(false)}
          openFrom="quick_link"
        />
      )}
      <Dropdown navbar={true} as="li">
        <Dropdown.Toggle
          bsPrefix="toggle"
          variant="transparent"
          className="custom-transperent-common-button p-0 shadow-none"
          title="User Profile"
          style={{ marginBottom: "2px" }}
        >
          <BsPatchPlusFill size={20} color={iconColor} />
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end mt-2">
          <div className="bg-white rounded-2 py-1 dark__bg-1000">
            <div className="text-dark fw-normal text-center pb-1">
              QUICK LINKS
            </div>
            <Dropdown.Divider className="mt-0" />
            {permission?.createLead && (
              <Dropdown.Item
                className="d-flex align-items-center"
                onClick={() => setShowQuickLeadForm(true)}
              >
                <SiGoogleadmob size={16} className="me-1" />
                <span className="pt-1">Create Quick Lead</span>
              </Dropdown.Item>
            )}
            {permission?.createTask && (
              <Dropdown.Item
                className="d-flex align-items-center"
                onClick={() => setShowTaskForm(true)}
              >
                <BiTask size={19} className="me-1" />
                <span className="pt-1">Create Task</span>
              </Dropdown.Item>
            )}
            {permission?.createTicket && (
              <Dropdown.Item
                className="d-flex align-items-center"
                onClick={() => setShowTicketForm(true)}
              >
                <BiSupport size={19} className="me-1" />
                <span className="pt-1">Create Ticket</span>
              </Dropdown.Item>
            )}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default QuickLinkDropdown;
