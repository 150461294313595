import React, { useEffect, useRef, useState } from "react";
import { Dropdown, Form, Image } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import defaultImage from "../../assets/img/Avathar/ProfileImage.jpg";
import { FaEdit } from "react-icons/fa";
import axios from "axios";
import { showToast } from "module/common/Toast/toast";
import LoadingOverlay from "components/common/LoadingOverlay/LoadingOverlay";
import ListLoading from "module/common/ListLoading";

function UpdateAssigneeListDropDown({ url, getData, active = "" }) {
  const [dataProcessing, setDataProcessing] = useState(false);
  const [searchParams, setSearchParams] = useState("");
  const [assignees, setAssignees] = useState([]);
  const apiCallDelayRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const getOptions = (inputValue) =>
    new Promise((resolve, reject) => {
      setDataProcessing(true);
      try {
        if (apiCallDelayRef?.current) clearTimeout(apiCallDelayRef.current);
        apiCallDelayRef.current = setTimeout(
          async () => {
            const response = await axios.get("user/users-autocomplete", {
              params: {
                name: inputValue,
              },
            });

            let result = response?.data?.data;
            result = result?.data ? result?.data : result;

            let options = [];
            if (Array.isArray(result)) {
              options = result;
            }
            resolve(options);
            setDataProcessing(false);
          },
          inputValue?.length > 0 ? 1200 : 0
        );
      } catch (error) {
        console.error("error", error);
        reject(error);
        setDataProcessing(false);
      }
    });

  const fetchData = async (inputValue) => {
    const options = await getOptions(inputValue);
    setAssignees(options);
  };

  useEffect(() => {
    if (isOpen) fetchData(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, isOpen]);

  const handleSubmit = (id) => {
    setSubmitLoading(true);
    axios({
      method: "patch",
      url: url,
      params: {
        assigned_to: id,
      },
    })
      .then((response) => {
        if (response.data.success === true) {
          showToast(response.data.message, "success");
          getData();
        }
      })
      .catch((error) => {
        console.error("error", error);
        showToast(error.response.data.message, "error");
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  return (
    <>
      <Dropdown navbar={true} autoClose="outside" onToggle={handleToggle}>
        <Dropdown.Toggle
          bsPrefix="toggle"
          variant="transparent"
          className="custom-transperent-common-button p-0 shadow-none"
        >
          <FaEdit
            size={14}
            className="cursor-pointer"
            title="Update Assignee"
          />
        </Dropdown.Toggle>
        <Dropdown.Menu
          style={{ cursor: "default", minWidth: "230px" }}
          className="dropdown-menu-card dropdown-menu-end fs--1"
        >
          <Form.Control
            type="text"
            onChange={(e) => setSearchParams(e.target.value)}
            value={searchParams}
            className="mb-2 px-2"
            isLoading={dataProcessing}
          />
          {dataProcessing ? (
            <Dropdown.Item className="custom-select-dropdown-item inactive text-center p-0">
              <ListLoading
                style={{
                  height: "170px",
                  maxHeight: "250px",
                  overflow: "hidden",
                }}
              />
            </Dropdown.Item>
          ) : assignees?.length > 0 ? (
            <SimpleBar
              style={{
                maxHeight: "250px",
              }}
              className="custom-simplebar-content d-flex flex-column gap-1"
            >
              {assignees?.map((assignee, index) => {
                return (
                  <Dropdown.Item
                    key={index}
                    style={{
                      fontSize: "13px",
                      cursor: "pointer",
                      minWidth: "210px",
                    }}
                    className={`text-dark d-flex align-items-center gap-1 p-1 custom-select-dropdown-item ${
                      active === assignee?.id ? "active" : ""
                    }`}
                    onClick={() => {
                      handleSubmit(assignee?.id);
                    }}
                  >
                    <Image
                      src={assignee?.profile_image ?? defaultImage}
                      roundedCircle
                      className="collaborator-dropdown-avatar"
                      alt={assignee?.full_name}
                    />
                    <span className="me-2">{assignee?.full_name}</span>
                  </Dropdown.Item>
                );
              })}
            </SimpleBar>
          ) : (
            <Dropdown.Item className="custom-select-dropdown-item inactive text-center">
              <span>No Data Found</span>
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
      {submitLoading && <LoadingOverlay />}
    </>
  );
}

export default UpdateAssigneeListDropDown;
