import React, { Fragment, lazy, useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import is from "is_js";
import MainLayout from "./MainLayout";
import { toast, ToastContainer } from "react-toastify";
import AppContext from "context/Context";
import LandingPage from "LandingPage";
import RequireGuest from "helpers/RequireGuest";
import Error404 from "components/errors/Error404";
import { CloseButton } from "react-bootstrap";
import AuthOutlet from "module/Authentication/AuthOutlet";
import LoginForm from "module/Authentication/Page/Login/LoginForm";
import AccountRecoveryForm from "module/Authentication/Page/AccountRecovery/AccountRecoveryForm";
import ResetPasswordForm from "module/Authentication/Page/ResetPassword/ResetPasswordForm";
import RequireAuth from "helpers/RequireAuth";
import DeleteContextProvider from "providers/DeleteContextProvider";
import ExportContextProvider from "providers/ExportContextProvider";
import Dashboard from "module/Dashboard";
import ProjectForm from "module/Project/ProjectForm/ProjectForm";
import TaskForm from "module/Task/TaskForm/TaskForm";
import TicketForm from "module/Tickets/TicketForm/TicketForm";
import TaskOverview from "module/Task/TaskInfo/TaskOverview";
import TaskDashboard from "module/Task/TaskDashboard/TaskDashboard";
import ProjectInfo from "module/Project/ProjectInfo/ProjectInfo";
import TicketInfo from "module/Tickets/TicketInfo/TicketInfo";
import TicketOverView from "module/Tickets/TicketOveView/TicketOverView";
import TicketConversionForm from "module/Tickets/TicketConversion/TicketConversionForm";
// import EnquiryForm from "components/enquiry/EnquiryForm";
import InvitationRegister from "module/Invitation/InvitationRegister";
import Error403 from "components/errors/Error403/Error403";
import StoreProvider from "providers/StoreProvider";
import SprintForm from "module/Sprints/SprintForm/SprintForm";
import TaskOutlet from "module/Task/TaskOutlet";
import ProjectViewWrapper from "module/Project/ProjectTable/ProjectViewWrapper";
import ReminderForm from "module/common/Reminder/ReminderForm";
import TaskTable from "module/Task/TaskTable/TaskTable";
import SuspensePageLoaderUI from "../components/module-loader/SuspensePageLoaderUI";
import TicketTable from "../module/Tickets/TicketTable/TicketTable";

const EmployeeOutlet = lazy(() => import("module/Employee/EmployeeOutlet"));
const EmployeeTableView = lazy(() =>
  import("module/Employee/EmployeeTable/EmployeeTableView")
);
const EmployeeProfile = lazy(() =>
  import("module/Employee/EmployeeProfile/EmployeeProfile")
);
const EmployeeOverview = lazy(() =>
  import("module/Employee/EmployeeOverview/EmployeeOverview")
);
// const UserProfile = lazy(() =>
//   import("module/common/ViewPages/Common/UserProfile/UserProfile")
// );
const ProjectFormForEmployee = lazy(() =>
  import("module/Project/ProjectForm/ProjectForm")
);
const TicketFormForEmployee = lazy(() =>
  import("module/Tickets/TicketForm/TicketForm")
);
const TicketInfoForEmployee = lazy(() =>
  import("module/Tickets/TicketInfo/TicketInfo")
);
const ReminderFormForEmployee = lazy(() =>
  import("module/common/Reminder/ReminderForm")
);
const TaskFormForEmployee = lazy(() => import("module/Task/TaskForm/TaskForm"));
const LeadOutlet = lazy(() => import("module/Leads/Lead/LeadOutlet"));
const ViewToRender = lazy(() =>
  import("components/kanban/components/ViewToRender")
);
const LeadKanbanView = lazy(() => import("module/Leads/Lead/LeadKanbanView"));
const LeadTableView = lazy(() => import("module/Leads/Lead/LeadTableView"));
const ComposeEmailForLead = lazy(() =>
  import("module/common/ViewPages/Common/Email/Compose/ComposeEmail")
);
const LeadInfo = lazy(() => import("module/Leads/Lead/LeadInfo/LeadInfo"));
const ReminderFormForLead = lazy(() =>
  import("module/common/Reminder/ReminderForm")
);

const TeamTableView = lazy(() =>
  import("module/Teams/Team/TeamTable/TeamTableView")
);
const TeamForm = lazy(() => import("module/Teams/Team/TeamForm"));
const TeamInfo = lazy(() => import("module/Teams/TeamInfo/TeamInfo"));

const CampaignTableView = lazy(() =>
  import("module/Campaign/CampaignTable/CampaignTableView")
);
const CampaignForm = lazy(() => import("module/Campaign/CampaignForm"));

const DepartmentTableView = lazy(() =>
  import("module/Department/DepartmentTable/DepartmentTableView")
);
const DepartmentForm = lazy(() => import("module/Department/DepartmentForm"));

const DesignationTableView = lazy(() =>
  import("module/Designation/DesignationTable/DesignationTableView")
);
const DesignationForm = lazy(() =>
  import("module/Designation/DesignationForm")
);

const LabelsTable = lazy(() => import("module/Labels/LabelsTable"));
const LabelsForm = lazy(() => import("module/Labels/LabelsForm"));

const AnnouncementTableView = lazy(() =>
  import("module/Announcement/AnnouncementTableView")
);
const AnnouncementForm = lazy(() =>
  import("module/Announcement/AnnouncementForm")
);

const OpportunityOutlet = lazy(() =>
  import("module/Opportunity/OpportunityOutlet")
);
const Opportunity = lazy(() => import("module/Opportunity/Opportunity"));
const OpportunityForm = lazy(() =>
  import("module/Opportunity/OpportunityForm")
);
const OverView = lazy(() => import("module/Opportunity/OverView/OverView"));
const LeadOffcanvas = lazy(() =>
  import("module/Leads/Lead/LeadInfo/LeadOffcanvas")
);
const QuotationOverview = lazy(() =>
  import("module/Quotation/Overview/QuotationOverview")
);

const Report = lazy(() => import("module/Report/Report"));
const ProjectReportView = lazy(() =>
  import("module/Report/ReportView/ProjectReportView")
);
const TasksReportView = lazy(() =>
  import("module/Report/ReportView/TasksReportView")
);
const EmployeeTaskReport = lazy(() =>
  import("module/Report/ReportView/EmployeeTaskReport")
);
const EmployeeTaskSummaryReport = lazy(() =>
  import("module/Report/ReportView/EmployeeTaskSummaryReport")
);
const EmployeeLeadSummaryReport = lazy(() =>
  import("module/Report/ReportView/EmployeeLeadSummaryReport")
);
const LeadsReportView = lazy(() =>
  import("module/Report/ReportView/LeadsReportView")
);
const OpportunityReportView = lazy(() =>
  import("module/Report/ReportView/OpportunityReportView")
);
const TimeTrackerReportView = lazy(() =>
  import("module/Report/ReportView/TimeTrackerReportView")
);
const LeadConversionReport = lazy(() =>
  import("module/Report/ReportView/LeadConversionReport")
);
const LeadSourceSummeryReport = lazy(() =>
  import("module/Report/ReportView/LeadSourceSummeryReport")
);
const LeadSourceSummeryItemReport = lazy(() =>
  import("module/Report/ReportView/LeadSourceSummeryItemReport")
);
const RunningOrCompletedTasksReport = lazy(() =>
  import("module/Report/ReportView/RunningOrCompletedTasksReport")
);
const SprintDetailedReport = lazy(() =>
  import("module/Report/ReportView/SprintDetailedReport")
);

const TodoTableView = lazy(() => import("module/Todo/TodoTable/TodoTableview"));
const TodoForm = lazy(() => import("module/Todo/TodoForm"));

const EstimationOutlet = lazy(() =>
  import("module/Estimation/EstimationOutlet")
);
const EstimationTableView = lazy(() =>
  import("module/Estimation/EstimationTable/EstimationTableView")
);
const EstimationForm = lazy(() => import("module/Estimation/EstimationForm"));

const Enquiry = lazy(() => import("module/Enquiry/Enquiry"));
const EnquiryInnerForm = lazy(() => import("module/Enquiry/EnquiryInnerForm"));

const Invitation = lazy(() => import("module/Invitation/Invitation"));
const InvitationForm = lazy(() => import("module/Invitation/InvitationForm"));

const LeadStatusTableView = lazy(() =>
  import("module/Leads/LeadStatus/LeadStatusTableView")
);
const LeadStatusForm = lazy(() =>
  import("module/Leads/LeadStatus/LeadStatusForm")
);

const LeadSourceTableView = lazy(() =>
  import("module/Leads/LeadSource/LeadSourceTableView")
);
const LeadSourceForm = lazy(() =>
  import("module/Leads/LeadSource/LeadSourceForm")
);

const ProductsTableView = lazy(() =>
  import("module/Products/ProductsTableView")
);
const ProductsForm = lazy(() => import("module/Products/ProductsForm"));

const TicketTypeTable = lazy(() =>
  import("module/Tickets/TicketType/TicketTypeTable")
);
const TicketTypeForm = lazy(() =>
  import("module/Tickets/TicketType/TicketTypeForm")
);

const Emails = lazy(() =>
  import("module/common/ViewPages/Common/Email/Emails")
);
const Inbox = lazy(() =>
  import("module/common/ViewPages/Common/Email/Inbox/Inbox")
);
const ComposeEmail = lazy(() =>
  import("module/common/ViewPages/Common/Email/Compose/ComposeEmail")
);
const EmailDetail = lazy(() =>
  import("module/common/ViewPages/Common/Email/EmailDetail/EmailDetail")
);

const UsersTable = lazy(() =>
  import("module/SystemSettings/Users/UsersTable/UsersTable")
);
const UserProfileOverview = lazy(() =>
  import("module/SystemSettings/Users/UserProfile/UserProfileOverview")
);
const UserRegistrationForm = lazy(() =>
  import("module/SystemSettings/Users/UsersForm/UserRegistrationForm")
);
const UserPermissions = lazy(() =>
  import("module/SystemSettings/Users/UsersPermissions/UserPermissions")
);

const RolesTable = lazy(() =>
  import("module/SystemSettings/Roles/RolesTable/RolesTable")
);
const RolesForm = lazy(() =>
  import("module/SystemSettings/Roles/RolesForm/RolesForm")
);
const RolePermissions = lazy(() =>
  import("module/SystemSettings/Roles/RolesPermissions/RolePermissions")
);

const EmailConfigTable = lazy(() =>
  import("module/EmailConfig/EmailConfigTable")
);
const EmailConfigForm = lazy(() =>
  import("module/EmailConfig/EmailConfigForm")
);

const EmployeeWorkTimeReport = lazy(() =>
  import(
    "module/Report/ReportView/EmployeeWorkTimeReport/EmployeeWorkTimeReport"
  )
);

const SprintOverview = lazy(() =>
  import("module/Sprints/SprintOverview/SprintOverview")
);
const SprintTable = lazy(() =>
  import("module/Sprints/SprintTable/SprintTable")
);

const ClientOutlet = lazy(() => import("module/Client/ClientOutlet"));
const ClientViewWrapper = lazy(() => import("module/Client/ClientViewWrapper"));
const ClientForm = lazy(() => import("module/Client/ClientForm/ClientForm"));
const ClientDashboard = lazy(() =>
  import("module/Client/ClientDashboard/ClientDashboard")
);
const ClientOverView = lazy(() =>
  import("module/Client/ClientOverView/ClientOverView")
);

const ProjectWorkTimeReport = lazy(() =>
  import("module/Report/ReportView/ProjectWorkTimeReport/ProjectWorkTimeReport")
);
const DocumentTypeTableView = lazy(() =>
  import("module/DocumentType/DocumentTypeTableView")
);
const DocumentTypeForm = lazy(() =>
  import("module/DocumentType/DocumentTypeForm")
);

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName("html")[0].classList;
  useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add("windows");
    }
    if (is.chrome()) {
      HTMLClassList.add("chrome");
    }
    if (is.firefox()) {
      HTMLClassList.add("firefox");
    }
  }, [HTMLClassList]);

  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<LandingPage />} />

        {/*- ------------- Authentication ---------------------------  */}

        <Route
          path="authentication"
          element={
            <RequireGuest>
              <AuthOutlet />
            </RequireGuest>
          }
        >
          <Route index element={<LoginForm />} />
          <Route path="login" element={<LoginForm />} />
          <Route path="account-recovery" element={<AccountRecoveryForm />} />
          <Route path="reset-password" element={<ResetPasswordForm />} />
        </Route>

        <Route
          path="/"
          element={
            <RequireAuth>
              <StoreProvider>
                <DeleteContextProvider>
                  <ExportContextProvider>
                    <MainLayout />
                  </ExportContextProvider>
                </DeleteContextProvider>
              </StoreProvider>
            </RequireAuth>
          }
        >
          <Route path="dashboard" element={<Dashboard />} />

          <Route path="employee" element={<SuspensePageLoaderUI />}>
            <Route element={<EmployeeOutlet />}>
              <Route index element={<EmployeeTableView />} />
              <Route path="add" element={<EmployeeProfile />} />
              <Route path="edit/:itemId" element={<EmployeeProfile />} />
              <Route path="overview/:empId" element={<EmployeeOverview />}>
                {/* <Route path="profile/:profileId" element={<UserProfile />} /> */}
                <Route
                  path="project/add"
                  element={<ProjectFormForEmployee />}
                />
                <Route
                  path="project/edit/:itemId"
                  element={<ProjectFormForEmployee />}
                />
                <Route path="ticket/add" element={<TicketFormForEmployee />} />
                <Route
                  path="ticket/edit/:itemId"
                  element={<TicketFormForEmployee />}
                />
                <Route
                  path="ticket/info/:itemId"
                  element={<TicketInfoForEmployee />}
                />
                <Route path="reminder" element={<ReminderFormForEmployee />} />
                <Route path="task/add" element={<TaskFormForEmployee />} />
                <Route
                  path="task/edit/:itemId"
                  element={<TaskFormForEmployee />}
                />
                <Route
                  path="task/clone/:itemId"
                  element={<TaskFormForEmployee />}
                />
              </Route>
            </Route>
          </Route>

          <Route path="lead" element={<SuspensePageLoaderUI />}>
            <Route element={<LeadOutlet />}>
              <Route
                index
                element={
                  <ViewToRender
                    primaryComponent={LeadKanbanView}
                    secondaryComponent={LeadTableView}
                    module="lead"
                  />
                }
              />
              <Route path="kanban" element={<LeadKanbanView />} />
              <Route path="list" element={<LeadTableView />}>
                <Route path="send-mail" element={<ComposeEmailForLead />} />
              </Route>
              <Route path="info/:overviewId" element={<LeadInfo />}>
                <Route path="reminder" element={<ReminderFormForLead />} />
              </Route>
            </Route>
          </Route>

          <Route element={<SuspensePageLoaderUI />}>
            <Route path="teams" element={<TeamTableView />}>
              <Route path="add" element={<TeamForm />} />
              <Route path="info/:teamId" element={<TeamInfo />} />
              <Route path="edit/:itemId" element={<TeamForm />} />
            </Route>
          </Route>

          <Route element={<SuspensePageLoaderUI />}>
            <Route path="campaign" element={<CampaignTableView />}>
              <Route path="add" element={<CampaignForm />} />
              <Route path="edit/:itemId" element={<CampaignForm />} />
            </Route>
          </Route>

          <Route element={<SuspensePageLoaderUI />}>
            <Route path="department" element={<DepartmentTableView />}>
              <Route path="add" element={<DepartmentForm />} />
              <Route path="edit/:itemId" element={<DepartmentForm />} />
            </Route>
          </Route>

          <Route element={<SuspensePageLoaderUI />}>
            <Route path="designation" element={<DesignationTableView />}>
              <Route path="add" element={<DesignationForm />} />
              <Route path="edit/:itemId" element={<DesignationForm />} />
            </Route>
          </Route>

          <Route path="project" element={<ProjectViewWrapper />}>
            <Route path="add" element={<ProjectForm />} />
            <Route path="edit/:itemId" element={<ProjectForm />} />
            <Route path="info/:itemInfoId" element={<ProjectInfo />}>
              <Route path="ticket/add" element={<TicketForm />} />
              <Route path="ticket/edit/:itemId" element={<TicketForm />} />
              <Route path="ticket/info/:itemId" element={<TicketInfo />} />
              <Route path="sprints/add" element={<SprintForm />} />
              <Route path="sprints/edit/:itemId" element={<SprintForm />} />
              <Route path="reminder" element={<ReminderForm />} />
              <Route path="task/add" element={<TaskForm />} />
              <Route path="task/edit/:itemId" element={<TaskForm />} />
              <Route path="task/clone/:itemId" element={<TaskForm />} />
              {/* <Route path="profile/:profileId" element={<UserProfile />} /> */}
            </Route>
          </Route>

          <Route element={<SuspensePageLoaderUI />}>
            <Route path="sprints" element={<SprintTable />}>
              <Route path="add" element={<SprintForm />} />
              <Route path="edit/:itemId" element={<SprintForm />} />
              <Route path="info/:itemInfoId" element={<SprintOverview />}>
                <Route path="edit/:itemId" element={<SprintForm />} />
                <Route path="reminder" element={<ReminderForm />} />
                <Route path="task/add" element={<TaskForm />} />
                <Route path="task/edit/:itemId" element={<TaskForm />} />
                <Route path="task/clone/:itemId" element={<TaskForm />} />
              </Route>
            </Route>
          </Route>

          <Route path="tasks" element={<TaskOutlet />}>
            <Route path="dashboard" element={<TaskDashboard />}>
              <Route path="add" element={<TaskForm />} />
            </Route>
            <Route path="add" element={<TaskForm />} />
            <Route path="list" element={<TaskTable />}>
              <Route path="add" element={<TaskForm />} />
              <Route path="edit/:itemId" element={<TaskForm />} />
              <Route path="reminder" element={<ReminderForm />} />
              <Route path="overview/:overviewId" element={<TaskOverview />}>
                <Route path="edit/:itemId" element={<TaskForm />} />
                <Route path="info/:itemId" element={<TicketInfo />} />
                <Route path="clone/:itemId" element={<TaskForm />} />
                <Route path="reminder" element={<ReminderForm />} />
              </Route>
              <Route path="clone/:itemId" element={<TaskForm />} />
            </Route>
          </Route>

          <Route element={<SuspensePageLoaderUI />}>
            <Route path="todo" element={<TodoTableView />}>
              <Route path="reminder" element={<ReminderForm />} />
              <Route path="add" element={<TodoForm />} />
              <Route path="edit/:itemId" element={<TodoForm />} />
            </Route>
          </Route>

          <Route path="estimation" element={<SuspensePageLoaderUI />}>
            <Route element={<EstimationOutlet />}>
              <Route index element={<EstimationTableView />} />
              <Route path="add" element={<EstimationForm />} />
              <Route path="edit/:itemId" element={<EstimationForm />} />
            </Route>
          </Route>

          <Route path="tickets/list" element={<TicketTable />}>
            <Route path="add" element={<TicketForm />} />
            <Route path="edit/:itemId" element={<TicketForm />} />
            <Route path="info/:itemId" element={<TicketInfo />} />
            <Route path="task/edit/:itemId" element={<TaskForm />} />
            <Route path="task/clone/:itemId" element={<TaskForm />} />
            <Route path="overview/update/:itemId" element={<TicketForm />} />
            <Route path="overview/:overviewId" element={<TicketOverView />}>
              <Route path="add" element={<TodoForm />} />
              <Route path="task/edit/:itemId" element={<TaskForm />} />
              <Route path="task/clone/:itemId" element={<TaskForm />} />
            </Route>
            <Route
              path="convert/:ticketId"
              element={<TicketConversionForm />}
            />
          </Route>

          <Route element={<SuspensePageLoaderUI />}>
            <Route path="labels" element={<LabelsTable />}>
              <Route path="add" element={<LabelsForm />} />
              <Route path="edit/:itemId" element={<LabelsForm />} />
            </Route>
          </Route>

          <Route element={<SuspensePageLoaderUI />}>
            <Route path="announcement" element={<AnnouncementTableView />}>
              <Route path="add" element={<AnnouncementForm />} />
              <Route path="edit/:itemId" element={<AnnouncementForm />} />
            </Route>
          </Route>

          <Route path="reports" element={<SuspensePageLoaderUI />}>
            <Route index element={<Report />} />
            <Route path="project-report" element={<ProjectReportView />} />
            <Route path="task-report" element={<TasksReportView />} />
            <Route
              path="employee-task-report"
              element={<EmployeeTaskReport />}
            />
            <Route
              path="employee-task-summary-report"
              element={<EmployeeTaskSummaryReport />}
            />
            <Route
              path="employee-lead-report"
              element={<EmployeeLeadSummaryReport />}
            />
            <Route path="leads-report" element={<LeadsReportView />} />
            <Route
              path="opportunity-report"
              element={<OpportunityReportView />}
            />
            <Route
              path="time-tracker-report"
              element={<TimeTrackerReportView />}
            />
            <Route
              path="lead-conversion-report"
              element={<LeadConversionReport />}
            />
            <Route
              path="lead-source-summary-report"
              element={<LeadSourceSummeryReport />}
            />
            <Route
              path="lead-source-summary-report/:lead_source_id"
              element={<LeadSourceSummeryItemReport />}
            />
            <Route
              path="running-completed-tasks-report"
              element={<RunningOrCompletedTasksReport />}
            />
            <Route
              path="sprint-detailed-report"
              element={<SprintDetailedReport />}
            />
            <Route
              path="employee-work-time-report"
              element={<EmployeeWorkTimeReport />}
            />
            <Route
              path="project-work-time-report"
              element={<ProjectWorkTimeReport />}
            />
          </Route>

          <Route path="account" element={<SuspensePageLoaderUI />}>
            <Route element={<ClientOutlet />}>
              <Route index element={<ClientDashboard />} />
              <Route path="grid" element={<ClientViewWrapper />} />
              <Route path="list" element={<ClientViewWrapper />} />
              <Route path="add" element={<ClientForm />} />
              <Route path="edit/:customerId" element={<ClientForm />} />
              <Route path="overview/:client_id" element={<ClientOverView />}>
                <Route path="project/add" element={<ProjectForm />} />
                <Route path="project/edit/:itemId" element={<ProjectForm />} />
                <Route path="reminder" element={<ReminderForm />} />
                <Route path="task/add" element={<TaskForm />} />
                <Route path="task/edit/:itemId" element={<TaskForm />} />
                <Route path="task/clone/:itemId" element={<TaskForm />} />
              </Route>
            </Route>
          </Route>

          <Route path="opportunity" element={<SuspensePageLoaderUI />}>
            <Route element={<OpportunityOutlet />}>
              <Route index element={<Opportunity />} />
              <Route path="add" element={<OpportunityForm />} />
              <Route path="edit/:itemId" element={<OpportunityForm />} />
              <Route path="overview/:itemId" element={<OverView />}>
                <Route path="reminder" element={<ReminderForm />} />
                <Route path="lead/:overviewId" element={<LeadOffcanvas />} />
                <Route
                  path="quotation/:quoteId"
                  element={<QuotationOverview />}
                />
              </Route>
            </Route>
          </Route>

          <Route element={<SuspensePageLoaderUI />}>
            <Route path="enquiries" element={<Enquiry />}>
              <Route path="edit/:enqId" element={<EnquiryInnerForm />} />
            </Route>
          </Route>

          <Route element={<SuspensePageLoaderUI />}>
            <Route path="invitation" element={<Invitation />}>
              <Route path="edit/:invId" element={<InvitationForm />} />
            </Route>
          </Route>

          <Route path="settings">
            <Route path="system-settings" element={<SuspensePageLoaderUI />}>
              <Route path="labels" element={<LabelsTable />}>
                <Route path="add" element={<LabelsForm />} />
                <Route path="edit/:itemId" element={<LabelsForm />} />
              </Route>
            </Route>
            <Route path="lead-settings" element={<SuspensePageLoaderUI />}>
              <Route path="lead-status" element={<LeadStatusTableView />}>
                <Route path="add" element={<LeadStatusForm />} />
                <Route path="edit/:itemId" element={<LeadStatusForm />} />
              </Route>
              <Route path="lead-source" element={<LeadSourceTableView />}>
                <Route path="add" element={<LeadSourceForm />} />
                <Route path="edit/:itemId" element={<LeadSourceForm />} />
              </Route>
              <Route path="products" element={<ProductsTableView />}>
                <Route path="add" element={<ProductsForm />} />
                <Route path="edit/:itemId" element={<ProductsForm />} />
              </Route>
            </Route>
            <Route path="employee-settings" element={<SuspensePageLoaderUI />}>
              <Route path="department" element={<DepartmentTableView />}>
                <Route path="add" element={<DepartmentForm />} />
                <Route path="edit/:itemId" element={<DepartmentForm />} />
              </Route>
              <Route path="designation" element={<DesignationTableView />}>
                <Route path="add" element={<DesignationForm />} />
                <Route path="edit/:itemId" element={<DesignationForm />} />
              </Route>
            </Route>
            <Route path="ticket-settings" element={<SuspensePageLoaderUI />}>
              <Route path="types" element={<TicketTypeTable />}>
                <Route path="add" element={<TicketTypeForm />} />
                <Route path="edit/:itemId" element={<TicketTypeForm />} />
              </Route>
            </Route>
          </Route>

          <Route element={<SuspensePageLoaderUI />}>
            <Route path="email" element={<Emails />}>
              <Route path="inbox" element={<Inbox />}>
                <Route path="add" element={<ComposeEmail />} />
              </Route>
              <Route path="email-detail/:emailId" element={<EmailDetail />}>
                <Route path="add" element={<ComposeEmail />} />
              </Route>
            </Route>
          </Route>

          <Route path="system-admin">
            <Route path="user-roles" element={<SuspensePageLoaderUI />}>
              <Route path="users" element={<UsersTable />}>
                <Route
                  path="profile/:userId"
                  element={<UserProfileOverview />}
                />
                <Route path="add" element={<UserRegistrationForm />} />
              </Route>
              <Route path="users-permissions" element={<UserPermissions />} />
              <Route path="roles" element={<RolesTable />}>
                <Route path="add" element={<RolesForm />} />
                <Route path="edit/:roleId" element={<RolesForm />} />
              </Route>
              <Route path="roles-permissions" element={<RolePermissions />} />
            </Route>
          </Route>

          <Route element={<SuspensePageLoaderUI />}>
            <Route path="email-config" element={<EmailConfigTable />}>
              <Route path="add" element={<EmailConfigForm />} />
              <Route path="edit/:emailConfigId" element={<EmailConfigForm />} />
            </Route>
          </Route>

          <Route element={<SuspensePageLoaderUI />}>
            <Route path="document-type" element={<DocumentTypeTableView />}>
              <Route path="add" element={<DocumentTypeForm />} />
              <Route
                path="edit/:documentTypeId"
                element={<DocumentTypeForm />}
              />
            </Route>
          </Route>
        </Route>
        {/*- ------------- Authentication ---------------------------  */}

        {/* <Route path="enquiry" element={<EnquiryForm />} /> */}
        <Route path="invitation/:id" element={<InvitationRegister />} />
        <Route path="errors/404" element={<Error404 />} />
        <Route path="*" element={<Error404 />} />
        <Route path="errors/403" element={<Error403 />} />
      </Routes>

      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </Fragment>
  );
};

export default Layout;
