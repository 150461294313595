const storeReduser = (state, action) => {
    switch (action.type) {
      case 'ADD-ITEM':
        return { ...state, [action.key]: action.payload };
  
      default:
        return state;
    }
  };
  export default storeReduser;
  