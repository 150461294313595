import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import logolight from "../../assets/img/logos/Accodax/Asset 2.svg";
import logoBlack from "../../assets/img/logos/Accodax/Asset 1.svg";

const Logo = ({ at, className, isNavbarVerticalCollapsed,mouseEnter, ...rest }) => {
  return (
    <Link
      to="/"
      className={classNames(
        "text-decoration-none",
        { "navbar-brand text-left": at === "navbar-vertical" },
        { "navbar-brand text-left": at === "navbar-top" }
      )}
      {...rest}
    >
      <div
        className={classNames(
          "d-flex",
          {
            "align-items-center py-3": at === "navbar-vertical",
            "align-items-center": at === "navbar-top",
            "flex-center fw-bolder fs-5 mb-4": at === "auth",
          },
          className
        )}
      >
        {!isNavbarVerticalCollapsed ? (
          <img
            className="mt-1"
            src={at === "navbar-top" ? logoBlack : logolight}
            alt="Logo"
            style={{ width: "8rem" }}
          />
        ) : (
          <img
            className={`mt-1 ${mouseEnter ? 'ms-1' : 'ms-4'}`}
            src={mouseEnter ? logolight : logoBlack}
            alt="Logo"
            style={{ width: "8rem" }}
          />
        )}
      </div>
    </Link>
  );
};

Logo.propTypes = {
  at: PropTypes.oneOf(["navbar-vertical", "navbar-top", "auth"]),
  width: PropTypes.number,
  className: PropTypes.string,
  textClass: PropTypes.string,
};

Logo.defaultProps = { at: "auth", width: 58 };

export default Logo;
