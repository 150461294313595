const getProjectFlagWithProps = (status) => {
  const statusWithColor = [
    {
      key: "Completed",
      value: "#1A5319",
    },
    {
      key: "OnTrack",
      value: "#06D001",
    },
    {
      key: "Caution",
      value: "#ffc107",
    },
    {
      key: "AtRisk",
      value: "#ff7e4c",
    },
    {
      key: "Overdue",
      value: "#FF0000",
    },
  ];

  if (status) {
    const foundObject = statusWithColor.find((obj) => obj.key === status);
    return status ? foundObject?.value ?? "grey" : "";
  }
};

export default getProjectFlagWithProps;
