import { React, useLayoutEffect, useState } from "react";
import {
  Button,
  ListGroup,
  ListGroupItem,
  OverlayTrigger,
  Spinner,
  Stack,
  Tooltip,
} from "react-bootstrap";
import axios from "axios";

import { AiFillEdit } from "react-icons/ai";
import { FaPlus } from "react-icons/fa";

import { apiCall } from "helpers/apiCalls";
import { labeslsFormKeys } from "helpers/formKeys";
import { showToast } from "module/common/Toast/toast";
import MutiSelectTaskLabel from "components/form/MutiSelectTaskLabel";
import removeRefData from "helpers/removeRefData";
import ListLoading from "module/common/ListLoading";

const Labels = ({ taskiId, refresh }) => {
  const [labelData, setLabelData] = useState([]);
  const [formData, setFormData] = useState(labeslsFormKeys);
  const [dataProcessing, setDataProcessing] = useState(false);
  const [onSave, setOnSave] = useState(false);
  const [onEdit, setOnEdit] = useState(false);

  useLayoutEffect(() => {
    taskiId && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskiId, refresh]);

  const getData = async () => {
    setDataProcessing(true);
    const data = await apiCall({
      url: `crm/task-labels/${taskiId}`,
    });

    setLabelData(data);
    setFormData({
      ...formData,
      subject_type: "task",
      task_id: taskiId,
      labels: [],
      labels_ref: [],
    });

    setDataProcessing(false);
  };

  const handleFieldChange = (e, action) => {
    let newFormData;
    let value;

    if (action) {
      if (
        action.action === "select-option" ||
        action.action === "remove-value"
      ) {
        value = e.map((item) => item.value);
      }
      if (action.action === "clear") {
        value = [];
      }
    }

    newFormData = {
      ...formData,
      [action.name]: value,
      [action.name + "_ref"]: e,
    };

    setFormData(newFormData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (onSave) return;
    setOnSave(true);
    let formDataWithoutRefkeys = removeRefData(formData);
    labelData.length > 0 &&
      labelData.map((item) => formDataWithoutRefkeys.labels.push(item.id));

    axios({
      method: "get",
      url: `crm/task-labels-update`,
      params: formDataWithoutRefkeys,
    })
      .then((response) => {
        if (response.data.success === true) {
          getData();
          setFormData({
            ...formData,
            task_id: taskiId,
          });
          showToast(response.data.message, "success");
          setOnEdit(false);
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
        showToast(
          "Something went wrong, please refresh the page and try again.",
          "error"
        );
      })
      .finally(() => {
        setOnSave(false);
      });
  };

  const handleEdit = () => {
    setOnEdit(true);
    setFormData({
      ...formData,
      labels: [...formData.labels, ...labelData.map(({ id }) => id)],
      labels_ref: [
        ...formData.labels_ref,
        ...labelData.map(({ id, label }) => ({
          label: label,
          value: id,
        })),
      ],
    });

    setLabelData([]);
  };

  return (
    <div>
      <ListGroup>
        <ListGroupItem className="border-0 ps-0 pe-0 pt-0">
          <div className="d-flex gap-3" style={{ minWidth: "100%" }}>
            <div className="w-100">
              <MutiSelectTaskLabel
                name="labels"
                handleFieldChange={handleFieldChange}
                value={formData.labels_ref}
                excludeLabels={labelData}
              />
            </div>
            <div className="d-flex align-items-center">
              <Button
                size="sm"
                onClick={handleSubmit}
                disabled={formData?.labels?.length > 0 || onEdit ? false : true}
              >
                {onSave ? (
                  <Spinner style={{ width: "20px", height: "20px" }} />
                ) : (
                  <FaPlus size={13} />
                )}
              </Button>
            </div>
          </div>
        </ListGroupItem>
        {!dataProcessing ? (
          Object.keys(labelData).length > 0 ? (
            <ListGroupItem className="border rounded-0 d-flex">
              <Stack direction="horizontal">
                <div className="d-flex flex-wrap gap-2">
                  {labelData.map((label, key) => (
                    <span
                      key={key}
                      className="badge p-2"
                      style={{
                        backgroundColor: label.colour,
                        minWidth: "50px",
                      }}
                    >
                      {label.label}
                    </span>
                  ))}
                </div>
              </Stack>
              <div className="ms-auto">
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id="button-tooltip-2">Edit</Tooltip>}
                >
                  <Button
                    size="sm"
                    variant="transparent"
                    className="p-0 custom-transperent-common-button"
                    onClick={handleEdit}
                  >
                    <AiFillEdit />
                  </Button>
                </OverlayTrigger>
              </div>
            </ListGroupItem>
          ) : !onEdit ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "9vh" }}
            >
              <span className=""> No Labels Yet &#128521;</span>
            </div>
          ) : (
            ""
          )
        ) : (
          <ListLoading style={{ maxHeight: "9vh", overflow: "hidden" }} />
        )}
      </ListGroup>
    </div>
  );
};

export default Labels;
