import Flex from "components/common/Flex";
import {
  ConvertButton,
  CustomBackButton,
  CustomDeleteButton,
  CustomEditButton,
} from "module/common/Buttons/AppButtons";
import RibbonButtons from "module/common/Buttons/RibbonButtons";
import { GetAcodaxPermission } from "module/common/Permissions/AcodaxPermission";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import TicketForm from "../TicketForm/TicketForm";

const TicketOverviewHeader = ({
  handleDelete,
  overviewData,
  dataToRibbonButton,
  getData,
}) => {
  const { overviewId } = useParams();
  let itemId = overviewId;
  const navigate = useNavigate();
  const [showTicketForm, setShowTicketForm] = useState(false);

  const permission = {
    delete: GetAcodaxPermission("TICKET", "delete"),
    update: GetAcodaxPermission("TICKET", "update"),
  };
  const fetchData = getData;

  return (
    <Card className="border-bottom">
      <Card.Header className="d-flex flex-between-center">
        <div className="ms-2">
          <RibbonButtons dataToRibbonButton={dataToRibbonButton} />
        </div>
        <Flex>
          {!overviewData?.task && (
            <ConvertButton
              onClick={() => navigate(`/tickets/list/convert/${overviewId}`)}
              size="sm"
            />
          )}
          {permission?.update && (
            <CustomEditButton
              variant="light"
              onClick={() => setShowTicketForm(true)}
              label="Edit Task"
              className="shadow me-2 ms-2"
            />
          )}
          {permission?.delete && (
            <CustomDeleteButton
              variant="light"
              title="Back"
              onClick={handleDelete}
              className={"shadow"}
            />
          )}
          <CustomBackButton
            onClick={() => navigate(-1)}
            size="sm"
            className={"ms-2"}
          />
        </Flex>
      </Card.Header>
      {showTicketForm && (
        <TicketForm
          show={showTicketForm}
          handleCloseForm={() => setShowTicketForm(false)}
          openFrom="ticket_overview"
          ticketId={itemId}
          fetchData={fetchData}
        />
      )}
    </Card>
  );
};

export default TicketOverviewHeader;
