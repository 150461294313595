import { React, useEffect, useRef, useState } from "react";
import { Placeholder } from "react-bootstrap";
import PropTypes from "prop-types";

function ScrollToLoadMore({ row, column, nextLink, loadNextPage }) {
  const componentRef = useRef(null);
  const [rowRef, setRowRef] = useState([]);
  const [colRef, setColRef] = useState([]);

  const addArrayItems = (count) => {
    let array = [];
    for (let index = 0; index < count; index++) {
      array.push(index);
    }
    return array;
  };

  useEffect(() => {
    if (Array.isArray(row)) {
      setRowRef(row);
    } else if (Number.isInteger(row) && row > 0) {
      setRowRef(addArrayItems(row));
    }

    if (Array.isArray(column)) {
      setRowRef(column);
    } else if (Number.isInteger(column) && column > 0) {
      setColRef(addArrayItems(column));
    }

    return () => {
      setRowRef([]);
      setColRef([]);
    };
  }, [column, row]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        loadNextPage();
      }
    }, options);
    observer.observe(componentRef.current);
    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextLink]);

  return (
    <tbody ref={componentRef}>
      {rowRef.map((key) => (
        <tr key={key}>
          {colRef.map((key) => (
            <td key={key} className="p-1">
              <Placeholder as="p" animation="glow" className="m-0">
                <Placeholder
                  className="w-100"
                  size="sm"
                  bg="secondary"
                  style={{ opacity: "0.01" }}
                />
              </Placeholder>
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
}

ScrollToLoadMore.propTypes = {
  row: PropTypes.any,
  column: PropTypes.any,
  loadNextPage: PropTypes.func,
  nextLink: PropTypes.any,
};

export default ScrollToLoadMore;
