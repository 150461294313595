import { React, useContext, useEffect } from "react";
import DatePicker from "react-date-picker";
import PropTypes from "prop-types";

import { FaCalendar, FaExclamationCircle, FaTimes } from "react-icons/fa";

import { AuthWizardContext } from "context/Context";

import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

const AppDatePicker = ({
  value,
  name,
  className,
  onChange,
  isInvalid,
  format,
  showDefaultDate,
  clearable = true,
  ...rest
}) => {
  let { user } = useContext(AuthWizardContext);
  const handleFieldChange = (date, name) => {
    let formatedDate = date
      ? date.getFullYear().toString().padStart(4, "0") +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + date.getDate()).slice(-2)
      : null;

    onChange({ target: { name: name, value: formatedDate } });
  };

  const setDateFomat = (format) => {
    const dateFormatArrayWithAllowdkeysObject = {
      dd: "dd",
      mm: "MM",
      MM: "MM",
      yyyy: "y",
      y: "y",
    };

    let dateFormat = "y-MM-dd";
    let formatItemKeysArray =
      typeof format === "string" ? format.split("-") : [];
    if (
      formatItemKeysArray.length === 3 &&
      formatItemKeysArray.includes("dd") &&
      (formatItemKeysArray.includes("mm") ||
        formatItemKeysArray.includes("MM")) &&
      (formatItemKeysArray.includes("yyyy") ||
        formatItemKeysArray.includes("y"))
    ) {
      let newFormatArrayWithAllowdkeys = [];
      formatItemKeysArray.map((key) =>
        newFormatArrayWithAllowdkeys.push(
          dateFormatArrayWithAllowdkeysObject[key]
        )
      );
      dateFormat = newFormatArrayWithAllowdkeys.join("-");
    }
    return dateFormat;
  };

  useEffect(() => {
    if (!value && showDefaultDate) {
      handleFieldChange(new Date(), name);
    }
  });

  return (
    <DatePicker
      className={`custome-date-picker${className ? " " + className : ""}${
        isInvalid ? " is-invalid " : ""
      }`}
      calendarClassName={"custome-date-picker-calendar"}
      value={
        value
          ? new Date(value)
          : showDefaultDate && value !== null
          ? new Date()
          : null
      }
      calendarIcon={
        isInvalid ? (
          <FaExclamationCircle size={12} color="#dc3545" />
        ) : !value ? (
          <FaCalendar size={12} color="#b6c1d2" />
        ) : null
      }
      clearIcon={
        value && clearable ? <FaTimes size={12} color="#b6c1d2" /> : null
      }
      onChange={(date) => handleFieldChange(date, name)}
      format={setDateFomat(
        format
          ? format
          : user && user.branch && user.branch.date_format
          ? user.branch.date_format
          : "y-MM-dd"
      )}
      {...rest}
    />
  );
};

AppDatePicker.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  isInvalid: PropTypes.bool,
  format: PropTypes.string,
  showDefaultDate: PropTypes.bool,
  clearable: PropTypes.bool,
};

export default AppDatePicker;
