import React from "react";
import { Card } from "react-bootstrap";
import PropTypes from "prop-types";
import ActivityTimeLine from "module/common/ViewPages/Common/Activity/Timeline/ActivityTimeLine";

function Activity({ triggerActivityLog, itemId, type }) {
  const isProject = type === "PROJECT";
  return (
    <Card className="card-main rounded-0 shadow-none h-100">
      <Card.Header
        className={`rounded-0 pb-2 pt-2`}
        style={{
          backgroundColor: isProject ? "#edf3f8" : "#5f6f83",
          color: isProject ? "#000" : "#fff",
        }}
      >
        <span className="mb-0 fs--1">ACTIVITY</span>
      </Card.Header>
      <Card.Body
        style={{
          overflow: "none",
          overflowY: "scroll",
          height: "70vh",
        }}
      >
        <ActivityTimeLine
          triggerActivityLog={triggerActivityLog}
          itemId={itemId}
          height={"50rem"}
          type={type}
        />
      </Card.Body>
    </Card>
  );
}

Activity.propTypes = {
  data: PropTypes.any,
};

export default Activity;
