import useAutoCompleteApiCallHandler from "hooks/useAutoCompleteApiCallHandler";
import React from "react";
import AsyncSelect from "react-select/async";

const SelectSprint = ({
  handleFieldChange,
  name,
  error,
  value,
  style,
  placeholder,
  disabled,
  onFocus,
  projectId,
}) => {
  const { fetchData, setDefaultResult, loading, defaultOptions } =
    useAutoCompleteApiCallHandler({
      url: "crm/sprint-autocomplete",
      setParams: (searchKey) => ({ name: searchKey, project_id: projectId }),
      setOptions: (option) => setOptionData(option),
      onFocus: onFocus,
      dependencyKeyToResetDefaultOptions: projectId,
    });

  const setOptionData = (option) => ({
    label: option.name,
    value: option.id,
  });

  return (
    <AsyncSelect
      cacheOptions
      loadOptions={fetchData}
      onFocus={setDefaultResult}
      defaultOptions={defaultOptions}
      isLoading={loading}
      isClearable
      isDisabled={disabled}
      name={name ? name : "sprint"}
      value={value}
      placeholder={placeholder ?? ""}
      onChange={handleFieldChange}
      className={error && "form-control ps-0 py-0 is-invalid"}
      styles={{
        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? "#696c96" : "inherit",
          "&:hover": {
            backgroundColor: state.isSelected ? "#192E49" : "#e1e2ea",
          },
        }),
        control: (provided) => ({
          ...provided,
          ...style,
        }),
      }}
    />
  );
};

export default SelectSprint;
