import { React, useEffect, useState } from "react";
import {
  Outlet,
  useLocation,
  createSearchParams,
  useSearchParams,
} from "react-router-dom";
import { Col, Row } from "react-bootstrap";

import { apiCall } from "helpers/apiCalls";
import InfoTile from "./Common/InfoTile";
import DashboardListHeading from "./Common/DashboardListHeading";
import ListTile from "./Common/ListTile";
import OverviewChart from "./Common/OverviewChart";
import AssigneeOverview from "./Common/AssigneeOverview";
import TaskCompletion from "./Common/TaskCompletion";
import StatusSummary from "./Common/StatusSummary";

const TaskDashboard = () => {
  const location = useLocation();
  const [dashboardData, setDashboardData] = useState({});
  const [dashboardLoading, setDashboardLoading] = useState(false);
  let todayDate = new Date().toISOString().slice(0, 10);
  const [queryParams] = useSearchParams();
  const statusFilter = queryParams.get("status_filter");
  const filterItems = queryParams.get("filters");
  const pageNumber = queryParams.get("page");
  const todayDeadlineTasksDefaultFilter = {
    date_range: "Today",
    date_range_for: "end_date",
    from_date: todayDate,
    to_date: todayDate,
  };

  const defaultStatusFilter = [
    "to_do",
    "in_progress",
    "in_review",
    "hold",
    "need_clarification",
    "bug",
    "deployed",
    "cancelled",
    "reopened",
  ];

  const outstandingTasksDefaultFilter = {
    date_range: "Till Today",
    date_range_for: "end_date",
    to_date: todayDate,
    status_filter: defaultStatusFilter.join("/"),
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, filterItems, statusFilter]);

  const getData = async () => {
    setDashboardLoading(true);
    try {
      const resData = await apiCall({
        url: "crm/task-summary",
      });

      setDashboardData(resData);
      setDashboardLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const totalTasks = {
    status: "total_tasks",
    count: dashboardData.total_no_of_task,
    percentage: "",
  };

  return (
    <>
      <Outlet context={[getData]} />
      {!location.pathname.includes("/tasks/list") && (
        <Row className="gx-2 d-flex">
          <Col lg={12} md={12} className="d-flex flex-column gap-2">
            <div className="custom-status-tasks-tiles">
              <Row className="g-4">
                <InfoTile
                  totalTasks={totalTasks}
                  dashboardData={dashboardData}
                  loading={dashboardLoading}
                  loadingBox={10}
                />
              </Row>
            </div>
            <Row className="g-2">
              <Col xl={9} md={9} sm={12}>
                <div className="custom-escalated-tasks-div h-100">
                  <Row className="gx-2 d-flex h-100">
                    <Col md={6} className="">
                      <DashboardListHeading
                        heading={"Today's Deadline Tasks"}
                        route={`/tasks/list?${createSearchParams(
                          todayDeadlineTasksDefaultFilter
                        )}`}
                      />
                      <ListTile
                        head
                        taskCount={dashboardData.todays_task_count}
                        taskCompletedCount={
                          dashboardData.todays_completed_task_count
                        }
                        taskPercentage={
                          dashboardData.todays_completed_task_percentage
                        }
                        data={dashboardData.deadline_today}
                        loading={dashboardLoading}
                      />
                    </Col>
                    <Col md={6} className="h-100">
                      <DashboardListHeading
                        heading={"Outstanding Tasks"}
                        route={`/tasks/list?${createSearchParams(
                          outstandingTasksDefaultFilter
                        )}`}
                      />
                      <ListTile
                        taskCount={dashboardData.todays_task_count}
                        taskCompletedCount={
                          dashboardData.todays_completed_task_count
                        }
                        taskPercentage={
                          dashboardData.todays_completed_task_percentage
                        }
                        data={dashboardData.deadline_over}
                        loading={dashboardLoading}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col xl={3} md={3} sm={12}>
                <StatusSummary
                  dashboardData={dashboardData}
                  loading={dashboardLoading}
                />
                <TaskCompletion
                  dashboardData={dashboardData}
                  loading={dashboardLoading}
                />
              </Col>
              <Col xl={9} md={9} sm={12}>
                <OverviewChart
                  dashboardData={dashboardData}
                  loading={dashboardLoading}
                />
              </Col>
              <Col xl={3} md={3} sm={12}>
                <AssigneeOverview
                  dashboardData={dashboardData}
                  loading={dashboardLoading}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default TaskDashboard;
