import axios from "axios";
import { accountRecoveryFormKeys } from "helpers/formKeys";
import generateFormDataHelper from "helpers/generateFormDataHelper";
import { showToast } from "module/common/Toast/toast";
import React, { useState } from "react";
import { Alert, Button, Form, InputGroup, Spinner } from "react-bootstrap";
import { FaArrowRight, FaLock, FaRedo, FaUser } from "react-icons/fa";
import mailSuccessImage from "../../asset/images/mail-success.jpg";
import { Link, useSearchParams } from "react-router-dom";

const AccountRecoveryForm = () => {
  const [validatingUser, setValidatingUser] = useState(false);
  const [formData, setFormData] = useState(accountRecoveryFormKeys);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errors, setErrors] = useState({});
  const [queryParams] = useSearchParams();
  const loginAs = queryParams.get("as");

  const handleFieldChange = (e) =>
    setFormData(generateFormDataHelper(e, null, formData));

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validatingUser) return;

    setErrors({});

    if (!formData.username)
      return showToast(`Invalid request, username is required.`, "error");

    setValidatingUser(true);

    axios
      .post(
        loginAs === "user"
          ? `forgot-password`
          : `send-customer-password-reset-link`,
        { ...formData, app_name: "CRM" }
      )
      .then((response) => {
        if (response.data.success === true) {
          setFormData(accountRecoveryFormKeys);
          setSuccessMessage(response.data.message);
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            "error"
          );
        }
        setValidatingUser(false);
      })
      .catch((error) => {
        setValidatingUser(false);
        if (!error.response.data || !error.response.data.message)
          return showToast(
            `Something went wrong, please refresh the page and try again.`,
            "error"
          );

        const validationError = error?.response?.data?.data?.errors;
        setErrors({ password: validationError?.[0]?.[0] || "" });
        showToast(error.response.data.message, "error");
      });
  };

  return successMessage ? (
    <div className="d-flex flex-column align-items-center mail-success text-center">
      <div>
        <img
          src={mailSuccessImage}
          alt="email success"
          loading="lazy"
          className="w-100"
        />
      </div>
      <p className="title text-dark fw-bold my-3">Success!</p>
      <p>{successMessage}</p>
      <Button
        variant="danger"
        className="rounded d-flex flex-row justify-content-center align-items-center"
        size="sm"
        onClick={() => setSuccessMessage(null)}
      >
        <span>Retry</span>
        <FaRedo className="ms-1" size={11} />
      </Button>
    </div>
  ) : (
    <Form onSubmit={handleSubmit}>
      {Object.keys(errors).length > 0 ? (
        <Form.Group className="mb-3">
          <Alert variant="danger">{errors.password}</Alert>
        </Form.Group>
      ) : null}

      <Form.Group className="mb-3">
        <p className="m-0 text-muted info">
          Lost your account? Don't worry, we will send reset instructions to
          your registered email address.
        </p>
      </Form.Group>

      <Form.Group className="mb-3" controlId="username">
        <Form.Label>Username / Email</Form.Label>
        <InputGroup
          className={` rounded shadow py-1 ${
            formData.username.length > 0 && !errors.username
              ? "is-valid"
              : errors.username
              ? "is-invalid"
              : ""
          }`}
        >
          <InputGroup.Text className="border-0 bg-transparent px-2">
            <FaUser size={10} />
          </InputGroup.Text>
          <Form.Control
            className="border-0"
            placeholder="Enter username or email"
            name="username"
            onChange={handleFieldChange}
            value={formData.username}
            isValid={formData.username.length > 0 && !errors.username}
            isInvalid={!!errors.username || !!errors.password}
          />
        </InputGroup>
      </Form.Group>

      <Form.Group className="d-flex flex-row">
        <Button
          type="submit"
          className="rounded w-100 py-2 d-flex flex-row justify-content-center align-items-center"
          disabled={validatingUser}
          style={{ backgroundColor: "#6AABAC", borderColor: "#6AABAC" }}
        >
          {validatingUser ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                variant="white"
                className="me-1"
                style={{ width: "12px", height: "12px" }}
              />
              <span className="fs--1">Validating...</span>
            </>
          ) : (
            <>
              <span>Send recovery link</span>
              <FaArrowRight className="ms-2" size={15} />
            </>
          )}
        </Button>
      </Form.Group>

      <Form.Group className="mb-3 mt-3 d-flex justify-content-center align-items-center">
        <Button
          className={`d-flex flex-row align-items-center rounded py-1 ${"border-0"} `}
          size="sm"
          variant={"outline-secondary"}
          style={{ boxShadow: "none" }}
          as={Link}
          to={`/authentication/login?as=${loginAs}`}
        >
          <FaLock size={13} />
          <span className="ms-1 small">Login</span>
        </Button>
      </Form.Group>
    </Form>
  );
};

export default AccountRecoveryForm;
