import { React, useContext, useEffect, useState } from "react";
import {
  Link,
  Outlet,
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Badge, Button, Card, Image } from "react-bootstrap";
import qs from "qs";

import { FaCircle, FaMoneyBillWave } from "react-icons/fa";
import { BiShuffle, BiSupport } from "react-icons/bi";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { FaRegCircleXmark } from "react-icons/fa6";

import {
  AddButton,
  DeleteButton,
  EditButton,
} from "module/common/Buttons/AppButtons";
import {
  AuthWizardContext,
  DeleteContext,
  StoreContext,
} from "context/Context";
import { MODULES } from "constants/Constants";
import { apiCall } from "helpers/apiCalls";
import { stringLimiter } from "helpers/utils";
import { FilterCommonButton } from "module/common/Buttons/CommonButton";
import { commonTicketFilterFormKeys } from "helpers/formKeys";
import { GetAcodaxPermission } from "module/common/Permissions/AcodaxPermission";
import ProfileImage from "../../../assets/img/Avathar/ProfileImage.jpg";
import CustomerDetails from "components/customer-details/CustomerDetails";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import SimpleBar from "simplebar-react";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import UserProfile from "module/common/Offcanvas/UserProfile/UserProfile";
import TicketInfo from "../TicketInfo/TicketInfo";
import getTicketStatusWithProps from "module/common/helpers/getTicketStatusWithProps";
import getPriorityWithProps from "module/common/helpers/getPriorityWithProps";
import getTaskStatusWithProps from "module/common/helpers/getTaskStatusWithProps";
import removeEmptyFields from "helpers/removeEmptyFields";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import AppPagination from "components/common/app-pagination/AppPagination";
import ExportButton from "components/export-button/ExportButton";
import RefreshButton from "components/refresh-button/RefreshButton";
import TableHeader from "helpers/tableHeader";
import TaskInfo from "module/Task/TaskInfo/TaskInfo";
import CommonFilter from "components/filter/CommonFilter";
import CustomizeColumnButton from "module/common/Buttons/CustomizeColumnButton";
import LoadingScreenCover from "components/common/loading-screen/LoadingScreenCover";
import LoadingCommon from "components/common/loading-screen/LoadingCommon";

const TicketTable = ({ projectId, renderFrom, employeeData = {} }) => {
  let navigate = useNavigate();
  const location = useLocation();
  const Translate = useAxisproTranslate();
  const [queryParams] = useSearchParams();
  let user = useContext(AuthWizardContext);
  let loggedAsCustomer = user?.user?.customer_id ? true : false;
  const allQueryParams = Object.fromEntries([...queryParams]);
  const { itemInfoId, empId } = useParams();
  const activeTab = queryParams.get("status");
  const [showCustomerDetailsArea, setCustomerDetailsArea] = useState(false);
  const [customerData, setCustomerData] = useState({});
  const [userProfile, setUserProfile] = useState();
  const [showUserProfileArea, setShowUserProfileArea] = useState(false);
  const pageNumber = queryParams.get("page");
  const [ticketInfo, setTicketInfo] = useState({
    show: false,
    id: "",
  });
  const [ticketData, setTicketData] = useState({});
  const [dataLoading, setDataLoading] = useState(false);
  const [filterFields, setFilterFields] = useState({});
  const { store } = useContext(StoreContext);
  const {
    [`CUSTOMIZED_COLUMNS_${MODULES.TICKET?.toUpperCase()}`]:
      customizedColumnsArray,
  } = store;
  const triggerFromQuickLinkTicket = store?.triggerFromQuickLinkTicket ?? false;
  const {
    date_range,
    from_date,
    to_date,
    date_range_for,
    project_id,
    assignee,
    priority,
    type,
    title,
    origin,
    status,
    account_id,
    order_by,
    billable,
    created_by,
  } = allQueryParams;
  const [filterVisibility, setFilterVisibility] = useState(false);
  const [taskInfoId, setTaskInfoId] = useState("");
  const { dispatch: deleteContextDispatch } = useContext(DeleteContext);
  const [customColumns, setCustomColumns] = useState([]);
  const permission = {
    show: GetAcodaxPermission("TICKET", "show"),
    delete: GetAcodaxPermission("TICKET", "delete"),
    update: GetAcodaxPermission("TICKET", "update"),
    store: GetAcodaxPermission("TICKET", "store"),
    export: GetAcodaxPermission("TICKET", "export"),
    show_customer: GetAcodaxPermission("CUSTOMER", "show"),
  };
  const actionsPermission = permission?.update || permission?.delete;

  const handleDelete = (data) => {
    deleteContextDispatch({
      type: "CONFIG",
      payload: {
        target: data,
        url: "/crm/ticket/" + data,
        title: "Delete Ticket",
        message: "Are you sure you want to delete this ticket?",
        onSuccess: () => {
          const dataWithoutDeletedItem = ticketData.data.filter(
            (account) => account.id !== data
          );

          if (dataWithoutDeletedItem.length < 1 && pageNumber > 1) {
            // if data length < 1 and page number > 1 then redirect to previous page
            navigate(
              `${
                renderFrom === MODULES.PROJECT
                  ? `/project/info/${projectId}`
                  : renderFrom === MODULES.EMPLOYEE
                  ? `/employee/overview/${empId}`
                  : "/tickets/list"
              }?${createSearchParams({
                ...allQueryParams,
                page: pageNumber - 1,
              })}`
            );
          } else {
            setTicketData((previous) => ({
              ...previous,
              data: dataWithoutDeletedItem,
            }));
            // reset deleteContext
            deleteContextDispatch({
              type: "RESET",
            });
          }
        },
      },
    });
  };

  const handleVisibility = () => {
    if (location.pathname.includes("employee/overview")) {
      return true;
    }
    if (location.pathname.includes("overview")) {
      return false;
    }
    if (location.pathname.includes("convert")) {
      return false;
    }
    return true;
  };

  const getData = async (page = 1) => {
    setDataLoading(true);

    const filterParamObject = {
      page: page ? page : pageNumber,
      date_range_for: date_range_for,
      from_date: from_date,
      to_date: to_date,
      title: title,
      project_id: projectId ? projectId : project_id,
      assignee:
        Object.keys(employeeData).length > 0 ? employeeData.id : assignee,
      priority: priority,
      type: type,
      status: activeTab && activeTab !== "all" ? activeTab : "",
      origin: origin,
      account_id: account_id,
      order_by: order_by,
      billable: billable,
      created_by: created_by,
    };
    setFilterFields(filterParamObject);
    try {
      const data = await apiCall({
        url: "crm/ticket",
        params: removeEmptyFields(filterParamObject),
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      });
      setTicketData(data);
      setDataLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (handleVisibility()) {
      getData(pageNumber);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageNumber,
    activeTab,
    date_range,
    date_range_for,
    title,
    project_id,
    priority,
    assignee,
    type,
    origin,
    status,
    account_id,
    triggerFromQuickLinkTicket,
    order_by,
    billable,
    created_by,
  ]);

  useEffect(() => {
    return () => {
      setTicketInfo({
        show: false,
        id: "",
      });
    };
  }, [location.pathname]);

  const handleCustomerDetailsArea = (name, id) => {
    setCustomerData({ label: name, value: id });
    setCustomerDetailsArea(!showCustomerDetailsArea);
  };

  const handleUserProfileArea = (id) => {
    if (id) {
      setUserProfile(id);
      setShowUserProfileArea(true);
    } else {
      setUserProfile();
      setShowUserProfileArea(false);
    }
  };

  const handleInfoPage = (data) => {
    if (permission?.show) {
      setTicketInfo({
        show: true,
        id: data?.id,
      });
    }
  };

  const handleTaskInfo = (id) => {
    if (id) {
      setTaskInfoId(id);
    } else {
      setTaskInfoId("");
    }
  };

  const getCustomizedColumnsData = () => {
    if (
      !Array.isArray(customizedColumnsArray) ||
      customizedColumnsArray.length === 0
    ) {
      return columns;
    }

    const customizedColumnAccessors = customizedColumnsArray.map((item) =>
      typeof item === "object" && item !== null ? item.accessor : item
    );

    return columns.filter((column) =>
      customizedColumnAccessors.includes(column.accessor)
    );
  };

  useEffect(() => {
    if (!dataLoading) {
      setCustomColumns(getCustomizedColumnsData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customizedColumnsArray, dataLoading]);

  const columns = [
    {
      accessor: "reference",
      Header: Translate("REFERENCE"),
      isMandatory: true,
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text text-wrap",
      },
      retrieveData: handleInfoPage,
      Cell: (rowData) => {
        const { reference } = rowData.row.original;
        return (
          <>
            {reference ? (
              <Badge className="fs--2" bg="danger">
                {reference}
              </Badge>
            ) : (
              <>-</>
            )}
          </>
        );
      },
    },
    {
      accessor: "title",
      Header: Translate("TICKET TITLE"),
      isMandatory: true,
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text",
        style: { minWidth: "20rem" },
      },
      retrieveData: handleInfoPage,
      Cell: (rowData) => {
        const { title, description } = rowData.row.original;
        return (
          <>
            <span
              className="d-block left-align-custom-span fw-bold text-start"
              title={title}
            >
              {stringLimiter(title, 40)}
            </span>
            <span
              className="d-block left-align-custom-span text-start"
              title={description}
            >
              {description.length > 25
                ? description.substring(0, 25) + "..."
                : description}
            </span>
          </>
        );
      },
    },
    ...(renderFrom !== MODULES.PROJECT
      ? [
          {
            accessor: "project",
            Header: Translate("PROJECT"),
            headerProps: { className: "py-3 custom-header-text" },
            cellProps: {
              className: "py-3 custom-table-body-text text-wrap",
              style: { minWidth: "17rem" },
            },
            customRouteTo: {
              route: `/project/info/routeId?tab=overview`,
              key: "project_id",
              islocallyStored: true,
            },
            Cell: (rowData) => {
              const { project } = rowData.row.original;
              return (
                <>
                  <h5 className="mb-0 fs--1 link-like-text">
                    {stringLimiter(project, 30)}
                  </h5>
                </>
              );
            },
          },
          {
            accessor: "customer_id",
            Header: Translate("CLIENT"),
            headerProps: { className: "py-3 custom-header-text" },
            cellProps: {
              className: "py-3 custom-table-body-text text-wrap",
              style: { minWidth: "15rem" },
            },
            Cell: (rowData) => {
              const { customer_image, customer_name, customer_id } =
                rowData.row.original;
              return (
                <>
                  <div
                    className={`d-flex align-items-center ${
                      permission?.show_customer
                        ? "custom-clickable-link-crm "
                        : ""
                    }`}
                    onClick={() => {
                      permission?.show_customer &&
                        handleCustomerDetailsArea(
                          customer_name ?? "",
                          customer_id ?? ""
                        );
                    }}
                  >
                    <Image
                      className="custom-image-crm"
                      src={customer_image ? customer_image : ProfileImage}
                      roundedCircle
                      alt="?"
                      loading="lazy"
                      height={"25vh"}
                      width={"25vh"}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = ProfileImage;
                      }}
                    />

                    <p className="ps-1 m-0" title={customer_name}>
                      {customer_name ? stringLimiter(customer_name, 20) : "-"}
                    </p>
                  </div>
                </>
              );
            },
          },
        ]
      : []),
    {
      accessor: "ticket_type",
      Header: Translate("TYPE"),
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text",
        style: { minWidth: "13rem" },
      },
      retrieveData: handleInfoPage,
      Cell: (rowData) => {
        const { ticket_type } = rowData.row.original;
        return (
          <h5 className="mb-0 fs--1">{ticket_type ? ticket_type : "-"}</h5>
        );
      },
    },
    ...(loggedAsCustomer
      ? []
      : [
          {
            accessor: "ticket_origin",
            Header: Translate("ORIGIN"),
            headerProps: { className: "py-3 custom-header-text" },
            cellProps: {
              className: "py-3 custom-table-body-text",
            },
            retrieveData: handleInfoPage,
            Cell: (rowData) => {
              const { ticket_origin } = rowData.row.original;
              return (
                <h5 className="mb-0 fs--1 text-capitalize">
                  {ticket_origin ? ticket_origin : "-"}
                </h5>
              );
            },
          },
          {
            accessor: "priority",
            Header: Translate("PRIORITY"),
            headerProps: { className: "py-3 custom-header-text" },
            cellProps: {
              className: "py-3 custom-table-body-text",
            },
            retrieveData: handleInfoPage,
            Cell: (rowData) => {
              const { priority } = rowData.row.original;
              return (
                <>
                  {priority ? (
                    <Badge
                      bg={"none"}
                      style={{
                        backgroundColor: getPriorityWithProps(priority),
                        width: "60px",
                      }}
                      className="fs--2"
                    >
                      {priority}
                    </Badge>
                  ) : (
                    "-"
                  )}
                </>
              );
            },
          },
          {
            accessor: "assigned_to",
            Header: "ASSIGNEE",
            headerProps: { className: "py-3 custom-header-text" },
            cellProps: {
              className: "py-3 custom-table-body-text",
              style: { minWidth: "12rem" },
            },
            Cell: (rowData) => {
              const { assigned_to, assignee_image, assignee } =
                rowData.row.original;
              return (
                <>
                  {assigned_to ? (
                    <div
                      className="d-flex"
                      onClick={() => {
                        handleUserProfileArea(assigned_to);
                      }}
                    >
                      <span className="d-inline">
                        <Image
                          className="custom-image-crm"
                          src={assignee_image ? assignee_image : ProfileImage}
                          roundedCircle
                          alt="?"
                          loading="lazy"
                          height={"25vh"}
                          width={"25vh"}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = ProfileImage;
                          }}
                        />
                      </span>
                      <span className="d-inline p-0 ms-2 d-flex align-items-center">
                        <p className="m-0">{stringLimiter(assignee, 18)}</p>
                      </span>
                    </div>
                  ) : (
                    <div className="ms-2"> - </div>
                  )}
                </>
              );
            },
          },
        ]),
    ...(loggedAsCustomer
      ? []
      : [
          {
            accessor: "created_by",
            Header: Translate("CREATED BY"),
            headerProps: { className: "py-3 custom-header-text" },
            cellProps: {
              className: "py-3 custom-table-body-text",
              style: { minWidth: "12rem" },
            },
            Cell: (rowData) => {
              const { created_user_full_name, created_by } =
                rowData.row.original;
              return (
                <>
                  {created_user_full_name ? (
                    <div
                      className="d-flex"
                      onClick={() => {
                        handleUserProfileArea(created_by);
                      }}
                    >
                      <span className="d-inline">
                        <Image
                          className="custom-image-crm"
                          src={ProfileImage}
                          roundedCircle
                          alt="?"
                          loading="lazy"
                          height={"25vh"}
                          width={"25vh"}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = ProfileImage;
                          }}
                        />
                      </span>
                      <span className="d-inline p-0 ms-2 d-flex align-items-center">
                        <p className="m-0">
                          {stringLimiter(created_user_full_name, 18)}
                        </p>
                      </span>
                    </div>
                  ) : (
                    <div className="ms-2"> - </div>
                  )}
                </>
              );
            },
          },
        ]),
    {
      accessor: "ticket_date_formated",
      Header: loggedAsCustomer
        ? Translate("CREATED DATE")
        : Translate("START DATE"),
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text",
      },
      retrieveData: handleInfoPage,
      Cell: (rowData) => {
        const { ticket_date_formated } = rowData.row.original;
        return (
          <h5 className="mb-0 fs--1">
            {ticket_date_formated ? ticket_date_formated : "-"}
          </h5>
        );
      },
    },
    ...(loggedAsCustomer
      ? []
      : [
          {
            accessor: "end_date_formated",
            Header: Translate("END DATE"),
            headerProps: { className: "py-3 custom-header-text" },
            cellProps: {
              className: "py-3 custom-table-body-text",
            },
            retrieveData: handleInfoPage,
            Cell: (rowData) => {
              const { end_date_formated } = rowData.row.original;
              return (
                <h5 className="mb-0 fs--1">
                  {end_date_formated ? end_date_formated : "-"}
                </h5>
              );
            },
          },
        ]),
    {
      accessor: "task_no",
      Header: Translate("TASK REFERENCE"),
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text text-wrap",
      },
      Cell: (rowData) => {
        const { task } = rowData.row.original;
        return (
          <div
            className="d-flex flex-column shadow-sm"
            title={`${task?.title}`}
            onClick={() => handleTaskInfo(task?.id)}
          >
            {task?.task_no && (
              <>
                <div className="badge badge-soft-warning fs--2">
                  {task?.task_no}
                </div>
                <div className="badge badge-soft-light fs--2 text-capitalize text-dark">
                  <FaCircle
                    className="me-1"
                    color={getTaskStatusWithProps(task?.status)}
                    size={10}
                  />
                  {task?.status?.replaceAll("_", " ")}
                </div>
              </>
            )}
          </div>
        );
      },
    },
    {
      accessor: "is_converted",
      Header: Translate("CONVERTED"),
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text text-wrap",
      },
      retrieveData: handleInfoPage,
      Cell: (rowData) => {
        const { task } = rowData.row.original;
        return (
          <>
            <div className="ms-2">
              {task ? (
                <IoMdCheckmarkCircleOutline
                  size={22}
                  color="#4ccb4c"
                  title="Converted To Task"
                />
              ) : (
                <FaRegCircleXmark
                  size={20}
                  color="#eb7676"
                  title="Not Converted To Task"
                />
              )}
            </div>
          </>
        );
      },
    },
    ...(loggedAsCustomer
      ? []
      : [
          {
            accessor: "billable",
            Header: Translate("BILLABLE"),
            headerProps: { className: "py-3 custom-header-text" },
            cellProps: {
              className: "py-3 custom-table-body-text",
            },
            retrieveData: handleInfoPage,
            Cell: (rowData) => {
              const { billable } = rowData.row.original;
              return (
                <div className="ms-2">
                  {billable ? (
                    <FaMoneyBillWave
                      size={21}
                      color="#14bd78"
                      title="Billable Ticket"
                    />
                  ) : (
                    ""
                  )}
                </div>
              );
            },
          },
        ]),
    {
      accessor: "status",
      Header: Translate("STATUS"),
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text",
      },
      retrieveData: handleInfoPage,
      Cell: (rowData) => {
        const { status } = rowData.row.original;
        return (
          <div>
            <Badge
              bg={"none"}
              style={{
                backgroundColor: getTicketStatusWithProps(status),
                width: "60px",
                fontSize: "11px",
              }}
              className="text-capitalize"
            >
              {status === "awaiting_business_input"
                ? "Await"
                : status === "with_client_review"
                ? "Review"
                : status}
            </Badge>
          </div>
        );
      },
    },
    ...(actionsPermission
      ? [
          {
            accessor: "none",
            Header: "",
            headerProps: {
              className: `py-3 custom-table-head-th-action ${
                actionsPermission ? "" : "d-none"
              }`,
            },
            disableSortBy: true,
            cellProps: {
              className: `custom-table-body-td-action py-3 ${
                actionsPermission ? "" : "d-none"
              }`,
            },
            Cell: (rowData) => {
              const { id, task } = rowData.row.original;
              return (
                <>
                  <div className="d-flex gap-2 align-items-center justify-content-center">
                    {!loggedAsCustomer && !task && (
                      <Button
                        variant="transparent"
                        size="sm"
                        as={Link}
                        to={`/tickets/list/convert/${id}`}
                        title="Convert"
                        className="custom-button-action p-0"
                      >
                        <BiShuffle
                          color="#404A57"
                          className="custom-button-convert-icon m-1"
                          size={17}
                        />
                      </Button>
                    )}
                    {permission?.update && (
                      <EditButton
                        route={
                          renderFrom === MODULES.PROJECT
                            ? `/project/info/${itemInfoId}/ticket/edit/${id}?${createSearchParams(
                                allQueryParams
                              )}`
                            : renderFrom === MODULES.EMPLOYEE
                            ? `/employee/overview/${empId}/ticket/edit/${id}?${createSearchParams(
                                {
                                  ...allQueryParams,
                                }
                              )}`
                            : `edit/${id}?${createSearchParams({
                                ...allQueryParams,
                              })}`
                        }
                      />
                    )}
                    {permission?.delete && (
                      <DeleteButton onClick={() => handleDelete(id)} />
                    )}
                  </div>
                </>
              );
            },
          },
        ]
      : []),
  ];

  const handleTaskClose = () => {
    setTaskInfoId("");
  };

  return (
    <>
      <Outlet context={[getData]} />
      {handleVisibility() && (
        <Card
          className={`${
            renderFrom === MODULES.PROJECT || renderFrom === MODULES.EMPLOYEE
              ? ``
              : `mb-3`
          } ${
            renderFrom === MODULES.EMPLOYEE ? "shadow-none" : ``
          } flex-grow-1`}
        >
          <Card.Header className="d-flex flex-row justify-content-between align-items-center flex-wrap gap-2">
            <div className="d-flex align-items-center">
              <BiSupport
                size={!renderFrom ? 20 : 18}
                className="me-1 text-dark"
              />
              <TableHeader title={Translate("TICKET LIST")} table />
            </div>
            <div className="d-flex flex-wrap gap-1">
              <CustomizeColumnButton
                columns={columns}
                fromModule={MODULES.TICKET}
              />
              <RefreshButton
                loading={dataLoading}
                style={{ fontSize: "11px" }}
                className={"d-flex align-items-center justify-content-center"}
                variant={"info"}
                onClick={() => getData()}
              />
              {permission?.export && renderFrom !== MODULES.EMPLOYEE && (
                <ExportButton
                  url="crm/ticket-export"
                  initialMessage={"Ticket export started"}
                  successMessage={"Ticket export completed"}
                  file_type={"pdf"}
                  variant={"danger"}
                  filters={filterFields}
                />
              )}
              {permission?.store && (
                <AddButton
                  className="shadow"
                  route={
                    renderFrom === MODULES.PROJECT
                      ? `/project/info/${projectId}/ticket/add?${createSearchParams(
                          allQueryParams
                        )}`
                      : renderFrom === MODULES.EMPLOYEE
                      ? `/employee/overview/${empId}/ticket/add?${createSearchParams(
                          {
                            ...allQueryParams,
                            assigneeId: employeeData?.id,
                            assigneeName: employeeData?.name,
                          }
                        )}`
                      : `add?${createSearchParams({
                          ...allQueryParams,
                        })}`
                  }
                  variant="success"
                  title="Add New Ticket"
                />
              )}
              <FilterCommonButton
                filterVisibility={filterVisibility}
                setFilterVisibility={setFilterVisibility}
              />
            </div>
          </Card.Header>
          {filterVisibility && (
            <CommonFilter
              filterKeys={commonTicketFilterFormKeys}
              module={MODULES.TICKET}
              additionalResetParams={{
                tab:
                  renderFrom === MODULES.PROJECT ||
                  renderFrom === MODULES.EMPLOYEE
                    ? "tickets"
                    : "",
                status: activeTab ? activeTab : "all",
              }}
            />
          )}
          <SimpleBar
            className={
              !filterVisibility
                ? "simplebar-style-with-pagination simplebar-custom"
                : "simplebar-style-with-pagination-and-filter-on-with-three-row-for-ticket simplebar-custom"
            }
          >
            <Card.Body className="p-0">
              {!dataLoading && ticketData?.data ? (
                <>
                  <AdvanceTableWrapper
                    columns={customColumns}
                    data={ticketData?.data}
                    sortable
                  >
                    <AdvanceTable
                      table
                      headerClassName="bg-200 text-900 text-nowrap align-middle position-sticky top-0 z-1"
                      rowClassName="align-middle white-space-nowrap custom-table-row"
                      tablebodyClassName="custom-table-body position-sticky"
                      tableprops={{
                        size: "sm",
                        striped: true,
                        className: "fs--1 mb-0 overflow-hidden",
                      }}
                    />
                  </AdvanceTableWrapper>
                  <CustomerDetails
                    show={showCustomerDetailsArea}
                    onHide={handleCustomerDetailsArea}
                    value={customerData}
                    showCustomerDetailsArea={showCustomerDetailsArea}
                  />
                </>
              ) : (
                <LoadingScreenCover
                  style={{
                    minHeight: filterVisibility ? "61dvh" : "76.9dvh",
                  }}
                  className={!filterVisibility && `border-top`}
                >
                  {dataLoading && (
                    <LoadingCommon loadingText={`Looking for Tickets...`} />
                  )}
                </LoadingScreenCover>
              )}
              <UserProfile
                show={showUserProfileArea}
                handleClose={() => setShowUserProfileArea(false)}
                id={userProfile}
              />
              <TicketInfo
                show={ticketInfo?.show}
                id={ticketInfo?.id}
                onHide={() => {
                  setTicketInfo({
                    show: false,
                    id: "",
                  });
                }}
                getDataRefresh={getData}
              />
              {taskInfoId && (
                <TaskInfo
                  taskInfoId={taskInfoId}
                  showTaskInfo={taskInfoId ? true : false}
                  handleCloseTaskInfo={handleTaskClose}
                  getData={getData}
                />
              )}
            </Card.Body>
          </SimpleBar>
          {!dataLoading && (
            <Card.Footer className="border-top p-2 footer">
              {ticketData && ticketData.links && (
                <AppPagination
                  data={ticketData}
                  url={`${
                    renderFrom === MODULES.EMPLOYEE
                      ? `/employee/overview/${empId}`
                      : `/tickets/list`
                  }`}
                  search={allQueryParams}
                />
              )}
            </Card.Footer>
          )}
        </Card>
      )}
    </>
  );
};

export default TicketTable;
