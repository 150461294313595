import { React, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import Select from "react-select";

const MutiSelectTaskLabel = ({
  handleFieldChange,
  name,
  error,
  value,
  style,
  placeholder,
  excludeLabels,
  disabled,
}) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadOptions = (inputValue) => {
    setLoading(true);
    return new Promise(async (resolve) => {
      let response = await axios.get("crm/label-autocomplete", {
        params: {
          subject_type: "task",
        },
      });
      let data = response && response.data ? response.data.data : [];
      let filteredLabels = [];
      if (typeof excludeLabels === "object") {
        let tempExcludeLabels = new Set(excludeLabels.map(({ id }) => id));
        filteredLabels = data.filter(({ id }) => !tempExcludeLabels.has(id));
      } else {
        filteredLabels = data;
      }
      const loadedOptions = filteredLabels.map((item) => ({
        label: item.label,
        value: item.id,
      }));

      resolve(loadedOptions);
      setLoading(false);
    });
  };

  const handleFocus = async ({ inputValue }) => {
    setOptions([]);
    const OptionsData = await loadOptions(inputValue);
    setOptions(OptionsData);
  };

  return (
    <Select
      isMulti
      cacheOptions
      isLoading={loading}
      options={options}
      loadOptions={loadOptions}
      onFocus={handleFocus}
      defaultOptions
      isClearable
      isDisabled={disabled}
      name={name ? name : "Select"}
      value={value}
      placeholder={placeholder ?? ""}
      onChange={handleFieldChange}
      isSearchable={true}
      className={`custom-select text-capitalize ${
        error && "form-control ps-0 py-0 is-invalid"
      }`}
      styles={{
        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? "#696c96" : "inherit",
          "&:hover": {
            backgroundColor: state.isSelected ? "#192E49" : "#e1e2ea",
          },
        }),
        control: (provided) => ({
          ...provided,
          height: "fit-content",
          minHeight: 32,
          ...style,
        }),
      }}
    ></Select>
  );
};

MutiSelectTaskLabel.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.any,
  keyName: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
};

export default MutiSelectTaskLabel;
