import React from "react";
import { Card } from "react-bootstrap";
import CountUp from "react-countup";
import PropTypes from "prop-types";

import { AiOutlineClockCircle } from "react-icons/ai";

import Background from "components/common/Background";

import bg1 from "assets/img/icons/spot-illustrations/corner-1.png";
import DashboardTileLoader from "module/common/Loading/DashboardTileLoader";

function TotalWorkHours({ hour, loading = false }) {
  const [hoursStr, minutesStr, secondsStr] = hour.split(":");
  const hours = parseInt(hoursStr, 10);
  const minutes = parseInt(minutesStr, 10);
  const seconds = parseInt(secondsStr, 10);
  const totalHours = hours + minutes / 60 + seconds / 3600;

  return (
    <Card className="card-main rounded-0 h-100">
      <Background image={bg1} className="bg-card rounded-0" />
      <Card.Body className="">
        {!loading ? (
          <div className="d-flex align-items-center">
            <AiOutlineClockCircle size={40} className="text-muted" />
            <div className="d-flex flex-column align-items-end w-100 justify-content-center">
              <span className="fs-4 text-dark text-center mt-3">
                <CountUp
                  start={0}
                  end={totalHours}
                  suffix="hr"
                  duration={2.75}
                  decimals={2}
                  separator=","
                  decimal="."
                />
              </span>
              <span className="mt-2">Total hours worked</span>
            </div>
          </div>
        ) : (
          <div style={{ height: "46px" }} className="mb-4">
            <DashboardTileLoader column={1} />
          </div>
        )}
      </Card.Body>
    </Card>
  );
}

TotalWorkHours.propTypes = {
  price: PropTypes.any,
};

export default TotalWorkHours;
