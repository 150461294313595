import ReactECharts from "echarts-for-react";
import * as echarts from "echarts/core";
import { GaugeChart } from "echarts/charts";
import { getColor } from "helpers/utils";
echarts.use([GaugeChart]);
const GaugeEChart = ({
  height,
  width,
  value,
  borderRadius,
  backgroundColor,
}) => {
  const getOptions = (data) => ({
    series: [
      {
        type: "gauge",
        startAngle: 90,
        endAngle: -270,
        radius: "90%",
        pointer: {
          show: false,
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
          itemStyle: {
            color: getColor("success"),
          },
        },
        axisLine: {
          lineStyle: {
            width: 8,
            color: [[1, getColor("200")]],
          },
        },
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        data: [
          {
            value: data,
            detail: {
              offsetCenter: ["7%", "4%"],
            },
          },
        ],
        detail: {
          width: 50,
          height: 14,
          fontSize: 28,
          fontWeight: 500,
          fontFamily: "poppins",
          color: getColor("500"),
          formatter: "{value}%",
        },
      },
    ],
  });

  return (
    <>
      <ReactECharts
        echarts={echarts}
        option={getOptions(value)}
        style={{
          height: height ? height : "10.3rem",
          width: width ? width : "10.3rem",
          borderRadius: borderRadius ? borderRadius : "",
          backgroundColor: backgroundColor ? backgroundColor : "#fff",
        }}
      />
    </>
  );
};

export default GaugeEChart;
