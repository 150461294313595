export const MODULES = {
  EMPLOYEE: "employee",
  LEAD: "lead",
  CAMPAIGN: "campaign",
  OPPORTUNITY: "opportunity",
  TODO: "todo",
  TASKS: "tasks",
  ACCOUNT: "account",
  PROJECT: "project",
  SPRINT: "sprints",
  TICKET: "tickets",
  ANNOUNCEMENT: "announcement",
  USER: "users",
  INBOX: "inbox",
  PRODUCTS: "products",
  PROJECTREPORT: "project-report",
  INVITATION: "invitation",
  ROLE: "roles",
  LABELS: "labels",
  LEADSOURCE: "leads-source",
  LEADSTATUS: "leads-status",
  DEPARTMENT: "department",
  DESIGNATION: "designation",
  TICKETTYPES: "ticket-types",
  EMAILCONFIG: "email-config",
  EMPLOYEETASKSUMMARYREPORT: "employee-task-summary-report",
  TASKDETAILEDREPORT: "task-detailed-report",
  RUNNINGORCOMPLETEDTASKREPORT: "running-or-completed-task-report",
  TIMETRACKERTASKREPORT: "time-tracker-task-report",
  SPRINTDETAILEDREPORT: "sprint-detailed-report",
  EMPLOYEETASKREPORT: "employee-task-report",
  EMPLOYEELEADSUMMARYREPORT: "employee-lead-summary-report",
  LEADSDETAILEDREPORT: "leads-detailed-report",
  LEADCONVERSIONREPORT: "lead-conversion-report",
  LEADSOURCESUMMARYREPORT: "lead-source-summary-report",
  OPPORTUNITYDETAILEDREPORT: "opportunity-detailed-report",
  EMPLOYEEWORKTIMEREPORT: "employee-work-time-report",
  PROJECTWORKTIMEREPORT: "project-work-time-report",
  TEAMS: "teams",
  DOCUMENTTYPE: "document-type",
};
