import { React, useEffect, useState } from "react";
import axios from "axios";
import { Alert, Card, Container, Table } from "react-bootstrap";
import qs from "qs";
import PropTypes from "prop-types";

import { showToast } from "module/common/Toast/toast";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import ScrollToLoadMore from "components/scroll-to-load-more/ScrollToLoadMore";
import LoadingCommon from "components/common/loading-screen/LoadingCommon";

function TransactionTab({ customer_id }) {
  const Translate = useAxisproTranslate();
  const [updatedData, setUpdatedData] = useState([]);
  const [creditAmount, setCreditAmount] = useState([]);
  const [debitAmount, setDebitAmount] = useState([]);
  const [totalAmount, setTotalAmount] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState([]);
  const [nextLink, setNextLink] = useState(null);

  const getData = () => {
    if (customer_id) {
      setLoading(true);
      axios
        .get("/sales/customer-balance-statements", {
          params: {
            customer_id: customer_id,
          },
          paramsSerializer: (params) => {
            return qs.stringify(params);
          },
        })
        .then((response) => {
          if (response.data.success) {
            setData(response.data.data.data);
            setMeta(response.data.data);
            setNextLink(response.data.data.links.next);
            setLoading(false);
          }
        })
        .catch((error) => {
          if (error) {
            showToast("Something went wrong please contact admin", "error");
            setLoading(false);
          }
        });
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer_id]);

  const loadNextPage = async () => {
    if (nextLink && nextLink !== "") {
      await axios
        .get(nextLink)
        .then((res) => {
          setData([...data, ...res.data.data.data]);
          if (
            res?.data?.data?.links?.next !== null &&
            typeof res.data.data.links.next !== "undefined"
          ) {
            setNextLink(res.data.data.links.next);
          } else {
            setNextLink(null);
          }
        })
        .catch((error) => {
          if (error) {
            showToast("Something went wrong please contact admin", "error");
          }
        });
    }
  };

  useEffect(() => {
    if (data && data && data.length > 0) {
      let currentSum = 0;
      const newArray = data.map((item) => {
        currentSum += item.debit_amount;
        currentSum -= item.credit_amount;
        setTotalAmount(currentSum);
        return { ...item, balanceAmount: currentSum };
      });

      setUpdatedData(newArray);
      let totalCreditAmount = newArray.map((item) =>
        parseFloat(item.credit_amount)
      );
      let totalDebitAmount = newArray.map((item) =>
        parseFloat(item.debit_amount)
      );
      if (totalCreditAmount.length > 0) {
        let totalCAmount = totalCreditAmount.reduce((a, b) => a + b);
        setCreditAmount(totalCAmount);
      }
      if (totalDebitAmount.length > 0) {
        let totalDAmount = totalDebitAmount.reduce((a, b) => a + b);
        setDebitAmount(totalDAmount);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Card style={{ height: "79vh", overflow: "hidden", overflowY: "scroll" }}>
      <Container fluid className="p-0 reports-container-wrapper">
        {!loading ? (
          <Table striped hover responsive>
            <thead
              className="text-dark"
              style={{ backgroundColor: "rgb(237,243,248)" }}
            >
              <tr>
                <th className="ledger-report-table-heading">#</th>
                <th className="ledger-report-table-heading">
                  {Translate("DATE")}
                </th>
                <th className="ledger-report-table-heading">
                  {Translate("REFERENCE")}
                </th>
                <th className="ledger-report-table-heading">
                  {Translate("TRANSACTION TYPE")}
                </th>
                <th className="ledger-report-table-heading">
                  {Translate("DEBIT")}
                </th>
                <th className="ledger-report-table-heading">
                  {Translate("CREDIT")}
                </th>
                <th className="ledger-report-table-heading">
                  {Translate("BALANCE")}
                </th>
              </tr>
            </thead>
            {data && data.length > 0 ? (
              <>
                {updatedData.map((item, index) => (
                  <tbody key={index}>
                    <tr className="report-column-custom-style">
                      <td>{index + meta.meta.from}</td>
                      <td>
                        {item?.trans_date_formatted
                          ? item.trans_date_formatted
                          : "-"}
                      </td>
                      <td>{item.reference}</td>
                      <td>
                        <span className="report-column-content-custom-style">
                          {item.trans_type_name}
                        </span>
                      </td>
                      <td>
                        <span className="report-column-content-custom-style">
                          {item.debit_amount === 0
                            ? "-"
                            : parseFloat(item.debit_amount).toLocaleString(
                                navigator.language,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                        </span>
                      </td>
                      <td>
                        <span className="report-column-content-custom-style">
                          {item.credit_amount === 0
                            ? "-"
                            : parseFloat(item.credit_amount).toLocaleString(
                                navigator.language,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                        </span>
                      </td>
                      <td>
                        <span className="report-column-content-custom-style">
                          {parseFloat(item.balanceAmount).toLocaleString(
                            navigator.language,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                ))}
                <tr className="total-wrapper report-column-custom-style">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="text-field">
                    <span className="report-column-content-custom-style">
                      {Translate("Total")}
                    </span>
                  </td>
                  <td>
                    <span className="report-column-content-custom-style">
                      {Math.abs(debitAmount).toLocaleString(
                        navigator.language,
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}
                    </span>
                  </td>
                  <td>
                    <span className="report-column-content-custom-style">
                      {Math.abs(creditAmount).toLocaleString(
                        navigator.language,
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}
                    </span>
                  </td>
                  <td>
                    <span className="report-column-content-custom-style">
                      {totalAmount.toLocaleString(navigator.language, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </span>
                  </td>
                </tr>
                {nextLink && nextLink !== null && data && data.length > 0 && (
                  <ScrollToLoadMore
                    row={3}
                    column={7}
                    nextLink={nextLink}
                    loadNextPage={loadNextPage}
                  />
                )}
              </>
            ) : (
              <tr className="alert-styles">
                <td colSpan={12}>
                  <Alert variant="warning">
                    {Translate("Currently there is no report generated.")}
                  </Alert>
                </td>
              </tr>
            )}
          </Table>
        ) : (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "60vh", width: "85vw" }}
          >
            <LoadingCommon loadingText="Looking for Transactions" />
          </div>
        )}
      </Container>
    </Card>
  );
}

TransactionTab.propTypes = {
  customer_id: PropTypes.any,
};

export default TransactionTab;
