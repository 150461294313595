import React from "react";
import ReactEcharts from "echarts-for-react";

const PieChart = ({ options, height, width }) => {
  return (
    <div
      className="pie-chart-container"
      style={{ height: height, width: width }}
    >
      <ReactEcharts option={options} style={{ height: "100%" }} />
    </div>
  );
};

export default PieChart;
