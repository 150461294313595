import { IoHourglassOutline, IoTicketOutline } from "react-icons/io5";
import {
  MdBackHand,
  MdLockOpen,
  MdNextPlan,
  MdOutlineDoneOutline,
} from "react-icons/md";
import { TbEyeSearch } from "react-icons/tb";
const getTicketStatusWithProps = (status, type, iconSize = 25) => {
  const statusWithColor = [
    {
      key: "open",
      value: "#3da2d1",
      icon: <MdLockOpen color={"#3da2d1"} size={iconSize} className="mb-1" />,
    },
    {
      key: "closed",
      value: "#139101",
      icon: (
        <MdOutlineDoneOutline
          color={"#139101"}
          size={iconSize}
          className="mb-1"
        />
      ),
    },
    {
      key: "pending",
      value: "#d1ab2a",
      icon: (
        <MdBackHand size={iconSize - 3} color={"#d1ab2a"} className="mb-1" />
      ),
    },
    {
      key: "awaiting_business_input",
      value: "#cf9427",
      icon: (
        <IoHourglassOutline
          size={iconSize}
          color={"#cf9427"}
          className="mb-1"
        />
      ),
    },
    {
      key: "with_client_review",
      value: "#cf7229",
      icon: <TbEyeSearch size={iconSize} color={"#cf7229"} className="mb-1" />,
    },
    {
      key: "deferred",
      value: "#bd31ba",
      icon: <MdNextPlan size={iconSize} color={"#bd31ba"} className="mb-1" />,
    },
    {
      key: "new",
      value: "#6758cc",
      icon: (
        <IoTicketOutline size={iconSize} color={"#6758cc"} className="mb-1" />
      ),
    },
  ];

  if (status) {
    const foundObject = statusWithColor.find((obj) => obj.key === status);
    if (type === "icon") {
      return foundObject?.icon;
    } else {
      return status ? foundObject?.value ?? "grey" : foundObject?.icon;
    }
  } else {
    if (!type) {
      return statusWithColor;
    }
  }
};

export default getTicketStatusWithProps;
