import React, { useContext, useState } from "react";
import { Dropdown, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import { AuthWizardContext } from "context/Context";
import SimpleBar from "simplebar-react";
import UserProfile from "module/common/Offcanvas/UserProfile/UserProfile";
import defaultImage from "../../../assets/img/Avathar/ProfileImage.jpg";

function CollaboratorsListDropDown({ collaborators }) {
  const { user } = useContext(AuthWizardContext);
  const [userProfile, setUserProfile] = useState();
  const [showUserProfileArea, setShowUserProfileArea] = useState(false);
  const createCollaboratorsString = (collaborators, user) => {
    const userIndex = collaborators.findIndex(
      (collaborator) => collaborator.id === user.id
    );

    if (userIndex > -1) {
      const [userCollaborator] = collaborators.splice(userIndex, 1);
      collaborators.unshift(userCollaborator);
    }

    return collaborators.reduce((col, collaborator, index) => {
      return col + (index !== 0 ? ", " : "") + (collaborator?.full_name || "");
    }, "");
  };

  const handleUserProfileArea = (id) => {
    if (id) {
      setUserProfile(id);
      setShowUserProfileArea(true);
    } else {
      setUserProfile();
      setShowUserProfileArea(false);
    }
  };

  return (
    <>
      <Dropdown navbar={true} autoClose="outside">
        <Dropdown.Toggle
          bsPrefix="toggle"
          variant="transparent"
          className="custom-transperent-common-button p-0 shadow-none"
        >
          <OverlayTrigger
            overlay={
              <Tooltip
                style={{ position: "fixed" }}
                id="overlay-trigger-example"
              >
                Tap to show more
              </Tooltip>
            }
          >
            <div
              style={{ textAlign: "left", color: "#344050" }}
              className="fs--1"
            >
              {createCollaboratorsString(collaborators, user)?.length > 15
                ? createCollaboratorsString(collaborators, user)?.substring(
                    0,
                    15
                  ) + "..."
                : createCollaboratorsString(collaborators, user)}
            </div>
          </OverlayTrigger>
        </Dropdown.Toggle>
        <Dropdown.Menu
          style={{ cursor: "default" }}
          className="dropdown-menu-card dropdown-menu-end fs--1"
        >
          <SimpleBar
            style={{
              maxHeight: "250px",
            }}
            className="custom-simplebar-content d-flex flex-column gap-2"
          >
            {collaborators?.map((collaborator, index) => {
              return (
                <Dropdown.Item
                  key={index}
                  style={{ fontSize: "13px", cursor: "pointer" }}
                  className="text-dark d-flex align-items-center gap-1 p-1"
                  onClick={() => {
                    handleUserProfileArea(collaborator?.id);
                  }}
                >
                  <Image
                    src={collaborator?.profile_image ?? defaultImage}
                    roundedCircle
                    className="collaborator-dropdown-avatar"
                    alt={collaborator?.full_name}
                  />
                  <span className="me-2">{collaborator?.full_name}</span>
                </Dropdown.Item>
              );
            })}
          </SimpleBar>
        </Dropdown.Menu>
      </Dropdown>
      <UserProfile
        show={showUserProfileArea}
        handleClose={() => setShowUserProfileArea(false)}
        id={userProfile}
      />
    </>
  );
}

export default CollaboratorsListDropDown;
