import axios from "axios";
import React, { useState } from "react";
import { MultiSelect } from "react-multi-select-component";

const MutiSelectCheckBoxTaskStatus = ({
  name,
  value = [],
  handleFieldChange,
  error,
}) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const loadOptions = () => {
    return new Promise(async (resolve) => {
      setLoading(true);
      let response = await axios.get("crm/task-status");
      let data = response && response.data ? response.data.data : [];

      const loadedOptions = data?.map((item) => ({
        label: item?.replace("_", " "),
        value: item,
      }));
      setOptions(loadedOptions);
      setLoading(false);
      resolve(loadedOptions);
    });
  };

  const handleChange = (selectedOptions) => {
    const action = {
      action: "select-option",
      name: name ? name : "task_status",
    };
    handleFieldChange(selectedOptions, action);
  };

  return (
    <MultiSelect
      isLoading={loading}
      className={`custom-multi-select-checkbox text-capitalize ${
        error ? "is-invalid" : ""
      }`}
      name={name ? name : "products"}
      options={options}
      value={value}
      onMenuToggle={(e) => e && loadOptions()}
      onChange={handleChange}
      labelledBy=""
      overrideStrings={{
        allItemsAreSelected: options?.map((item, key) => {
          let label = key > 0 ? "," + item?.label : item?.label;
          return label;
        }),
      }}
    />
  );
};

export default MutiSelectCheckBoxTaskStatus;
