import { React, useState } from "react";
import { Col, Form, Row, Modal, Button } from "react-bootstrap";
import { CustomButton } from "module/common/Buttons/CommonButton";
import { showToast } from "module/common/Toast/toast";
import { BsStars } from "react-icons/bs";
import { generativeAiFormKeys } from "helpers/formKeys";
import MarkDownTextEditor from "../TextEditor/MarkDownTextEditor";
import SimpleBar from "simplebar-react";
import Showdown from "showdown";

function CommonAiGeneratorForm({
  show,
  handleClose,
  searchValue,
  handleApply,
}) {
  const [formData, setFormData] = useState({
    ...generativeAiFormKeys,
    prompt: searchValue,
  });
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState({});

  const handleFieldChange = (e, action) => {
    let newFormData;
    let value;
    if (!action) {
      newFormData = {
        ...formData,
        [e.target.name]: e.target.value,
      };
    } else {
      if (action.action === "select-option") {
        value = e.code ? e.code : e.value;
      }
      newFormData = {
        ...formData,
        [action.name]: value,
        [action.name + "_ref"]: e,
      };
    }
    setFormData(newFormData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormError({});
    setLoading(true);

    const { response, ...formDataWithoutResponse } = formData;

    const myHeaders = new Headers();
    myHeaders.append("Accept", "text/event-stream");
    myHeaders.append(
      "X-Acodax-Api-Key",
      process.env.REACT_APP_ACODAX_AI_API_KEY
    );
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify(formDataWithoutResponse);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_ACODAX_AI_API_HOST}generate`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }
      const reader = response.body?.getReader();
      if (reader) {
        let accumulatedData = "";

        const updateFormData = async (data) => {
          accumulatedData += data;
          setFormData({ ...formData, response: accumulatedData });
        };

        // Process the stream data in chunks
        for (;;) {
          const { done, value } = await reader.read();
          if (done) break;
          updateFormData(new TextDecoder().decode(value));
        }
      }
    } catch (error) {
      console.error(error);
      showToast(
        "Something went wrong, please refresh the page and try again.",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDescriptionApply = (value) => {
    const converter = new Showdown.Converter();
    const htmlData = converter.makeHtml(value);
    handleApply(htmlData);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      backdrop="static"
      className="no-border-radius-modal"
    >
      <Modal.Header closeButton>
        <BsStars size={22} className="me-2" color="#1565C0" />
        <span className="custom-module-header" style={{ fontSize: "15px" }}>
          Generate with AI
        </span>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Col>
              <Form.Group as={Col} xs={12} className="mt-2 ">
                <Form.Label className="">Prompt</Form.Label>
                <Row md={12} lg={12} xs={12}>
                  <Col md={10} lg={10} xs={10}>
                    <Form.Control
                      as="textarea"
                      name="prompt"
                      onChange={handleFieldChange}
                      value={formData.prompt}
                      isInvalid={!!formError.prompt}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className="d-flex p-0 m-0"
                    >
                      <span className="ms-auto">{formError.prompt}</span>
                    </Form.Control.Feedback>
                  </Col>
                  <Col
                    md={2}
                    lg={2}
                    xs={2}
                    className="d-flex justify-content-end"
                  >
                    <CustomButton
                      handleSubmit={handleSubmit}
                      onSave={loading}
                      labelText={formData?.response ? "Regenerate" : "Generate"}
                      loadingLabelText={"Generating..."}
                      disabled={!formData?.prompt}
                      style={{ height: "fit-content" }}
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Response</Form.Label>
                <Col>
                  <SimpleBar
                    style={{ height: "500px", border: "1px solid #b5b5b5" }}
                  >
                    <div className="p-2">
                      <MarkDownTextEditor
                        value={formData?.response}
                        handleFiledChange={handleFieldChange}
                        isPreview={true}
                      />
                    </div>
                  </SimpleBar>
                </Col>
                <Form.Control.Feedback
                  type="invalid"
                  className="d-flex p-0 m-0"
                >
                  <span className="ms-auto">{formError?.response}</span>
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Form.Group className="d-flex justify-content-end gap-1 p-3 border-top">
        <CustomButton
          handleSubmit={() => handleDescriptionApply(formData?.response)}
          disabled={!formData?.response}
          labelText={"Apply"}
          variant="info"
        />
        <Button variant={"danger"} size={"sm"} onClick={handleClose}>
          Cancel
        </Button>
      </Form.Group>
    </Modal>
  );
}

export default CommonAiGeneratorForm;
