import axios from "axios";

export const apiCall = ({
  url,
  addIitemToStore,
  storeKey,
  onSessionOut,
  params = {},
  paramsSerializer,
}) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
        ...(paramsSerializer ? { paramsSerializer: paramsSerializer } : null),
      })
      .then((response) => {
        if (response.data.success === true) {
          addIitemToStore && addIitemToStore(storeKey, response.data.data);
          resolve(response.data.data);
        }
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.status === 401 &&
          onSessionOut
        ) {
          onSessionOut();
        } else {
          console.log(error);
        }

        reject(error);
      });
  });
};
