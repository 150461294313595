import IconButton from "components/common/IconButton";
import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  Image,
  Row,
  Spinner,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import ProfileImage from "../../../assets/img/Avathar/profile.png";
import ProfileImageShe from "../../../assets/img/Avathar/she.jpg";
import { BiShuffle } from "react-icons/bi";
import Attachments from "module/common/ViewPages/Common/Attachments/Attachments";
import SelectProject from "components/form/SelectProject";
import SelectTaskStatus from "components/form/SelectTaskStatus";
import SelectUsers from "components/form/SelectUsers";
import MultiSelectUsers from "components/form/MultiSelectUsers";
import MutiSelectTaskLabel from "components/form/MutiSelectTaskLabel";
import TextEditor from "module/common/TextEditor/TextEditor";
import BackButton from "components/common/BackButton";
import { apiCall } from "helpers/apiCalls";
import ListLoading from "module/common/ListLoading";
import { tasksFormKeys } from "helpers/formKeys";
import axios from "axios";
import { showToast } from "module/common/Toast/toast";
import removeRefData from "helpers/removeRefData";
import AppTimePicker from "components/app-time-picker/AppTimePicker";
import { CustomBackButton } from "module/common/Buttons/AppButtons";
import getTicketStatusWithProps from "module/common/helpers/getTicketStatusWithProps";
import getPriorityWithProps from "module/common/helpers/getPriorityWithProps";
import getTaskStatusWithProps from "module/common/helpers/getTaskStatusWithProps";
import { GetAcodaxPermission } from "module/common/Permissions/AcodaxPermission";
import CustomerDetails from "components/customer-details/CustomerDetails";
import UserProfile from "module/common/Offcanvas/UserProfile/UserProfile";

const TicketConversionForm = () => {
  const { ticketId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [ticketData, setTicketData] = useState({});
  const [formData, setFormData] = useState(tasksFormKeys);
  const [formError, setFormError] = useState({});
  const [onSave, setOnSave] = useState(false);
  const [customerData, setCustomerData] = useState({
    showCustomerInfo: false,
    value: "",
    label: "",
  });
  const [assigneeData, setAssigneeData] = useState({
    show: false,
    id: "",
  });

  const permission = {
    showProject: GetAcodaxPermission("PROJECT", "show"),
    showCustomer: GetAcodaxPermission("CUSTOMER", "show"),
  };

  const getTicketData = async () => {
    setFormError({});
    setLoading(true);
    const data = await apiCall({
      url: "crm/ticket/" + ticketId,
    });

    setTicketData(data);
    setFormData({
      ...formData,
      project_id: data.project_id,
      project_id_ref: {
        label: data?.project,
        value: data.project_id,
      },
      title: data.title,
      description: data.description,
      start_date: data.ticket_date_time,
      end_date: data.end_date_time,
      assigned_to: data.assigned_to,
      assigned_to_ref: {
        label: data?.assignee ?? "",
        value: data?.assigned_to ?? "",
      },
      status: "to_do",
      status_ref: { label: "To Do", value: "to_do" },
    });

    setLoading(false);
  };

  const handleFieldChange = (e, action) => {
    let newFormData;
    let value;
    if (!action) {
      newFormData = {
        ...formData,
        [e.target.name]: e.target.value,
      };
    } else {
      if (
        action.action === "select-option" ||
        action.action === "remove-value"
      ) {
        if (action.name === "labels" || action.name === "collaborators") {
          const labelArray = e.map((item) => ({ id: item.value }));
          value = labelArray;
        } else {
          value = e.code ? e.code : e.value;
        }
      } else if (action.action === "clear") {
        value = [];
      } else if (action.action === "text-editor") {
        value = action.value;
      }
      newFormData = {
        ...formData,
        [action.name]: value,
        [action.name + "_ref"]: e,
      };
    }
    setFormData(newFormData);
  };

  useEffect(() => {
    if (ticketId) {
      getTicketData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (onSave) return;
    setFormError({});
    setOnSave(true);

    let formDataWithoutRefkeys = removeRefData({
      ...formData,
      ticket_id: ticketId,
    });

    axios({
      method: "post",
      url: `crm/task`,
      data: formDataWithoutRefkeys,
    })
      .then((response) => {
        if (response.data.success === true) {
          setFormData(tasksFormKeys);
          showToast(response.data.message, "success");
          navigate(`/tasks/list/overview/${response.data.data.id}`);
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
      })
      .catch((error) => {
        if (error.response.data && error.response.data.message) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
          if (
            error.response.data.message ===
            "Ticket already converted to a task."
          ) {
            showToast(error.response.data.message, "error");
          }
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
      })
      .finally(() => {
        setOnSave(false);
      });
  };

  const handleNavigation = (type) => {
    if (type === "project" && permission?.showProject) {
      navigate(`/project/info/${ticketData?.project_id}?tab=overview`);
    } else if (type === "client" && permission?.showCustomer) {
      setCustomerData({
        showCustomerInfo: !customerData?.showCustomerInfo,
        value: ticketData?.customer_id,
        label: ticketData?.customer_name,
      });
    } else if (type === "assignee") {
      setAssigneeData({
        show: true,
        id: ticketData?.assigned_to,
      });
    }
  };

  return (
    <div className="d-flex flex-column" style={{ height: "91vh" }}>
      {!loading && Object.keys(ticketData).length > 0 ? (
        <>
          <Card>
            <Card.Header className="d-flex">
              <CustomBackButton onClick={() => navigate(-1)} size="sm" />
              <IconButton variant="falcon-default" size="sm" className="ms-3">
                <span className="fw-bold">Ticket</span>
                <Badge
                  className="badge ms-2"
                  bg={"none"}
                  style={{
                    backgroundColor: getTicketStatusWithProps(
                      ticketData.status
                    ),
                  }}
                >
                  {ticketData.status === "awaiting_business_input"
                    ? "Await"
                    : ticketData.status === "with_client_review"
                    ? "Review"
                    : ticketData.status}
                </Badge>
                <BiShuffle size={20} className="ms-2 me-2" />
                <span className="fw-bold">Task</span>
                <Badge
                  bg={"none"}
                  style={{
                    backgroundColor: getTaskStatusWithProps(formData.status),
                  }}
                  className="ms-2 text-capitalize"
                >
                  {formData.status.replace("_", " ")}
                </Badge>
              </IconButton>
            </Card.Header>
          </Card>
          <Row className="g-3 mt-0 flex-fill d-flex">
            <Col md={5} className="flex-fill">
              <Card className="h-100">
                <Card.Header
                  className="border-bottom fw-semibold"
                  style={{ fontSize: "15px" }}
                >
                  Ticket Info
                </Card.Header>
                <Card.Header className="fw-bold border-bottom ps-3">
                  <Card.Title className="d-flex justify-content-between">
                    <span>Title</span>
                    <span className="ms-auto">
                      <span>
                        <Badge bg={"danger"} style={{ fontSize: "11px" }}>
                          {ticketData?.reference}
                        </Badge>
                      </span>
                      <span className="ms-1 me-1">
                        <Badge
                          bg={"none"}
                          style={{
                            backgroundColor: getPriorityWithProps(
                              ticketData.priority
                            ),
                            fontSize: "11px",
                          }}
                          className="text-capitalize"
                        >
                          {ticketData.priority}
                        </Badge>
                      </span>
                      <Badge
                        bg={"none"}
                        style={{
                          backgroundColor:
                            ticketData?.billable === 1 ? "#139101" : "#FC859D",
                          fontSize: "11px",
                        }}
                      >
                        {ticketData?.billable === 1
                          ? "Billable"
                          : "Non Billable"}
                      </Badge>
                    </span>
                  </Card.Title>
                  {ticketData.title}
                </Card.Header>
                <Card.Body className="p-0">
                  <div className="d-flex flex-column p-3">
                    <span className="fs--2">Description</span>
                    <span className="">
                      {ticketData.description ? ticketData.description : "N/A"}
                    </span>
                  </div>
                  <div className="mt-2">
                    <div className="d-flex flex-row">
                      <div className="p-3 border border-start-0 border-bottom-0 w-50">
                        <div className="d-flex flex-column">
                          <span className="fs--2">Project</span>
                          <span
                            className="fw-bold text-capitalize link-like-text"
                            onClick={() => {
                              handleNavigation("project");
                            }}
                          >
                            {ticketData?.project ?? ""}
                          </span>
                        </div>
                      </div>
                      <div className="p-3 border-top w-50">
                        <div className="d-flex flex-column">
                          <span className="fs--2">Client</span>
                          <span
                            onClick={() => {
                              handleNavigation("client");
                            }}
                          >
                            <Image
                              className="custom-image-crm"
                              src={ticketData?.customer_image ?? ProfileImage}
                              roundedCircle
                              alt="?"
                              loading="lazy"
                              height={"30vh"}
                              width={"30vh"}
                            />
                            <span className="ms-1 fw-bold text-capitalize link-like-text">
                              {ticketData?.customer_name}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div className="p-3 border-top border-end border-bottom w-50">
                        <div className="d-flex flex-column">
                          <span className="fs--2">Assignee</span>
                          <span>
                            <Image
                              className="custom-image-crm"
                              src={
                                ticketData?.assignee_image ?? ProfileImageShe
                              }
                              roundedCircle
                              alt="?"
                              loading="lazy"
                              height={"30vh"}
                              width={"30vh"}
                            />
                            <span
                              className="ms-1 fw-bold text-capitalize link-like-text"
                              onClick={() => {
                                handleNavigation("assignee");
                              }}
                            >
                              {ticketData?.assignee}
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="p-3 border-top border-bottom w-50">
                        <div className="d-flex flex-column">
                          <span className="fs--2">Ticket Type</span>
                          <span className="ms-1 fw-bold text-capitalize">
                            {ticketData?.ticket_type ?? ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="m-3 d-flex justify-content-between">
                    <div className="d-flex flex-column">
                      <span className="fs--2">Origin</span>
                      <span className="ms-1 fw-bold text-capitalize">
                        {ticketData.ticket_origin}
                      </span>
                    </div>
                    <div className="d-flex flex-column">
                      <span className="fs--2">Last Activity</span>
                      <span className="ms-1 fw-bold text-capitalize">
                        {ticketData.updated_at}
                      </span>
                    </div>
                  </div>
                  <div
                    style={{ maxHeight: "29vh", overflow: "auto" }}
                    className="mb-3"
                  >
                    <Attachments
                      itemId={ticketId}
                      type="TICKET"
                      fetchUrl={`crm/ticket-attachments/${ticketData.id}`}
                      attachmentControl={false}
                      itemsClassName="border-start-0 border-end-0"
                    />
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={7} className="d-flex flex-column">
              <Card className="flex-fill">
                <Card.Header
                  className="border-bottom fw-semibold"
                  style={{ fontSize: "15px" }}
                >
                  Task Info
                </Card.Header>
                <Card.Body className="py-2">
                  <Form id="task-form" onFocus={() => setFormError({})}>
                    <Row>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput2"
                        >
                          <Form.Label className="require-data">
                            Title
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={4}
                            name="title"
                            onChange={handleFieldChange}
                            value={formData.title}
                            isInvalid={!!formError.title}
                            onClick={() => setFormError({})}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formError.title}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label className="require-data">
                            Project
                          </Form.Label>
                          <SelectProject
                            name="project_id"
                            value={formData.project_id_ref}
                            error={formError.project_id}
                            disabled={true}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formError.project_id}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="require-data">
                            Start Date
                          </Form.Label>
                          <AppTimePicker
                            name="start_date"
                            yearPlaceholder="yyyy"
                            monthPlaceholder="mm"
                            dayPlaceholder="dd"
                            hourPlaceholder="hh"
                            minutePlaceholder="mm"
                            onChange={handleFieldChange}
                            value={formData.start_date}
                            isInvalid={!!formError.start_date}
                            showDefaultDate={true}
                          />
                          <Form.Control.Feedback type="is-invalid">
                            {formError.start_date}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="require-data">
                            End Date
                          </Form.Label>
                          <AppTimePicker
                            name="end_date"
                            yearPlaceholder="yyyy"
                            monthPlaceholder="mm"
                            dayPlaceholder="dd"
                            hourPlaceholder="hh"
                            minutePlaceholder="mm"
                            onChange={handleFieldChange}
                            value={formData.end_date}
                            isInvalid={!!formError.end_date}
                            showDefaultDate={true}
                          />
                          <Form.Control.Feedback type="is-invalid">
                            {formError.end_date}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="require-data">
                            Status
                          </Form.Label>
                          <SelectTaskStatus
                            value={formData.status_ref}
                            name="status"
                            handleFieldChange={handleFieldChange}
                            error={!!formError.status}
                            clearable={false}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formError.status}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Assignee</Form.Label>
                          <SelectUsers
                            name="assigned_to"
                            value={formData.assigned_to_ref}
                            handleFieldChange={handleFieldChange}
                            error={formError.assigned_to}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formError.assigned_to}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col x={6}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput3"
                        >
                          <Form.Label>Collaborator</Form.Label>
                          <MultiSelectUsers
                            name="collaborators"
                            handleFieldChange={handleFieldChange}
                            error={!!formError.collaborators}
                            value={formData.collaborators_ref}
                            excludeUser={formData.assigned_to}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formError.description}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Label</Form.Label>
                          <MutiSelectTaskLabel
                            name="labels"
                            handleFieldChange={handleFieldChange}
                            error={!!formError.label}
                            value={formData.labels_ref}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formError.label}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="exampleForm.ControlInput3">
                          <Form.Label className="require-data">
                            Description
                          </Form.Label>
                          <div className="text-dark">
                            <TextEditor
                              name="description"
                              handleFieldChange={handleFieldChange}
                              value={formData.description}
                              isInvalid={!!formError.description}
                            />
                          </div>
                          <Form.Control.Feedback type="invalid">
                            {formError.description}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
                <Card.Footer>
                  <div className="d-flex gap-1">
                    <Button
                      variant="success"
                      size="sm"
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                      disabled={onSave}
                    >
                      {onSave ? (
                        <Spinner style={{ width: "18px", height: "18px" }} />
                      ) : (
                        " Convert"
                      )}
                    </Button>
                    <BackButton variant={"danger"} size={"sm"}>
                      Cancel
                    </BackButton>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </>
      ) : (
        <ListLoading />
      )}
      <CustomerDetails
        show={customerData?.showCustomerInfo}
        onHide={() => {
          setCustomerData({ showCustomerInfo: false, value: "", label: "" });
        }}
        value={customerData}
        showCustomerDetailsArea={customerData?.showCustomerInfo}
      />
      <UserProfile
        show={assigneeData?.show}
        handleClose={() =>
          setAssigneeData({
            show: false,
            id: "",
          })
        }
        id={assigneeData?.id}
      />
    </div>
  );
};

export default TicketConversionForm;
