import { React, useEffect, useRef, useState } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReportForThisWeekChart from "./ReportForThisWeekChart";
import Flex from "components/common/Flex";
import { useParams } from "react-router-dom";
import DashboardTileLoader from "module/common/Loading/DashboardTileLoader";

const ReportForThisWeek = ({ reportData = {}, loading }) => {
  const chartRef = useRef(null);
  const { itemInfoId } = useParams();
  const [graphData, setGraphData] = useState([]);
  const handleLegend = (event, name) => {
    chartRef.current.getEchartsInstance().dispatchAction({
      type: "legendToggleSelect",
      name: name,
    });
    event.target.closest("button").classList.toggle("opacity-50");
  };

  useEffect(() => {
    Object.keys(reportData)?.length > 0 &&
      setGraphData([
        ["product", "Last Week", "This Week"],
        ...Object.keys(reportData).map((key) => [key, ...reportData[key]]),
      ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData, itemInfoId]);

  return (
    <Card className="card-main rounded-0  h-100">
      <Card.Header className="pb-0">
        <Row className="flex-between-center gap-2">
          <Col xs="auto">
            <h6 className="mb-0">Report for this week (Working Hr)</h6>
          </Col>
          <Col xs="auto" as={Flex}>
            <Button
              size="sm"
              className="d-flex align-items-center p-0  me-3 shadow-none"
              variant="default"
              onClick={(event) => handleLegend(event, "Last Week")}
            >
              <FontAwesomeIcon
                icon="circle"
                className="text-primary fs--2 me-1"
              />
              Last Week
            </Button>
            <Button
              size="sm"
              className="d-flex align-items-center p-0 shadow-none"
              variant="default"
              onClick={(event) => handleLegend(event, "This Week")}
            >
              <FontAwesomeIcon icon="circle" className="text-300 fs--2 me-1" />
              This Week
            </Button>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="py-0">
        {!loading ? (
          <ReportForThisWeekChart data={graphData} ref={chartRef} />
        ) : (
          <div className="h-100 pt-4">
            <DashboardTileLoader column={3} />
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default ReportForThisWeek;
