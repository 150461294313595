import { React, useEffect, useState } from "react";
import axios from "axios";
import { Col, Row } from "react-bootstrap";

import { RiGroupLine, RiMoneyDollarBoxLine } from "react-icons/ri";
import { BsFillTicketDetailedFill } from "react-icons/bs";
import { AiOutlineProject } from "react-icons/ai";
// import { BiGridAlt } from "react-icons/bi";
import { TbClick } from "react-icons/tb";

import CountTile from "./CountTile";
import useAxisproPermission from "hooks/useAxisproPermission";

function TotalCountTile() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const axisProPermission = useAxisproPermission();
  const [
    showCustomer,
    showEmployee,
    showProject,
    // showEstimations,
    showTickets,
    showLeads,
  ] = [
    "show-customer",
    "list-employee",
    "list-project",
    "list-estimation",
    "list-ticket",
    "list-lead",
  ].map((permission) => axisProPermission(permission));
  const fetchData = () => {
    setIsLoading(true);
    axios
      .get("crm/dashboard-data")
      .then((res) => {
        if (res.data.success === true) {
          setData(res.data.data);
          setIsLoading(false);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Row className="g-2">
        {showCustomer && (
          <Col md={4} xxl={4}>
            <CountTile
              data={data.client_count}
              isLoading={isLoading}
              label={"Clients"}
              icon={<TbClick className="text-light" size={20} />}
              color={"rgba(255,160,0,255)"}
              route={"/account/grid"}
            />
          </Col>
        )}
        {showEmployee && (
          <Col md={4} xxl={4}>
            <CountTile
              data={data.employee_count}
              isLoading={isLoading}
              label={"Employees"}
              icon={<RiGroupLine className="text-light" size={20} />}
              color={"#47db3e"}
              route={"/employee"}
            />
          </Col>
        )}
        {showProject && (
          <Col md={4} xxl={4}>
            <CountTile
              data={data.project_count}
              isLoading={isLoading}
              label={"Projects"}
              icon={<AiOutlineProject className="text-light" size={20} />}
              color={"#ff0066"}
              route={"/project"}
            />
          </Col>
        )}
        {/* COMMENTED FOR FUTURE USE
        {showEstimations && (
          <Col md={4} xxl={4}>
            <CountTile
              data={data.estimation_count}
              isLoading={isLoading}
              label={"Estimations"}
              icon={<BiGridAlt className="text-light" size={20} />}
              color={"#5d686f"}
              route={"/estimation"}
            />
          </Col>
        )} */}
        {showTickets && (
          <Col md={4} xxl={4}>
            <CountTile
              data={data.ticket_count}
              isLoading={isLoading}
              label={"Tickets"}
              icon={
                <BsFillTicketDetailedFill className="text-light" size={20} />
              }
              color={"rgba(0,203,215,255)"}
              route={"/tickets/list?tab=all"}
            />
          </Col>
        )}
        {showLeads && (
          <Col md={4} xxl={4}>
            <CountTile
              data={data.leads_count}
              isLoading={isLoading}
              label={"Leads"}
              icon={<RiMoneyDollarBoxLine className="text-light" size={20} />}
              color={"rgba(25,35,47,255)"}
              route={"/lead"}
            />
          </Col>
        )}
      </Row>
    </>
  );
}

export default TotalCountTile;
