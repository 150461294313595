import { React, Suspense } from "react";
import { Outlet } from "react-router-dom";

import LoadingCommon from "../common/loading-screen/LoadingCommon";

export default function SuspensePageLoaderUI() {
  return (
    <Suspense
      fallback={
        <div className="bg-light flex-fill d-flex align-items-center justify-content-center">
          <LoadingCommon />
        </div>
      }
    >
      <Outlet />
    </Suspense>
  );
}
