import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import bg2 from "assets/img/icons/spot-illustrations/corner-2.png";
import Background from "components/common/Background";
import DashboardTileLoader from "module/common/Loading/DashboardTileLoader";
import getTaskStatusWithProps from "module/common/helpers/getTaskStatusWithProps";
import Carousel from "react-bootstrap/Carousel";
import { FaListUl, FaPlusCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { GetAcodaxPermission } from "module/common/Permissions/AcodaxPermission";
import { BiObjectsHorizontalLeft } from "react-icons/bi";

const chunkArray = (array, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};

function InfoTile({ totalTasks, dashboardData, loading, loadingBox = 6 }) {
  const data = Array.from({ length: loadingBox }, (_, index) => index + 1);
  const statusInfo = dashboardData?.status_info || [];
  const allStatusInfo = [totalTasks, ...statusInfo];
  const chunkSize = 6;
  const chunkedStatusInfo = chunkArray(allStatusInfo, chunkSize);

  const permission = {
    store: GetAcodaxPermission("TASK", "create"),
    show: GetAcodaxPermission("TASK", "show"),
  };

  const repeatStatusInfoArray = (chunk) => {
    if (chunk.length >= chunkSize) {
      return chunk;
    }
    const remainingChunks = chunkSize - chunk.length;
    return [...chunk, ...allStatusInfo.slice(0, remainingChunks)];
  };

  return (
    <>
      <Carousel className="task-carousal">
        {!loading && allStatusInfo.length > 0
          ? chunkedStatusInfo.map((chunk, chunkIndex) => (
              <Carousel.Item key={chunkIndex} className="px-2">
                <Row className="d-flex justify-content-start">
                  {repeatStatusInfoArray(chunk).map((info, key) => (
                    <Col
                      key={key}
                      xs={12}
                      md={6}
                      lg={4}
                      xl={2}
                      className="p-2 d-flex"
                    >
                      <Card className="card-main rounded-0 mt-2 flex-fill">
                        <Background image={bg2} className="bg-card rounded-0" />
                        <Card.Body>
                          <div className="d-grid justify-content-center align-items-center">
                            <span className="d-flex justify-content-center">
                              {info.status === "total_tasks" ? (
                                <BiObjectsHorizontalLeft
                                  color={"#00a9fe"}
                                  size={25}
                                />
                              ) : (
                                getTaskStatusWithProps(info.status, "icon")
                              )}
                            </span>
                            <span
                              className="fs-1 mt-2 text-capitalize text-center"
                              style={{ color: "rgb(1,0,1)" }}
                            >
                              {info.status.replace("_", " ")}
                            </span>
                            <span className="mt-2 d-flex justify-content-center">
                              {info.count} Tasks
                            </span>
                          </div>

                          {info.status === "total_tasks" && (
                            <div
                              className="d-flex justify-content-between"
                              style={{
                                position: "absolute",
                                zIndex: "2",
                                bottom: "10px",
                                right: "10px",
                                gap: "5px",
                              }}
                            >
                              <Button
                                variant="transparent"
                                className={`p-0 custom-transperent-common-button ${
                                  permission?.store ? "" : "invisible"
                                }`}
                                size="sm"
                                as={Link}
                                to="/tasks/dashboard/add"
                                title="Create New Task"
                              >
                                <div className="d-flex align-items-center gap-1">
                                  <FaPlusCircle size={15} />
                                </div>
                              </Button>
                              <Button
                                variant="transparent"
                                className={`p-0 custom-transperent-common-button ${
                                  permission?.show ? "" : "invisible"
                                }`}
                                size="sm"
                                as={Link}
                                to="/tasks/list"
                                title="Show All Tasks"
                              >
                                <div className="d-flex align-items-center gap-1">
                                  <FaListUl size={15} />
                                </div>
                              </Button>
                            </div>
                          )}
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Carousel.Item>
            ))
          : data.map((key) => (
              <Carousel.Item key={key} className="px-2">
                <Row className="d-flex justify-content-center">
                  {Array.from({ length: 6 }).map((_, index) => (
                    <Col
                      key={index}
                      xs={12}
                      md={6}
                      lg={4}
                      xl={2}
                      className="p-2"
                    >
                      <Card className="mt-2 card-main rounded-0">
                        <Background image={bg2} className="bg-card rounded-0" />
                        <Card.Body>
                          <DashboardTileLoader column={1} />
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Carousel.Item>
            ))}
      </Carousel>
    </>
  );
}

InfoTile.propTypes = {
  dashboardData: PropTypes.any,
  loading: PropTypes.bool,
};

export default InfoTile;
