import React from "react";
import { Card, Col } from "react-bootstrap";
import PropTypes from "prop-types";

import Flex from "components/common/Flex";
import Background from "components/common/Background";
import background from "assets/img/illustrations/crm-bar-chart.png";

function TicketsSingleTile({ label, number, variant }) {
  return (
    <Card style={{ padding: "20px 25px" }} className="border m-2 card-main">
      <Background image={background} />
      <Card.Body className="p-0">
        <Col sm={12} md={12}>
          <Flex className="position-relative">
            <div className="flex-1">
              <p
                className={`fw-normal mb-2 mt-1 fw-bold ${variant}`}
                style={{ fontSize: "17px" }}>
                {label}
              </p>
              <p
                className={`mb-0 ${variant}`}
                style={{
                  fontSize: "25px",
                  fontWeight: "700",
                  letterSpacing: "0.7px",
                }}>
                {number}
              </p>
            </div>
          </Flex>
        </Col>
      </Card.Body>
    </Card>
  );
}

TicketsSingleTile.propTypes = {
  label: PropTypes.string.isRequired,
  number: PropTypes.any.isRequired,
  variant: PropTypes.string.isRequired,
};

export default TicketsSingleTile;
