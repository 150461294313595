import { React, useEffect, useState } from "react";
import {
  Card,
  ListGroup,
  ListGroupItem,
  Offcanvas,
  Tab,
  Tabs,
  Image,
} from "react-bootstrap";

import {
  FaBuilding,
  FaCodeBranch,
  FaEnvelopeSquare,
  FaPhoneSquareAlt,
} from "react-icons/fa";

import { apiCall } from "helpers/apiCalls";
import PieChart from "module/common/Charts/PieChart";
import GraphChart from "module/common/Charts/GraphChart";
import BarChart from "module/common/Charts/BarChart";
import ActivityTimeLine from "module/common/ViewPages/Common/Activity/Timeline/ActivityTimeLine";

import ProfileImage from "assets/img/Avathar/employee.avif";
import LoadingScreen from "components/common/loading-screen/LoadingScreen";
import useAxisproPermission from "hooks/useAxisproPermission";
import { CustomBackButton } from "module/common/Buttons/AppButtons";
import getTaskStatusWithProps from "module/common/helpers/getTaskStatusWithProps";

function UserProfile({ show, handleClose, id }) {
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const axisProPermission = useAxisproPermission();
  const [activeTab, setActiveTab] = useState("info");
  const fetchData = async () => {
    setLoading(true);
    const data = await apiCall({
      url: `crm/assignee-details`,
      params: { assignee: id },
    });
    setUserData(data.summary);
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const userProfileClose = () => {
    setActiveTab("info");
    handleClose();
  };

  const transformString = (string) => {
    const reformedString = string.replace(
      /(_|^|\s+)(\w)/g,
      (_, spaceOrStart, letter) => spaceOrStart + letter.toUpperCase()
    );
    return reformedString.replace("_", " ");
  };

  const allTaskStatusColorCodesArray = getTaskStatusWithProps()?.map(
    (item) => item.value
  );

  const optionsPie = {
    title: {
      text: "Task Overview",
      left: "center",
      top: "40px",
    },
    color: allTaskStatusColorCodesArray,
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c} ({d}%)",
    },

    series: [
      {
        name: "Pie Chart",
        type: "pie",
        radius: "50%",
        center: ["50%", "60%"],
        data:
          Object.keys(userData).length > 0 &&
          userData.task_status_count.map((item) => {
            return {
              name: transformString(item.status),
              value: item.count,
            };
          }),
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  const dataBar = {
    labels: Object.keys(userData).length > 0 && userData.six_months_before,
    datasets: [
      {
        label: "Completed",
        data:
          Object.keys(userData).length > 0 &&
          userData.monthly_ticket_count.map((item) => item.tickets_closed),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
      {
        label: "Assigned",
        data:
          Object.keys(userData).length > 0 &&
          userData.monthly_ticket_count.map((item) => item.tickets_assigned),
        backgroundColor: "rgba(255, 99, 132, 0.6)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };

  const optionsBar = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: true,
        position: "top",
      },
    },
  };

  const optionsGraph = {
    title: {
      text: "Project Overview",
      subtext: "(Tasks 6 months)",
      left: "left",
    },
    color: [
      "#0048BA",
      "#B0BF1A",
      "#7CB9E8",
      "#B284BE",
      "#DB2D43",
      "#C46210",
      "#9F2B68",
      "#F19CBB",
      "#3B7A57",
      "#FFBF00",
      "#9966CC",
      "#3DDC84",
    ],
    tooltip: {
      trigger: "axis",
    },
    xAxis: {
      type: "category",
      data: Object.keys(userData).length > 0 && userData.six_months_before,
    },
    yAxis: {
      type: "value",
    },
    series:
      Object.keys(userData).length > 0 &&
      userData.task_count_by_project.map((item) => {
        return {
          name: item.project,
          type: "line",
          data: item.data.map((graphValue) => graphValue.task_count),
        };
      }),
  };

  const handleTabSwitch = (tab) => {
    setActiveTab(tab);
  };

  return (
    <Offcanvas
      show={show}
      onHide={userProfileClose}
      placement="end"
      style={{
        width: "600px",
      }}
    >
      <Offcanvas.Body className="p-0">
        {loading ? (
          <div className="d-flex h-100 justify-content-center align-items-center">
            <LoadingScreen message="Fetching Data..." />
          </div>
        ) : Object.keys(userData).length > 0 ? (
          <>
            <div className="project-custom-profile-section">
              <div className="project-custom-close-button d-flex justify-content-end">
                <CustomBackButton
                  variant="light"
                  title="Back"
                  onClick={userProfileClose}
                  className={"shadow"}
                />
              </div>
              <div className="project-custom-profile-image">
                <div className="d-flex justify-content-center">
                  <Image
                    src={userData.user?.image ?? ProfileImage}
                    roundedCircle
                    height={120}
                    width={120}
                  />
                </div>
                <div className="d-flex flex-column mt-2">
                  <span className="fw-bold fs--1 text-dark text-uppercase d-flex justify-content-center">
                    {userData.user?.name}
                  </span>
                  <span className="d-flex justify-content-center">
                    {userData.user?.role}
                  </span>
                  {userData.user?.company_name && (
                    <span className="d-flex justify-content-center">
                      {userData.user.company_name}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <Tabs
              defaultActiveKey={activeTab}
              id="custom-profile-tabs"
              className="custom-profile-tabs"
              variant="pills"
              justify
              onSelect={handleTabSwitch}
              style={{ color: "#000" }}
            >
              <Tab eventKey="info" title="Info" className="custom-profile-tab">
                {activeTab === "info" && (
                  <Card className="card-user-profile">
                    <Card.Body>
                      <div className="">
                        <ListGroup className="border-bottom rounded-0">
                          <ListGroupItem
                            style={{ color: "#000" }}
                            className="ps-0 pb-2 border-0  fw-bold"
                          >
                            Contact Informations
                          </ListGroupItem>
                          <ListGroupItem
                            style={{ color: "#000" }}
                            className="border-0 ps-0 pb-1 pt-1"
                          >
                            <FaPhoneSquareAlt
                              size={16}
                              className="me-1"
                              color="#3FA2F6"
                            />
                            Phone : {userData.user?.phone}
                          </ListGroupItem>
                          <ListGroupItem
                            style={{ color: "#000" }}
                            className="border-0 ps-0 pt-1 pb-3"
                          >
                            <FaEnvelopeSquare
                              size={16}
                              className="me-1"
                              color="#FFA38F"
                            />
                            Email : {userData.user?.email}
                          </ListGroupItem>
                        </ListGroup>
                        <ListGroup
                          style={{ color: "#000" }}
                          className="border-bottom rounded-0 pt-2"
                        >
                          <ListGroupItem
                            style={{ color: "#000" }}
                            className="ps-0 pb-2 border-0  fw-bold"
                          >
                            Organizational Information
                          </ListGroupItem>
                          <ListGroupItem
                            style={{ color: "#000" }}
                            className="border-0 ps-0 pb-1 pt-1"
                          >
                            <FaBuilding
                              size={16}
                              className="me-1"
                              color="#E4003A"
                            />
                            Organization : {userData.user?.company_name}
                          </ListGroupItem>
                          <ListGroupItem
                            style={{ color: "#000" }}
                            className="border-0 ps-0 pt-1"
                          >
                            <FaCodeBranch
                              size={16}
                              className="me-1"
                              color="#FFB22C"
                            />
                            Branch : {userData.user?.branch_name}
                          </ListGroupItem>
                          <ListGroupItem
                            style={{ color: "#000" }}
                            className="border-0 ps-0 pb-1 pt-1 pb-3"
                          >
                            <FaPhoneSquareAlt
                              size={16}
                              className="me-1"
                              color="#508D4E"
                            />
                            Official Phone : {userData.user?.official_phone}
                          </ListGroupItem>
                        </ListGroup>
                      </div>
                    </Card.Body>
                  </Card>
                )}
              </Tab>
              {axisProPermission("list-project") && (
                <Tab
                  eventKey="projects"
                  title="Projects"
                  className="custom-profile-tab"
                >
                  {activeTab === "projects" && (
                    <Card className="card-user-profile">
                      <Card.Body>
                        <ListGroup horizontal>
                          <ListGroupItem className="border-0 p-0 fw-bolder text-muted">
                            Active Projects : {userData.active_project}
                          </ListGroupItem>
                          <ListGroupItem className="border-0 p-0 ms-auto fw-bolder text-muted">
                            Completed Projects : {userData.completed_project}
                          </ListGroupItem>
                        </ListGroup>
                        <div className="chart-section-user-profile mt-3">
                          <GraphChart
                            style={{ color: "#000" }}
                            options={optionsGraph}
                          />
                        </div>
                      </Card.Body>
                    </Card>
                  )}
                </Tab>
              )}
              {axisProPermission("show-task") && (
                <Tab
                  eventKey="tasks"
                  title="Tasks"
                  className="custom-profile-tab"
                >
                  {activeTab === "tasks" && (
                    <Card className="card-user-profile">
                      <Card.Body>
                        <ListGroup horizontal>
                          <ListGroupItem className="border-0 p-0 fw-bolder text-muted">
                            Assigned Tasks : {userData.no_of_assigned_task}
                          </ListGroupItem>
                          <ListGroupItem className="border-0 p-0 ms-auto fw-bolder text-muted">
                            Completed Tasks : {userData.no_of_completed_tasks}
                          </ListGroupItem>
                        </ListGroup>
                        <div className="chart-section-user-profile">
                          <PieChart
                            style={{ color: "#000" }}
                            options={optionsPie}
                            height={300}
                            width={"100%"}
                          />
                        </div>
                      </Card.Body>
                    </Card>
                  )}
                </Tab>
              )}
              {axisProPermission("list-ticket") && (
                <Tab
                  eventKey="tickets"
                  title="Tickets"
                  className="custom-profile-tab"
                >
                  {activeTab === "tickets" && (
                    <Card className="card-user-profile">
                      <Card.Body>
                        <ListGroup horizontal>
                          <ListGroupItem className="border-0 p-0 fw-bolder text-muted">
                            Assigned Tickets : {userData.no_of_assigned_tickets}
                          </ListGroupItem>
                          <ListGroupItem className="border-0 p-0 ms-auto fw-bolder text-muted">
                            Completed Tickets :{" "}
                            {userData.no_of_completed_tickets}
                          </ListGroupItem>
                        </ListGroup>
                        <div className="chart-section-user-profile mt-3">
                          <BarChart
                            style={{ color: "#000" }}
                            options={optionsBar}
                            data={dataBar}
                          />
                        </div>
                      </Card.Body>
                    </Card>
                  )}
                </Tab>
              )}
              <Tab
                eventKey="activity"
                title="Activity"
                className="custom-profile-tab"
              >
                {activeTab === "activity" && (
                  <ActivityTimeLine
                    height={"41rem"}
                    userId={userData?.user?.id}
                  />
                )}
              </Tab>
            </Tabs>
          </>
        ) : (
          <div className="d-flex h-100 justify-content-center align-items-center">
            <span>No User Data Available</span>
          </div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default UserProfile;
