import { React, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Modal } from "react-bootstrap";
import PropTypes from "prop-types";

import { IoMdArrowBack } from "react-icons/io";
import { FaHome } from "react-icons/fa";

import { AuthWizardContext } from "context/Context";

import "./Error403.scss";

function Error403Popup({ show, onHide }) {
  const { user } = useContext(AuthWizardContext);
  const navigate = useNavigate();

  const handleClick = () => {
    onHide();
    user?.customer_id ? navigate("/project") : navigate("/dashboard");
  };
  const handleBack = () => {
    navigate(-1);
    onHide();
  };

  return (
    <Modal
      show={show}
      size="lg"
      className="modal-with-overlay"
      centered
      backdrop="static"
      animation
    >
      <Card>
        <Card.Body className="p-5 d-flex flex-column align-items-center justify-content-center">
          <div className="my-auto d-flex flex-column align-items-center justify-content-center">
            <div className="display-1 text-300 fs-error">403</div>
          </div>
          <div className="container-403 d-flex flex-column align-items-center justify-content-center">
            <div className="content-container-403">
              <div className="forbidden-sign-403 mb-3" />
              <span className="text-header-403">
                Access to this page is restricted.
              </span>
              <p className="text-description-403">
                You do not have permission to access this page, contact your
                system administrator.
              </p>
              <div className="mt-3">
                <Button
                  variant="primary"
                  size="sm"
                  onClick={handleClick}
                  style={{ backgroundColor: "#191A24", borderColor: "#191A24" }}
                >
                  <div className="d-flex align-items-center gap-2">
                    <FaHome size={20} />
                    <span>Back to Home</span>
                  </div>
                </Button>
                <Button
                  className="btn btn-danger btn-sm ms-2"
                  onClick={handleBack}
                >
                  <IoMdArrowBack size={18} className="me-2" />
                  Back
                </Button>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Modal>
  );
}

Error403Popup.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

export default Error403Popup;
