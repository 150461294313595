import { React, useContext, useEffect, useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { Button, Card, Dropdown, ListGroup } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { isIterableArray } from "helpers/utils";
import { apiCall } from "helpers/apiCalls";
import { AuthWizardContext } from "context/Context";
import { showToast } from "module/common/Toast/toast";
import FalconCardHeader from "components/common/FalconCardHeader";
import Notification from "components/notification/Notification";
import NotificationLoadingScreen from "components/notification/NotificationLoadingScreen";

const NotificationDropdown = ({ iconColor }) => {
  const { user } = useContext(AuthWizardContext);
  // State
  const [notifications, setNotifications] = useState({});
  const [loading, setLoading] = useState(true);
  const [unreadCount, setUnreadCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  // Handler
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const getNotifications = async () => {
    setLoading(true);

    const apiData = await apiCall({
      url: "notifications/unread",
      params: {
        app_name: "CRM",
      },
    });

    if (apiData && apiData.data && apiData.data.length > 0) {
      setNotifications(apiData.data);
    }

    setLoading(false);
  };

  const markAllAsRead = () => {
    setLoading(true);
    axios
      .post("notifications/mark-all-as-read", { app_name: "CRM" })
      .then((response) => {
        if (response.data.success === true) {
          setUnreadCount(0);
          getNotifications();
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            "error"
          );
        }
      })
      .catch((error) => {
        showToast(
          `Something went wrong, please refresh the page and try again.`,
          "error"
        );

        console.log(error);
      });
  };

  useLayoutEffect(() => {
    const countUnreadNotifications = async () => {
      const data = await apiCall({
        url: "notifications/unread-count",
        params: {
          app_name: "CRM",
        },
      });
      if (data && data.count) {
        setUnreadCount(data.count);
        if (!isOpen && data.count > 0) {
          toast.warn(
            <div className="ms-2">
              Unread notification
              {data.count > 1 ? "s" : ""} found.
            </div>,
            {
              theme: "dark",
              position: "bottom-right",
              icon: (
                <div>
                  <div
                    className="bg-white rounded-circle text-danger d-flex align-items-center justify-content-center fw-bold p-2"
                    style={{ width: 30, height: 30, fontSize: 12 }}
                  >
                    {data.count > 99 ? 99 : data.count}
                    {data.count > 99 && <sup>+</sup>}
                  </div>
                </div>
              ),
              onClick: handleToggle,
            }
          );
        }
      }
    };
    countUnreadNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isOpen) {
      getNotifications();
    }
  }, [isOpen]);

  useEffect(() => {
    const currentURL = window.location.href;
    const urlObject = new URL(currentURL);
    const domain = urlObject.hostname;
    window.Echo.channel(`user_notification_${domain}_${user.id}`).listen(
      "NotificationBroadcast",
      (notificationItem) => {
        if (notificationItem && typeof notificationItem === "object") {
          notificationItem.total_unread_notification > 0 &&
            setUnreadCount(notificationItem.total_unread_notification);
          toast.warn(notificationItem.title, {
            theme: "dark",
            position: "bottom-right",
          });
        }
      }
    );
    return () => {
      window.Echo.channel(
        `user_notification_${domain}_${user.id}`
      ).stopListening("NotificationBroadcast");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.innerWidth < 1200 && setIsOpen(false);
    });
  }, []);

  const nullNotification = {
    data: {
      body: "Nothing to show",
    },
  };

  return (
    <>
      <Dropdown navbar={true} show={isOpen} onToggle={handleToggle}>
        <Dropdown.Toggle
          bsPrefix="toggle"
          variant="transparent"
          style={{ boxShadow: "none", border: "none" }}
          className="px-0"
        >
          {unreadCount > 0 ? (
            <div
              className="position-absolute bg-primary text-white d-flex justify-content-center align-items-center rounded-circle"
              style={{
                width: unreadCount > 9 ? 20 : 15,
                height: unreadCount > 9 ? 20 : 15,
                fontSize: "9.5px",
                right: 0,
                top: unreadCount > 9 ? 2 : 5,
                marginTop: "5px",
              }}
            >
              <span>
                {unreadCount > 99 ? (
                  <>
                    99<sup>+</sup>
                  </>
                ) : (
                  unreadCount
                )}
              </span>
            </div>
          ) : null}
          <FontAwesomeIcon
            icon="bell"
            transform="shrink-6"
            className="fs-4"
            style={{ marginTop: "5px" }}
            title="Notifications"
            color={iconColor}
          />
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret-bg">
          <Card
            className="dropdown-menu-notification dropdown-menu-end shadow-none"
            style={{ maxWidth: "20rem" }}
          >
            <FalconCardHeader
              className="card-header"
              title="Notifications"
              titleTag="h6"
              light={false}
              endEl={
                unreadCount > 0 ? (
                  <Button
                    variant="transparent"
                    size="sm"
                    className="card-link fw-normal text-primary"
                    onClick={markAllAsRead}
                  >
                    Mark all as read
                  </Button>
                ) : null
              }
            />
            {!loading ? (
              <>
                {notifications && notifications.length > 0 ? (
                  <ListGroup
                    variant="flush"
                    className="fw-normal fs--1 scrollbar"
                    style={{ maxHeight: "19rem" }}
                  >
                    {isIterableArray(notifications) && (
                      <>
                        {notifications.map((notification) => (
                          <ListGroup.Item
                            key={notification.id}
                            onClick={handleToggle}
                          >
                            <Notification {...notification} flush />
                          </ListGroup.Item>
                        ))}
                      </>
                    )}
                  </ListGroup>
                ) : (
                  <ListGroup
                    variant="flush"
                    className="fw-normal fs--1 scrollbar"
                    style={{ maxHeight: "19rem" }}
                  >
                    <ListGroup.Item>
                      <Notification {...nullNotification} flush />
                    </ListGroup.Item>
                  </ListGroup>
                )}
                {notifications.length > 0 && (
                  <div
                    className="card-footer text-center border-top"
                    onClick={handleToggle}
                  >
                    <Link className="card-link d-block" to="#!">
                      View all
                    </Link>
                  </div>
                )}
              </>
            ) : (
              [1, 2, 3, 4].map((index) => (
                <NotificationLoadingScreen key={index} />
              ))
            )}
          </Card>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default NotificationDropdown;
