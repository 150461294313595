import React from "react";
import MarkdownEditor from "@uiw/react-markdown-editor";

const MarkDownTextEditor = ({
  value,
  handleFieldChange,
  isPreview,
  showToolbar,
  enablePreview = false,
  height = "200px",
  name,
}) => {
  return (
    <>
      {isPreview ? (
        <MarkdownEditor.Markdown source={value} height={height} />
      ) : (
        <MarkdownEditor
          value={value}
          height={height}
          onChange={(value, viewUpdate) =>
            handleFieldChange(null, {
              action: "text-editor",
              name: name,
              value: value,
            })
          }
          showToolbar={showToolbar}
          enablePreview={enablePreview}
        />
      )}
    </>
  );
};

export default MarkDownTextEditor;
