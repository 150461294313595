import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import ActivityLogLoader from "./ActivityLogLoader";

function ActivityLoadMore({ nextLink, loadNextPage }) {
  const componentRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        loadNextPage();
      }
    }, options);
    observer.observe(componentRef.current);
    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextLink]);

  return (
    <div ref={componentRef} style={{ height: "1px" }}>
      <ActivityLogLoader height="24rem" items="10" />
    </div>
  );
}

ActivityLoadMore.propTypes = {
  nextLink: PropTypes.any,
  loadNextPage: PropTypes.func.isRequired,
};

export default ActivityLoadMore;

