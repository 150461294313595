import React from "react";
import { Card } from "react-bootstrap";
import PropTypes from "prop-types";

import DoughnutChart from "module/common/Charts/DoughnutChart";
import DashboardTileLoader from "module/common/Loading/DashboardTileLoader";

function StatusSummary({ dashboardData, loading }) {
  const capitalizeString = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const dataDoughnut = {
    labels:
      dashboardData?.status_info?.length > 0 &&
      dashboardData.status_info.map((item) =>
        capitalizeString(item.status.replace("_", " "))
      ),
    datasets: [
      {
        data:
          dashboardData?.status_info?.length > 0 &&
          dashboardData.status_info.map((item) =>
            parseFloat(item.percentage).toFixed(2)
          ),
        backgroundColor: [
          "#9C8AFF",
          "#75CDFF",
          "#07bc0cb8",
          "#FC859D",
          "#FFA636",
          "#808080",
          "#db3232",
          "#29C3BE",
          "#eb7936",
          "#b3ce68",
        ],
        hoverBackgroundColor: [
          "#9C8AFF",
          "#75CDFF",
          "#07bc0cb8",
          "#FC859D",
          "#FFA636",
          "#808080",
          "#db3232",
          "#29C3BE",
          "#eb7936",
          "#b3ce68",
        ],
        borderWidth: 1,
        cutout: 30,
      },
    ],
  };

  const optionsDoughnut = {
    responsive: true,
    maintainAspectRatio: false,
    title: {
      display: true,
      text: "Doughnut Chart",
      fontSize: 20,
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        align: "start",

        labels: {
          padding: 10,
          boxWidth: 10,
          fontColor: "black",
          fontSize: 14,
        },
      },
    },
  };

  return (
    <Card className="card-main rounded-0">
      {!loading ? (
        <Card.Body>
          <Card.Title className="d-flex justify-content-start fw-bold">
            Status Summary (%)
          </Card.Title>
          <div className="d-flex flex-wrap justify-content-center">
            <DoughnutChart
              height={200}
              width={"100%"}
              data={dataDoughnut}
              options={optionsDoughnut}
            />
          </div>
        </Card.Body>
      ) : (
        <Card.Body className="p-4">
          <DashboardTileLoader column={3} />
        </Card.Body>
      )}
    </Card>
  );
}

StatusSummary.propTypes = {
  dashboardData: PropTypes.any,
  loading: PropTypes.bool,
};

export default StatusSummary;
