import { AiFillSchedule } from "react-icons/ai";
import { MdOutlineDoneAll } from "react-icons/md";
const getMeetingStatusWithProps = (status, type, iconSize = 25) => {
  const statusWithColor = [
    {
      key: "SCHEDULED",
      value: "#F5803E",
      icon: (
        <AiFillSchedule color={"#F5803E"} size={iconSize} className="mb-1" />
      ),
    },
    {
      key: "COMPLETED",
      value: "#00D27A",
      icon: (
        <MdOutlineDoneAll size={iconSize} color={"#00D27A"} className="mb-1" />
      ),
    },
  ];

  if (status) {
    const foundObject = statusWithColor.find((obj) => obj.key === status);
    if (type === "icon") {
      return foundObject?.icon;
    } else {
      return status ? foundObject?.value ?? "grey" : foundObject?.icon;
    }
  } else {
    if (!type) {
      return statusWithColor;
    }
  }
};

export default getMeetingStatusWithProps;
