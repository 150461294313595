import { React, useRef, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";

import { FaTimes } from "react-icons/fa";

import DownloadLineItem from "./DownloadLineItem";

export default function DownloadCenterRecentDownloads({
  show,
  items,
  handleShowAll,
}) {
  const [showWindow, setShowWindow] = useState(show);
  const dropdownSectionRef = useRef(null);
  function removeScreen() {
    window.removeEventListener("click", handleClickOutside);
    setShowWindow(false);
  }

  function handleClose(event) {
    event.preventDefault();
    removeScreen();
  }

  function handleShowAllButton(event) {
    event.preventDefault();
    removeScreen();
    handleShowAll();
  }

  function handleClickOutside(event) {
    if (
      dropdownSectionRef?.current &&
      !dropdownSectionRef.current.contains(event.target)
    ) {
      removeScreen();
    }
  }

  useEffect(() => {
    if (show) {
      setShowWindow(show);
      window.addEventListener("click", handleClickOutside, true);
    }

    return () => {
      window.removeEventListener("click", handleClickOutside);
      setShowWindow(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return showWindow && Array.isArray(items) && items.length > 0 ? (
    <div
      className="download-center-quick-notification-screen position-absolute shadow-sm d-flex flex-column"
      ref={dropdownSectionRef}
    >
      <div className="d-flex flex-row align-items-center header-section shadow-sm">
        <p className="mb-0 me-2">Recent Downloads</p>
        <Button
          size="sm"
          variant="transparent"
          className="ms-auto p-0"
          onClick={handleClose}
        >
          <FaTimes />
        </Button>
      </div>

      <div className="downloads-list-section">
        <ul className="list-unstyled">
          {items.slice(0, 5).map((data, index) => (
            <li className="mb-2 py-2" key={index}>
              <DownloadLineItem item={data} />
            </li>
          ))}
        </ul>
      </div>

      <div className="text-center footer-section border-top p">
        <Button
          variant="transparent"
          size="sm"
          className="p-0"
          onClick={handleShowAllButton}
        >
          Show all
        </Button>
      </div>
    </div>
  ) : null;
}

DownloadCenterRecentDownloads.propTypes = {
  show: PropTypes.bool,
  items: PropTypes.array,
  handleShowAll: PropTypes.func,
};
