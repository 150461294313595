import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { ListGroup } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import PropTypes from "prop-types";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { FcFilledFilter } from "react-icons/fc";
import useAxisproTranslate from "hooks/useAxisproTranslate";
function DateRangePicker({
  formData,
  setFormData,
  dateRange_form,
  className,
  popperClassName,
}) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [years, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState("");
  const [showMonthLayout, setShowMonthLayout] = useState(false);
  const [showCalender, setShowCalender] = useState(false);
  const date = new Date();
  const Translate = useAxisproTranslate();
  const [startDate, setStartDate] = useState(new Date());
  const [showList, setShowList] = useState(true);
  const [endDate, setEndDate] = useState(date.setDate(date.getDate() + 7));
  const getFormattedDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (showCalender && end) {
      const formattedStartDate = getFormattedDate(new Date(start));
      const formattedEndDate = getFormattedDate(new Date(end));
      setFormData({
        ...formData,
        [dateRange_form.from]: formattedStartDate,
        [dateRange_form.to]: formattedEndDate,
        date_range: "Custom Date",
      });
      setShowList(false);
    }
  };
  useEffect(() => {
    if (
      formData.date_range !== "Specific Month" ||
      !formData.date_range !== "Custom Date"
    ) {
      setShowCalender(false);
      setShowMonthLayout(false);
    }
  }, [formData?.date_range]);

  const handleIntialDate = () => {
    setFormData({
      ...formData,
      [dateRange_form.from]: "",
      [dateRange_form.to]: "",
      date_range: "",
      date_range_for: "",
      date_range_for_ref: { label: "", value: "" },
    });
    setStartDate("");
    setEndDate("");
    setShowList(false);
  };

  const handleTillDate = () => {
    const today = new Date();
    const formattedToday = getFormattedDate(today);
    setFormData({
      ...formData,
      [dateRange_form.from]: "",
      [dateRange_form.to]: formattedToday,
      date_range: "Till Today",
    });
    setStartDate("");
    setEndDate("");
    setShowList(false);
  };
  const handleTodayDate = () => {
    const today = new Date();
    const formattedToday = getFormattedDate(today);
    setFormData({
      ...formData,
      [dateRange_form.from]: formattedToday,
      [dateRange_form.to]: formattedToday,
      date_range: "Today",
    });
    setStartDate(today);
    setEndDate(today);
    setShowList(false);
  };
  const handleYesterdayDate = () => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const formattedYesterday = getFormattedDate(yesterday);
    setFormData({
      ...formData,
      [dateRange_form.from]: formattedYesterday,
      [dateRange_form.to]: formattedYesterday,
      date_range: "Yesterday",
    });
    setStartDate(yesterday);
    setEndDate(yesterday);
    setShowList(false);
  };
  const handleThisWeekDate = () => {
    const today = new Date();
    const startOfWeek = new Date(today);
    const endOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - today.getDay());
    endOfWeek.setDate(today.getDate() + (6 - today.getDay()));
    const formattedStartOfWeek = getFormattedDate(startOfWeek);
    const formattedEndOfWeek = getFormattedDate(endOfWeek);
    setFormData({
      ...formData,
      [dateRange_form.from]: formattedStartOfWeek,
      [dateRange_form.to]: formattedEndOfWeek,
      date_range: "This Week",
    });
    setStartDate(startOfWeek);
    setEndDate(endOfWeek);
    setShowList(false);
  };
  const handlePreviousWeek = () => {
    const today = new Date();
    const currentDayOfWeek = today.getDay();
    const startOfWeek = new Date(today);
    const endOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - currentDayOfWeek - 7);
    endOfWeek.setDate(today.getDate() - currentDayOfWeek - 1);
    const formattedStartOfWeek = getFormattedDate(startOfWeek);
    const formattedEndOfWeek = getFormattedDate(endOfWeek);
    setFormData({
      ...formData,
      [dateRange_form.from]: formattedStartOfWeek,
      [dateRange_form.to]: formattedEndOfWeek,
      date_range: "Previous Week",
    });
    setStartDate(startOfWeek);
    setEndDate(endOfWeek);
    setShowList(false);
  };
  const handleThisMonth = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const lastDate = new Date(year, month + 1, 0).getDate();
    const startDate = new Date(year, month, 1);
    const endDate = new Date(year, month, lastDate);
    const formattedStartOfMonth = getFormattedDate(startDate);
    const formattedEndOfMonth = getFormattedDate(endDate);
    setFormData({
      ...formData,
      [dateRange_form.from]: formattedStartOfMonth,
      [dateRange_form.to]: formattedEndOfMonth,
      date_range: "This Month",
    });
    setStartDate(startDate);
    setEndDate(endDate);
    setShowList(false);
  };
  const handlePreviousMonth = () => {
    const today = new Date();
    const firstDayOfCurrentMonth = new Date(
      today.getFullYear(),
      today.getMonth(),
      1
    );
    const prevMonthEndDate = new Date(firstDayOfCurrentMonth);
    prevMonthEndDate.setDate(0);
    const prevMonthStartDate = new Date(prevMonthEndDate);
    prevMonthStartDate.setDate(1);
    const formattedPrevMonthStartDate = getFormattedDate(prevMonthStartDate);
    const formattedPrevMonthEndDate = getFormattedDate(prevMonthEndDate);
    setFormData({
      ...formData,
      [dateRange_form.from]: formattedPrevMonthStartDate,
      [dateRange_form.to]: formattedPrevMonthEndDate,
      date_range: "Previous Month",
    });
    setStartDate(prevMonthStartDate);
    setEndDate(prevMonthEndDate);
    setShowList(false);
  };
  const handleThisQuarter = () => {
    const today = new Date();
    const currentMonth = today.getMonth();
    let quarterStartMonth;
    if (currentMonth >= 0 && currentMonth <= 2) {
      quarterStartMonth = 0;
    } else if (currentMonth >= 3 && currentMonth <= 5) {
      quarterStartMonth = 3;
    } else if (currentMonth >= 6 && currentMonth <= 8) {
      quarterStartMonth = 6;
    } else {
      quarterStartMonth = 9;
    }
    const startDate = new Date(today.getFullYear(), quarterStartMonth, 1);
    const formattedStartOfQuarter = getFormattedDate(startDate);
    const lastDate = new Date(today.getFullYear(), quarterStartMonth + 3, 0);
    const formattedEndOfQuarter = getFormattedDate(lastDate);
    setFormData({
      ...formData,
      [dateRange_form.from]: formattedStartOfQuarter,
      [dateRange_form.to]: formattedEndOfQuarter,
      date_range: "This Quarter",
    });
    setStartDate(startDate);
    setEndDate(lastDate);
    setShowList(false);
  };
  const handlePreviousQuarter = () => {
    let today = new Date();
    let currentMonth = today.getMonth();
    let currentYear = today.getFullYear();
    let prevQuarterStartMonth;
    if (currentMonth >= 0 && currentMonth <= 2) {
      prevQuarterStartMonth = 9;
      currentYear--;
      currentMonth = 12;
    } else if (currentMonth >= 3 && currentMonth <= 5) {
      prevQuarterStartMonth = 0;
      currentMonth = 3;
    } else if (currentMonth >= 6 && currentMonth <= 8) {
      prevQuarterStartMonth = 3;
      currentMonth = 6;
    } else {
      prevQuarterStartMonth = 6;
      currentMonth = 9;
    }
    const prevQuarterStartDate = new Date(
      currentYear,
      prevQuarterStartMonth,
      1
    );
    const prevQuarterEndDate = new Date(currentYear, currentMonth, 0);
    const formattedPrevQuarterStartDate =
      getFormattedDate(prevQuarterStartDate);
    const formattedPrevQuarterEndDate = getFormattedDate(prevQuarterEndDate);
    setFormData({
      ...formData,
      [dateRange_form.from]: formattedPrevQuarterStartDate,
      [dateRange_form.to]: formattedPrevQuarterEndDate,
      date_range: "Previous Quarter",
    });
    setStartDate(prevQuarterStartDate);
    setEndDate(prevQuarterEndDate);
    setShowList(false);
  };
  const handleThisHalf = () => {
    const today = new Date();
    const currentMonth = today.getMonth();
    let halfStartMonth;
    if (currentMonth >= 0 && currentMonth <= 5) {
      halfStartMonth = 0;
    } else if (currentMonth >= 6 && currentMonth <= 12) {
      halfStartMonth = 6;
    }
    const startDate = new Date(today.getFullYear(), halfStartMonth, 1);
    const formattedStartOfHalf = getFormattedDate(startDate);
    const lastDate = new Date(today.getFullYear(), halfStartMonth + 6, 0);
    const formattedEndOfHalf = getFormattedDate(lastDate);
    setFormData({
      ...formData,
      [dateRange_form.from]: formattedStartOfHalf,
      [dateRange_form.to]: formattedEndOfHalf,
      date_range: "This Half",
    });
    setStartDate(startDate);
    setEndDate(lastDate);
    setShowList(false);
  };
  const handlePreviousHalf = () => {
    const today = new Date();
    const currentMonth = today.getMonth();
    let currentYear = today.getFullYear();
    let prevHalfStartMonth;
    let prevHalfEndMonth;
    if (currentMonth >= 0 && currentMonth <= 5) {
      prevHalfStartMonth = 6;
      prevHalfEndMonth = 11;
      currentYear--;
    } else {
      prevHalfStartMonth = 0;
      prevHalfEndMonth = 5;
    }
    const prevHalfStartDate = new Date(currentYear, prevHalfStartMonth, 1);
    const prevHalfEndDate = new Date(currentYear, prevHalfEndMonth + 1, 0);
    const formattedPrevHalfStartDate = getFormattedDate(prevHalfStartDate);
    const formattedPrevHalfEndDate = getFormattedDate(prevHalfEndDate);
    setFormData({
      ...formData,
      [dateRange_form.from]: formattedPrevHalfStartDate,
      [dateRange_form.to]: formattedPrevHalfEndDate,
      date_range: "Previous Half",
    });
    setStartDate(prevHalfStartDate);
    setEndDate(prevHalfEndDate);
    setShowList(false);
  };
  const handleThisYear = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const startDate = new Date(currentYear, 0, 1);
    const lastDate = new Date(currentYear, 11, 31);
    const formattedStartOfYear = getFormattedDate(startDate);
    const formattedEndOfYear = getFormattedDate(lastDate);
    setFormData({
      ...formData,
      [dateRange_form.from]: formattedStartOfYear,
      [dateRange_form.to]: formattedEndOfYear,
      date_range: "This Year",
    });
    setStartDate(startDate);
    setEndDate(lastDate);
    setShowList(false);
  };
  const handlePreviousYear = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const prevYearStartDate = new Date(currentYear - 1, 0, 1);
    const prevYearEndDate = new Date(currentYear - 1, 11, 31);
    const formattedPrevYearStartDate = getFormattedDate(prevYearStartDate);
    const formattedPrevYearEndDate = getFormattedDate(prevYearEndDate);
    setFormData({
      ...formData,
      [dateRange_form.from]: formattedPrevYearStartDate,
      [dateRange_form.to]: formattedPrevYearEndDate,
      date_range: "Previous Year",
    });
    setStartDate(prevYearStartDate);
    setEndDate(prevYearEndDate);
    setShowList(false);
  };
  const handleSelectMonth = (month) => {
    const normalizedMonthName = month.toLowerCase();
    const monthIndex = new Date(
      Date.parse(`${normalizedMonthName} 1, ${years}`)
    ).getMonth();
    const startDate = new Date(years, monthIndex, 1);
    const endDate = new Date(years, monthIndex + 1, 0);
    const formattedSpecificMonthStartDate = getFormattedDate(startDate);
    const formattedSpecificMonthEndDate = getFormattedDate(endDate);
    setFormData({
      ...formData,
      [dateRange_form.from]: formattedSpecificMonthStartDate,
      [dateRange_form.to]: formattedSpecificMonthEndDate,
      date_range: "Specific Month",
    });
    setStartDate(startDate);
    setEndDate(endDate);
    setMonth(month);
    setShowList(false);
  };
  const handleSpecificMonth = () => {
    setShowMonthLayout(true);
    setShowCalender(false);
  };
  const handleCustomDate = () => {
    setShowMonthLayout(false);
    setShowCalender(true);
    setEndDate(new Date());
    setStartDate(new Date());
  };
  const handleYearLeftButton = () => {
    let newYear = years - 1;
    setYear(newYear);
  };
  const handleYearRightButton = () => {
    let newYear = years + 1;
    setYear(newYear);
  };
  const handleDatePickerFocus = () => {
    setShowList(true);
  };

  return (
    <div className={`d-flex w-100 ${className ? className : ""}`}>
      <div className="bg-secondary py-1 px-2 d-flex justify-content-center align-items-center">
        <FcFilledFilter size={10} className="text-white" />
      </div>
      <DatePicker
        onFocus={handleDatePickerFocus}
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        value={
          formData.date_range === "till_today" ||
          formData.date_range === "Till Today"
            ? "Till Today"
            : formData.date_range === "Specific Month"
            ? month + "-" + years
            : formData.date_range === "Custom Date"
            ? formData[dateRange_form.from] +
              "  to  " +
              formData[dateRange_form.to]
            : formData.date_range
            ? formData.date_range
            : "Select Range"
        }
        dateFormat="MMM dd"
        className="form-control d-flex"
        popperClassName={popperClassName ? popperClassName : ""}
        calendarContainer={({ children }) => (
          <div className="react-datepicker react-datepicker__predefined-ranges d-flex bg-light">
            {showList && (
              <div>
                <ListGroup
                  variant="flush"
                  className="react-datepicker__ranges border-end-1 cursor-pointer"
                >
                  <ListGroup.Item
                    active={formData.date_range === "" ? true : false}
                    onClick={handleIntialDate}
                    className="p-1 text-center"
                  >
                    {Translate("Select Range")}
                  </ListGroup.Item>
                  <ListGroup.Item
                    active={
                      formData.date_range === "till_today" ||
                      formData.date_range === "Till Today"
                        ? true
                        : false
                    }
                    onClick={handleTillDate}
                    className="p-1 text-center"
                  >
                    {Translate("Till Today")}
                  </ListGroup.Item>
                  <ListGroup.Item
                    active={formData?.date_range === "Today" ? true : false}
                    onClick={handleTodayDate}
                    className="p-1 text-center"
                  >
                    {Translate("Today")}
                  </ListGroup.Item>
                  <ListGroup.Item
                    active={formData?.date_range === "Yesterday" ? true : false}
                    onClick={handleYesterdayDate}
                    className="p-1 text-center"
                  >
                    {Translate("Yesterday")}
                  </ListGroup.Item>
                  <ListGroup.Item
                    active={formData?.date_range === "This Week" ? true : false}
                    onClick={handleThisWeekDate}
                    className="p-1 text-center"
                  >
                    {Translate("This Week")}
                  </ListGroup.Item>

                  <ListGroup.Item
                    active={
                      formData?.date_range === "This Month" ? true : false
                    }
                    onClick={handleThisMonth}
                    className="p-1 text-center"
                  >
                    {Translate("This Month")}
                  </ListGroup.Item>

                  <ListGroup.Item
                    active={
                      formData?.date_range === "This Quarter" ? true : false
                    }
                    onClick={handleThisQuarter}
                    className="p-1 text-center"
                  >
                    {Translate("This Quarter")}
                  </ListGroup.Item>

                  <ListGroup.Item
                    active={formData?.date_range === "This Half" ? true : false}
                    onClick={handleThisHalf}
                    className="p-1 text-center"
                  >
                    {Translate("This Half")}
                  </ListGroup.Item>

                  <ListGroup.Item
                    active={formData?.date_range === "This Year" ? true : false}
                    onClick={handleThisYear}
                    className="p-1 text-center"
                  >
                    {Translate("This Year")}
                  </ListGroup.Item>

                  <ListGroup.Item
                    active={
                      formData?.date_range === "Previous Week" ? true : false
                    }
                    onClick={handlePreviousWeek}
                    className="p-1 text-center"
                  >
                    {Translate("Previous Week")}
                  </ListGroup.Item>
                  <ListGroup.Item
                    active={
                      formData?.date_range === "Previous Month" ? true : false
                    }
                    onClick={handlePreviousMonth}
                    className="p-1 text-center"
                  >
                    {Translate("Previous Month")}
                  </ListGroup.Item>
                  <ListGroup.Item
                    active={
                      formData?.date_range === "Previous Quarter" ? true : false
                    }
                    onClick={handlePreviousQuarter}
                    className="p-1 text-center"
                  >
                    {Translate("Previous Quarter")}
                  </ListGroup.Item>
                  <ListGroup.Item
                    active={
                      formData?.date_range === "Previous Half" ? true : false
                    }
                    onClick={handlePreviousHalf}
                    className="p-1 text-center"
                  >
                    {Translate(" Previous Half")}
                  </ListGroup.Item>
                  <ListGroup.Item
                    active={
                      formData?.date_range === "Previous Year" ? true : false
                    }
                    onClick={handlePreviousYear}
                    className="p-1 text-center"
                  >
                    {Translate("Previous Year")}
                  </ListGroup.Item>
                  <ListGroup.Item
                    active={
                      formData?.date_range === "Specific Month" ? true : false
                    }
                    onClick={handleSpecificMonth}
                    className="p-1 text-center"
                  >
                    {Translate("Specific Month")}
                  </ListGroup.Item>
                  <ListGroup.Item
                    active={
                      formData?.date_range === "Custom Date" ? true : false
                    }
                    onClick={handleCustomDate}
                    className="p-1 text-center"
                  >
                    {Translate("Custom Date")}
                  </ListGroup.Item>
                </ListGroup>
              </div>
            )}
            {showMonthLayout && (
              <div className="" style={{ width: "320px" }}>
                <div className="d-flex w-100 align-items-center justify-content-around mt-3">
                  <div
                    className="cursor-pointer"
                    onClick={handleYearLeftButton}
                  >
                    <FaAngleLeft size={20} />
                  </div>
                  <h1 className="fs-1 ">{years}</h1>
                  <div
                    className="cursor-pointer"
                    onClick={handleYearRightButton}
                  >
                    <FaAngleRight size={20} />
                  </div>
                </div>
                <div className="d-flex flex-wrap ps-2 pt-3">
                  {monthNames.map((items, key) => {
                    return (
                      <div
                        key={key}
                        className={`date-range-month  cursor-pointer d-flex align-items-center justify-content-center ${
                          items === month && "active-month"
                        }`}
                        onClick={() => handleSelectMonth(items)}
                      >
                        <div>{Translate(items)}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {showCalender && children}
          </div>
        )}
      />
    </div>
  );
}
DateRangePicker.propTypes = {
  formData: PropTypes.any,
  setFormData: PropTypes.any,
  dateRange_form: PropTypes.any,
};
export default DateRangePicker;
