import { React } from "react";
import AsyncSelect from "react-select/async";
import PropTypes from "prop-types";
import useAutoCompleteApiCallHandler from "hooks/useAutoCompleteApiCallHandler";

const SelectTicketOrigin = ({
  handleFieldChange,
  name,
  error,
  value,
  style,
  placeholder,
  isDisabled,
  onFocus,
}) => {

  const { fetchData, setDefaultResult, loading, defaultOptions } =
  useAutoCompleteApiCallHandler({
    url: "crm/ticket-origin",
    setParams: (searchKey) => ({ name: searchKey }),
    setOptions: (option) => setOptionData(option),
    onFocus: onFocus,
  });

const setOptionData = (option) => ({
  label: option,
  value: option,
});

  return (
    <>
      <AsyncSelect
        cacheOptions
        loadOptions={fetchData}
        defaultOptions={defaultOptions}
        onFocus={setDefaultResult}
        isLoading={loading}
        isClearable
        isDisabled={isDisabled}
        name={name ? name : "project_status"}
        value={value}
        placeholder={placeholder ?? ""}
        onChange={handleFieldChange}
        className={`custom-select ${
          error
            ? "form-control ps-0 py-0 is-invalid text-capitalize"
            : "text-capitalize"
        }`}
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "#696c96" : "inherit",
            "&:hover": {
              backgroundColor: state.isSelected ? "#192E49" : "#e1e2ea",
            },
          }),
          control: (provided) => ({
            ...provided,
            height: 32,
            minHeight: 32,
            ...style,
          }),
        }}
      />
    </>
  );
};

SelectTicketOrigin.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.any,
  keyName: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
};

export default SelectTicketOrigin;
