import { React, useEffect, useState } from "react";
import axios from "axios";
import { Col, Offcanvas, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import "./CustomerDetails.css";
import CustomerDetailsTab from "./CustomerDetailsTab";
import OtherDetailsCollapse from "./OtherDetailsCollapse";
import LoadingScreen from "components/common/loading-screen/LoadingScreen";
import { FaUserCog } from "react-icons/fa";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomBackButton } from "module/common/Buttons/AppButtons";
import useAxisproTranslate from "hooks/useAxisproTranslate";

const CustomerDetails = ({ show, onHide, value, showCustomerDetailsArea }) => {
  const Translate = useAxisproTranslate();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    await axios
      .get(`sales/customers/${value.value}`)
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setLoading(true);
    if (showCustomerDetailsArea) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCustomerDetailsArea, value.value]);

  return (
    <Offcanvas
      show={show}
      placement="end"
      onHide={onHide}
      style={{ width: "500px" }}
    >
      <Offcanvas.Header
        style={{ boxShadow: "none" }}
        className="offcanvas-heading-style border-bottom pt-2 pb-2 mt-1"
      >
        <div className="d-flex align-items-center">
          <FaUserCog size={25} className="me-2 text-dark" />
          <span
            className="text-uppercase text-dark"
            style={{ fontSize: "16px" }}
          >
            {Translate("Client Details")}
          </span>
        </div>
        <CustomBackButton
          variant="light"
          title="Back"
          onClick={onHide}
          className={"shadow"}
        />
      </Offcanvas.Header>
      {!loading && data !== "" ? (
        <Offcanvas.Body className="customer-details-offcanvas p-0">
          <div className="p-3 pb-0" style={{ backgroundColor: "#f4f5ff" }}>
            <div className="d-flex flex-row align-items-center">
              <div className="profile-icon d-flex justify-content-center align-items-center me-2">
                <Image
                  className="profile-icon"
                  src={data?.image}
                  alt="customer-image"
                  width={45}
                  height={45}
                  roundedCircle
                />
              </div>
              <div className="details">
                <div className="d-flex">
                  <h5 className="mt-1 me-2 text-uppercase fw-bold">
                    {data.name}
                  </h5>
                  <Link
                    className="m-0"
                    to={`/account/overview/${value.value}?tab=overview`}
                  >
                    <FontAwesomeIcon icon="external-link-alt" size="xs" />
                  </Link>
                </div>
                <div className="d-flex">
                  <h6 className="mb-0 text-muted">
                    {data.email}
                    {data.email ? <span className="me-1 mx-1">|</span> : ""}
                    {data.phone}
                  </h6>
                </div>
              </div>
            </div>
            <Row className="gy-3 pt-1 mt-3 border-top">
              <Col sm={6} className="mt-1">
                <div className="me-2 py-2">
                  <h6 className="text-muted text-uppercase">
                    {Translate("Outstanding Receivables")}
                  </h6>
                  <h5 className="fs--1 fw-bold text-warning">
                    {data.total_outstanding_formatted}
                  </h5>
                </div>
              </Col>
              <Col sm={6} className="mt-1">
                <div className="py-2">
                  <h6 className="text-muted text-uppercase">
                    {Translate("Unused Credits")}
                  </h6>
                  <h5 className="fs--1 fw-bold text-success">
                    {data.unused_credits_formatted}
                  </h5>
                </div>
              </Col>
            </Row>
            {data.customer_type ||
            data.currency_code ||
            data.payment_term_name ||
            data.credit_status_name ? (
              <OtherDetailsCollapse data={data} />
            ) : (
              ""
            )}
          </div>
          <CustomerDetailsTab data={data} itemId={value.value} />
        </Offcanvas.Body>
      ) : (
        <LoadingScreen />
      )}
    </Offcanvas>
  );
};

CustomerDetails.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  value: PropTypes.any,
  showCustomerDetailsArea: PropTypes.bool,
};

export default CustomerDetails;
