import React, { useContext } from "react";
import { Card } from "react-bootstrap";
import CountUp from "react-countup";
import PropTypes from "prop-types";

import { StoreContext } from "../../../../context/Context";
import Background from "components/common/Background";
import bg1 from "assets/img/icons/spot-illustrations/corner-2.png";
import DashboardTileLoader from "module/common/Loading/DashboardTileLoader";
function ProjectCost({ price, loading = false }) {
  const { store } = useContext(StoreContext);
  let baseCurrency = store?.globalConfiguration?.base_currency ?? "AED";

  return (
    <Card className="card-main rounded-0 h-100">
      <Background image={bg1} className="bg-card rounded-0" />
      <Card.Body>
        {loading ? (
          <div style={{ height: "46px" }} className="mb-4">
            <DashboardTileLoader column={1} />
          </div>
        ) : (
          <div className="d-flex align-items-center">
            <span className="fs-2 fw-bold">{baseCurrency}</span>
            <div className="d-flex flex-column align-items-end w-100 justify-content-center">
              <span className="fs-3 text-dark text-center mt-3">
                <CountUp
                  start={0}
                  end={
                    price >= 1000000
                      ? price / 1000000
                      : price > 10000
                      ? price / 1000
                      : price
                  }
                  suffix={price >= 1000000 ? "M" : price > 10000 ? "K" : ""}
                  duration={2.75}
                  decimals={2}
                  separator=","
                  decimal="."
                />
              </span>
              <span className="mt-2">Total project price</span>
            </div>
          </div>
        )}
      </Card.Body>
    </Card>
  );
}

ProjectCost.propTypes = {
  price: PropTypes.any,
};

export default ProjectCost;
