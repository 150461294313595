import React from "react";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const BackButton = ({ children, onClick, variant, ...props }) => {
  const navigate = useNavigate();
  const goBack = (e) => {
    e.preventDefault();
    navigate(-1);
  };
  return (
    <Button
      type="button"
      variant={variant ? variant : "primary"}
      onClick={onClick ? onClick : goBack}
      {...props}
    >
      {children ? children : "Back"}
    </Button>
  );
};

BackButton.propTypes = {
  children: PropTypes.any,
  variant: PropTypes.string,
};

export default BackButton;
