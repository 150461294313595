import { BsExclamationDiamondFill } from "react-icons/bs";
import {
  FcHighPriority,
  FcLowPriority,
  FcMediumPriority,
} from "react-icons/fc";

const getPriorityWithProps = (status, type, iconSize = 25) => {
  const statusWithColor = [
    {
      key: "Low",
      value: "#4caf50",
      icon: <FcLowPriority color="#4caf50" size={iconSize} />,
    },
    {
      key: "Medium",
      value: "#f48c06",
      icon: <FcMediumPriority color="#f48c06" size={iconSize} />,
    },
    {
      key: "High",
      value: "#ff5400",
      icon: <BsExclamationDiamondFill color="#ff5400" size={iconSize} />,
    },
    {
      key: "Warm",
      value: "#ff5400",
      icon: <BsExclamationDiamondFill color="#ff5400" size={iconSize} />,
    },
    {
      key: "Hot",
      value: "#E63757",
      icon: <FcHighPriority color="#E63757" size={iconSize} />,
    },
    {
      key: "Critical",
      value: "#E63757",
      icon: <FcHighPriority color="#E63757" size={iconSize} />,
    },
    {
      key: "Cold",
      value: "#27BCFD",
      icon: <FcLowPriority color="#27BCFD" size={iconSize} />,
    },
  ];

  if (status) {
    const foundObject = statusWithColor.find((obj) => obj.key === status);
    if (type === "icon") {
      return foundObject?.icon;
    } else {
      return status ? foundObject?.value ?? "grey" : foundObject?.icon;
    }
  } else {
    if (!type) {
      return statusWithColor;
    }
  }
};

export default getPriorityWithProps;
