import { React } from "react";
import { Card, Table } from "react-bootstrap";
import PropTypes from "prop-types";

import FalconCardHeader from "components/common/FalconCardHeader";
import DashboardTileLoader from "module/common/Loading/DashboardTileLoader";

const ToDoListItem = ({ item }) => {
  return (
    <tr className="border">
      <td width={"5%"}>
        <span className="fw-bold">#{item.task_number}</span>
      </td>
      <td>
        <div className="d-flex flex-column">
          <span className="custom-text-muted-crm">Title</span>
          <span className="">{item.title}</span>
        </div>
      </td>
      <td>
        <div className="d-flex flex-column">
          <span className="custom-text-muted-crm">Assignee</span>
          <span>{item?.assigned_to ? item?.assignee?.name : "N/A"}</span>
        </div>
      </td>
      <td width={"10%"}>
        <div className="d-flex flex-column">
          <span className="custom-text-muted-crm">Start Date</span>
          <span>
            {item?.start_date_formatted ? item?.start_date_formatted : "N/A"}
          </span>
        </div>
      </td>
      <td width={"10%"}>
        <div className="d-flex flex-column">
          <span className="custom-text-muted-crm">End Date</span>
          <span>
            {item?.end_date_formatted ? item?.end_date_formatted : "N/A"}
          </span>
        </div>
      </td>
      <td>
        <div className="d-flex flex-column">
          <span className="custom-text-muted-crm">Priority</span>
          <span>{item?.priority ? item?.priority : "N/A"}</span>
        </div>
      </td>
    </tr>
  );
};

const ToDoList = ({ todo, loading }) => {
  // const [todoItems, setTodoItems] = useState(todo);
  // const handleChange = (id, completed) => {
  //   const updatedTodos = todoItems.map((item) =>
  //     item.id === id ? { ...item, completed } : item
  //   );
  //   setTodoItems(updatedTodos);
  // };
  return (
    <Card className="h-100 rounded-0 card-main">
      <FalconCardHeader
        title={
          <h6 className="mb-0 fw-semi-bold text-muted">To Do Task List</h6>
        }
        titleTag="h6"
      />
      <Card.Body
        className="pt-0"
        style={{ overflow: "none", overflowY: "scroll", height: "20rem" }}
      >
        {loading ? (
          <DashboardTileLoader column={4} />
        ) : (
          <Table size="sm">
            <tbody>
              {todo && todo.length > 0 ? (
                todo.map((item, index) => (
                  <ToDoListItem
                    key={item.id}
                    item={item}
                    index={index}
                    length={todo.length}
                    // setTodoItems={setTodoItems}
                    // handleChange={handleChange}
                  />
                ))
              ) : (
                <tr style={{ height: "20rem" }}>
                  <td>
                    <div className="h-100 d-flex align-items-center justify-content-center">
                      <h2 className="fs-1 m-0">Nothing to display!</h2>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        )}
      </Card.Body>
      {!loading && (
        <Card.Footer className="p-2 bg-light text-center">
          <p className="m-0">showing - {todo?.length ?? 0}</p>
        </Card.Footer>
      )}
    </Card>
  );
};

ToDoListItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ToDoList;
