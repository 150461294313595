import React from "react";
import { Placeholder, Tab, Tabs } from "react-bootstrap";

const TabLoading = () => {
  return (
    <Tabs
      id="ticket-custom-tabs"
      variant="pills"
      className="ticket-custom-tabs"
      justify
    >
      <Tab
        title={
          <div>
            <div>
              <Placeholder as="p" animation="glow" className="m-0">
                <Placeholder
                  className="w-100"
                  size="sm"
                  bg="secondary"
                  style={{ opacity: "0.01", borderRadius: "10px" }}
                />
              </Placeholder>
            </div>
            <div>
              <Placeholder as="p" animation="glow" className="m-0">
                <Placeholder
                  className="w-100"
                  size="sm"
                  bg="secondary"
                  style={{ opacity: "0.01", borderRadius: "10px" }}
                />
              </Placeholder>
            </div>
          </div>
        }
      ></Tab>
    </Tabs>
  );
};

export default TabLoading;
