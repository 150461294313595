import SoftBadge from "components/common/SoftBadge";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import React from "react";
import { Col, Image, ListGroup, ListGroupItem } from "react-bootstrap";
import { IoIosMail } from "react-icons/io";
import { MdLocalPhone, MdOutlineLocationOn } from "react-icons/md";
import ProfileImage from "assets/img/Avathar/ProfileImage.jpg";

const ContactCard = ({ item }) => {
  const Translate = useAxisproTranslate();
  return (
    <Col lg={6} md={6}>
      <div
        className="border-dashed h-100 overflow-auto mb-1 d-flex flex-column 
            justify-content-between"
      >
        <div className="p-2">
          <div className={`w-100 d-flex align-items-center mt-1 mb-2 ms-2`}>
            <Image
              src={ProfileImage}
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
              }}
            />
            <div className="d-flex flex-column  ms-2">
              <span className="fw-bold text-dark">
                {item.contact_name ? item.contact_name : "N/A"}
              </span>
            </div>
          </div>
          <div className="ms-2">
            <ListGroup>
              <ListGroupItem className="border-0 p-0 pt-1 pb-1">
                <div className="d-flex justify-content-start align-items-center gap-2">
                  <span>
                    <SoftBadge bg={"warning"} className="p-1 rounded-2">
                      <MdLocalPhone size={20} />
                    </SoftBadge>
                  </span>
                  <div className="d-flex flex-column">
                    <span style={{ color: "rgb(97,111,166)" }}>
                      {Translate("Number")}
                    </span>
                    <span className="text-dark">
                      {item.contact_mobile ? item.contact_mobile : "N/A"}
                    </span>
                  </div>
                </div>
              </ListGroupItem>
            </ListGroup>
          </div>
          <div className="ms-2">
            <ListGroup>
              <ListGroupItem className="border-0 p-0 pt-1 pb-1">
                <div className="d-flex justify-content-start align-items-center gap-2">
                  <span>
                    <SoftBadge bg={"danger"} className="p-1 rounded-2">
                      <IoIosMail size={20} />
                    </SoftBadge>
                  </span>
                  <div className="d-flex flex-column">
                    <span style={{ color: "rgb(97,111,166)" }}>
                      {Translate("Email")}
                    </span>
                    <span className="text-dark">
                      {item.contact_email ? item.contact_email : "N/A"}
                    </span>
                  </div>
                </div>
              </ListGroupItem>
            </ListGroup>
          </div>
          <div className="ms-2">
            <ListGroup>
              <ListGroupItem className="border-0 p-0 pt-1 pb-1">
                <div className="d-flex justify-content-start align-items-center gap-2">
                  <span>
                    <SoftBadge bg={"dark"} className="p-1 rounded-2">
                      <MdOutlineLocationOn size={20} />
                    </SoftBadge>
                  </span>
                  <div className="d-flex flex-column">
                    <span style={{ color: "rgb(97,111,166)" }}>
                      {Translate("Address")}
                    </span>
                    <span className="text-dark">
                      {item.contact_address ? item.contact_address : "N/A"}
                    </span>
                  </div>
                </div>
              </ListGroupItem>
            </ListGroup>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default ContactCard;
