import React, { useEffect, useState } from 'react'

function RibbonButtonLoader({ items = 10 }) {
    const [loadingIndex, setLoadingIndex] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            setLoadingIndex((prev) => prev + 1);
        }, 300);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="btn-ribbon-group">
            {[...Array(items).keys()].map((index) => (
                <div
                    key={index}
                    className={`btn-ribbon-wrapper ${loadingIndex > index ? 'visible' : ''}`}
                >
                    <button style={{ height: "30px", width: "100px" }} className="btn-ribbon">
                        {" "}
                    </button>
                </div>
            ))}
        </div>
    )
}

export default RibbonButtonLoader