import axios from "axios";
import { userResetPasswordFormKeys } from "helpers/formKeys";
import generateFormDataHelper from "helpers/generateFormDataHelper";
import { showToast } from "module/common/Toast/toast";
import React, { useContext, useState } from "react";
import {
  Alert,
  Button,
  Form,
  InputGroup,
  Modal,
  Spinner,
} from "react-bootstrap";
import { IoIosLock } from "react-icons/io";
import { PiHandWavingBold } from "react-icons/pi";
import removeEmptyFields from "helpers/removeEmptyFields";
import { AuthWizardContext } from "context/Context";
import { FaLock } from "react-icons/fa";
import { MdOutlineDangerous } from "react-icons/md";

const PasswordExpiry = ({ children }) => {
  const [saving, setIsSaving] = useState(false);
  const [formData, setFormData] = useState(userResetPasswordFormKeys);
  const [errors, setErrors] = useState({});
  const { dispatch, user } = useContext(AuthWizardContext);

  const handleFieldChange = (e) =>
    setFormData(generateFormDataHelper(e, null, formData));

  const handleSubmit = (e) => {
    e.preventDefault();
    if (saving) return;
    let validatedErrors = {};
    setErrors({});
    if (!formData.old_password)
      validatedErrors.old_password = "Old password is required";
    if (!formData.new_password)
      validatedErrors.new_password = "New password is required";
    if (!formData.c_password)
      validatedErrors.c_password = "Confirm password is required";
    if (formData.new_password && formData.c_password !== formData.new_password)
      validatedErrors.c_password =
        "New password and Confirm password does not match!";
    if (formData.old_password === formData.new_password)
      validatedErrors.new_password =
        "New password and Old password cannot be same!";
    if (Object.keys(validatedErrors).length > 0)
      return setErrors(validatedErrors);
    setIsSaving(true);
    axios({
      method: "post",
      url: `change-password`,
      params: {
        ...removeEmptyFields(formData),
      },
    })
      .then((response) => {
        setIsSaving(false);
        if (response.data.success !== true) {
          setFormData(userResetPasswordFormKeys);
          return showToast(
            `Something went wrong, please refresh the page and try again.`,
            "error"
          );
        }
        const userData = {
          ...user,
          password_expired: false,
        };
        // save user data with token to localStorage
        localStorage.setItem("AXIS_PRO_CRM_USER", JSON.stringify(userData));
        // update auth context
        dispatch({ type: "UPDATE", payload: { ...userData } });
        showToast(response.data.message, "success");
      })
      .catch((error) => {
        setIsSaving(false);
        if (!error.response.data || !error.response.data.message)
          return showToast(
            `Something went wrong, please refresh the page and try again.`,
            "error"
          );

        const validation_error = error?.response?.data?.data?.errors
          ? error.response.data.data.errors
          : null;

        if (validation_error) setErrors(validation_error);

        if (error.response.data.message)
          showToast(error.response.data.message, "error");
      });
  };

  return (
    <>
      {user?.password_expired ? (
        <Modal
          show={true}
          backdrop="static"
          keyboard={false}
          centered
          className="no-border-radius-modal"
        >
          <Modal.Header>
            <Modal.Title className="d-flex align-items-center">
              <MdOutlineDangerous size={23} color="#ff6f6f" />
              <span className="ms-1 fs-0">Password Expired!</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              {Object.keys(errors).length > 0 ? (
                <Form.Group className="mb-3">
                  <Alert variant="danger">
                    <ul className="ms-2 mb-0">
                      {Object.keys(errors).map((key, index) => (
                        <li key={index} className="mb-1">
                          {errors[key]}
                        </li>
                      ))}
                    </ul>
                  </Alert>
                </Form.Group>
              ) : null}
              <Form.Group className="mb-3">
                <div className="m-0 d-flex flex-column gap-1">
                  <div className="d-flex align-items-center gap-2">
                    <PiHandWavingBold size={16} color="#ff7916" />
                    <span>Hi {user?.name},</span>
                  </div>
                  <span className="custom-text-muted-crm ms-3">
                    Your password is no longer valid. To continue, please update
                    your password
                  </span>
                </div>
              </Form.Group>

              <Form.Group className="mb-3" controlId="old-password">
                <Form.Label>Old password</Form.Label>
                <InputGroup
                  className={` rounded shadow py-1 ${
                    formData.old_password.length > 0 && !errors.old_password
                      ? "is-valid"
                      : errors.old_password
                      ? "is-invalid"
                      : ""
                  }`}
                >
                  <InputGroup.Text className="border-0 bg-transparent px-2">
                    <IoIosLock size={13} />
                  </InputGroup.Text>
                  <Form.Control
                    type="password"
                    className="border-0"
                    placeholder="Enter new password"
                    name="old_password"
                    onChange={handleFieldChange}
                    value={formData.old_password}
                    isValid={
                      formData.old_password.length > 0 && !errors.old_password
                    }
                    isInvalid={!!errors.old_password}
                  />
                </InputGroup>
              </Form.Group>

              <Form.Group className="mb-3" controlId="new-password">
                <Form.Label>New password</Form.Label>
                <InputGroup
                  className={` rounded shadow py-1 ${
                    formData.new_password.length > 0 && !errors.new_password
                      ? "is-valid"
                      : errors.new_password
                      ? "is-invalid"
                      : ""
                  }`}
                >
                  <InputGroup.Text className="border-0 bg-transparent px-2">
                    <FaLock size={10} />
                  </InputGroup.Text>
                  <Form.Control
                    type="password"
                    className="border-0"
                    placeholder="Enter new password"
                    name="new_password"
                    onChange={handleFieldChange}
                    value={formData.new_password}
                    isValid={
                      formData.new_password.length > 0 && !errors.new_password
                    }
                    isInvalid={!!errors.new_password}
                  />
                </InputGroup>
              </Form.Group>

              <Form.Group className="mb-3" controlId="confirm-password">
                <Form.Label>Confirm password</Form.Label>
                <InputGroup
                  className={` rounded shadow py-1 ${
                    formData.c_password.length > 0 && !errors.c_password
                      ? "is-valid"
                      : errors.c_password
                      ? "is-invalid"
                      : ""
                  }`}
                >
                  <InputGroup.Text className="border-0 bg-transparent px-2">
                    <FaLock size={10} />
                  </InputGroup.Text>
                  <Form.Control
                    type="password"
                    className="border-0"
                    placeholder="Confirm new password"
                    name="c_password"
                    onChange={handleFieldChange}
                    value={formData.c_password}
                    isValid={
                      formData.c_password.length > 0 &&
                      !errors.c_password &&
                      formData.password &&
                      formData.password === formData.c_password
                    }
                    isInvalid={!!errors.c_password}
                  />
                </InputGroup>
              </Form.Group>

              <Form.Group className="d-flex flex-row mb-3">
                <Button
                  type="submit"
                  className="rounded w-100 py-2 d-flex flex-row justify-content-center align-items-center"
                  disabled={saving}
                  style={{ backgroundColor: "#6AABAC", borderColor: "#6AABAC" }}
                >
                  {saving ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        variant="white"
                        className="me-1"
                        style={{ width: "12px", height: "12px" }}
                      />
                      <span className="fs--1">Validating...</span>
                    </>
                  ) : (
                    <>
                      <span>Proceed</span>
                    </>
                  )}
                </Button>
              </Form.Group>
            </Form>
          </Modal.Body>
        </Modal>
      ) : (
        children
      )}
    </>
  );
};

export default PasswordExpiry;
