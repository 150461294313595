import React from "react";
import { Card, ProgressBar } from "react-bootstrap";
import PropTypes from "prop-types";
import { BsDatabaseFillExclamation } from "react-icons/bs";
import { FaCircle } from "react-icons/fa";
import DashboardTileLoader from "module/common/Loading/DashboardTileLoader";
import { Link } from "react-router-dom";
import getTaskStatusWithProps from "module/common/helpers/getTaskStatusWithProps";
import { stringLimiter } from "helpers/utils";

function ListTile({
  data,
  head,
  taskCount,
  taskCompletedCount,
  taskPercentage,
  loading,
}) {
  return (
    <Card className="card-main rounded-0" style={{ height: "462px" }}>
      {!loading ? (
        <>
          {head && (
            <Card.Header className="border-bottom">
              <div className="d-flex align-items-center">
                <span>
                  {taskCompletedCount} Task Completed out of &nbsp;
                  {taskCount}
                </span>
                <span className="ms-4">
                  <ProgressBar
                    variant="success"
                    now={taskPercentage}
                    style={{ height: "8px", width: "15vw" }}
                  />
                </span>
              </div>
            </Card.Header>
          )}
          <Card.Body
            className="p-0"
            style={{
              height: head ? "46vh" : "48.3vh",
              overflow: "hidden",
              overflowY: "scroll",
            }}
          >
            {data && data.length > 0 ? (
              data.map((item, key) => (
                <div className="d-flex flex-column p-3 border-bottom" key={key}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <span className="fw-bold">
                        <Link
                          className="custom-link-style-crm link-like-text"
                          to={`/tasks/list/overview/${item.id}`}
                          title={item.title}
                        >
                          {stringLimiter(item.title, 35)}
                        </Link>
                      </span>
                      <div className="text-capitalize">
                        <span
                          className="badge p-1"
                          style={{
                            backgroundColor: getTaskStatusWithProps(
                              item.status
                            ),
                            color: "white",
                          }}
                        >
                          {item.status.replace("_", " ")}
                        </span>
                        <FaCircle size={5} className="ms-1 me-1" />
                        {item.created_at_diff}
                      </div>
                    </div>
                    <div className="d-flex flex-column">
                      <span className="custom-text-muted-crm">
                        Created by : {item.created_user_full_name}
                      </span>
                      <span className="custom-text-muted-crm">
                        Created on : {item.created_at}
                      </span>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="h-100 p-3 d-flex flex-column justify-content-center align-items-center">
                <BsDatabaseFillExclamation size={40} className="text-mute" />
                <span>No data found!</span>
              </div>
            )}
          </Card.Body>
        </>
      ) : (
        <Card.Body className="p-4">
          <DashboardTileLoader column={5} />
        </Card.Body>
      )}
    </Card>
  );
}

ListTile.propTypes = {
  data: PropTypes.any,
  taskCount: PropTypes.any,
  taskCompletedCount: PropTypes.any,
  taskPercentage: PropTypes.any,
  head: PropTypes.bool,
  loading: PropTypes.bool,
};

export default ListTile;
