import { React, useContext } from "react";
import { Nav } from "react-bootstrap";
import ProfileDropdown from "components/navbar/top/ProfileDropdown";
import NotificationDropdown from "./NotificationDropdown";
import TaskTimerSection from "./TaskTimerSection";
import DownloadCenter from "components/download-center/DownloadCenter";
import QuickLinkDropdown from "./QuickLinkDropdown";
import { GetAcodaxPermission } from "module/common/Permissions/AcodaxPermission";
import { AuthWizardContext } from "context/Context";

const TopNavRightSideNavItem = () => {
  const { user } = useContext(AuthWizardContext);

  const quickLinkPermission =
    GetAcodaxPermission("TASK", "create") ||
    GetAcodaxPermission("LEAD", "store") ||
    GetAcodaxPermission("TICKET", "store");

  const showQuickLink = () => {
    if (user?.logged_as === "client") {
      return false;
    } else if (user?.logged_as === "customer") {
      return false;
    }
    return true;
  };

  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center gap-2"
      as="ul"
    >
      <TaskTimerSection />
      {quickLinkPermission && showQuickLink() && (
        <QuickLinkDropdown iconColor={"#5E6E82"} />
      )}
      <NotificationDropdown iconColor={"#5E6E82"} />
      <DownloadCenter iconColor={"#5E6E82"} />
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
