import React from "react";
import { Bar } from "react-chartjs-2";

const BarChart = ({ options, data, height, width }) => {
  return (
    <div
      className="bar-chart-container"
      style={{ height: height, width: width }}
    >
      <Bar data={data} options={options} />
    </div>
  );
};

export default BarChart;
