import { React, useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import ReactEChartsCore from "echarts-for-react/lib/core";
import { getColor } from "helpers/utils";
import * as echarts from "echarts/core";
import DashboardTileLoader from "module/common/Loading/DashboardTileLoader";
import getTaskStatusWithProps from "module/common/helpers/getTaskStatusWithProps";

function ProjectChart({ taskAnalytics = {}, loading = false }) {
  const [taskAnalyticsData, setTaskAnalyticsData] = useState([]);
  const chartRef = useRef(null);

  useEffect(() => {
    if (Object.keys(taskAnalytics)?.length > 0) {
      const newArray = Object.entries(taskAnalytics).map(([key, value]) => {
        const formattedName = key
          .replace(/_/g, " ")
          .replace(/\b\w/g, (c) => c.toUpperCase());
        return {
          key,
          value,
          name: formattedName,
        };
      });
      setTaskAnalyticsData(newArray);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskAnalytics]);

  const getOption = () => ({
    legend: {
      orient: "vertical",
      left: "left",
      top: "40px",
      textStyle: {
        color: getColor("gray-800"),
      },
      itemWidth: 15,
      itemHeight: 12,
    },
    title: [
      {
        text: "Task Analytics",
        left: "center",
        top: "20px",
        textStyle: {
          fontSize: "15px",
          fontWeight: 600,
          color: getColor("gray-800"),
        },
      },
    ],
    series: [
      {
        type: "pie",
        radius: ["40%", "70%"],
        center: ["50%", "55%"],
        avoidLabelOverlap: false,
        left: "130px",
        right: "30px",
        itemStyle: {
          borderRadius: 10,
          borderColor: getColor("gray-100"),
          borderWidth: 2,
        },
        label: {
          show: false,
          position: "center",
        },
        labelLine: {
          show: false,
        },
        data:
          taskAnalyticsData?.length > 0 &&
          taskAnalyticsData.map((item) => ({
            value: Math.round(item.value),
            name: item.name,
            itemStyle: {
              color: getTaskStatusWithProps(item.key),
            },
          })),
      },
    ],
    tooltip: {
      trigger: "item",
      padding: [7, 10],
      backgroundColor: getColor("gray-100"),
      borderColor: getColor("gray-300"),
      textStyle: { color: getColor("dark") },
      borderWidth: 1,
      transitionDuration: 0,
      axisPointer: {
        type: "none",
      },
    },
  });

  const updateDimensions = () => {
    if (window.innerWidth < 530) {
      chartRef?.current?.getEchartsInstance().setOption({
        series: [
          {
            center: ["65%", "55%"],
          },
        ],
      });
    } else
      chartRef?.current?.getEchartsInstance().setOption({
        series: [
          {
            center: ["50%", "55%"],
          },
        ],
      });
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <Card className="card-main rounded-0">
      {!loading ? (
        taskAnalyticsData && taskAnalyticsData.length > 0 ? (
          <div className="ms-3">
            <ReactEChartsCore
              echarts={echarts}
              option={getOption()}
              ref={chartRef}
              style={{ height: "15.4rem" }}
            />
          </div>
        ) : (
          <div
            className="h-100 d-flex align-items-center justify-content-center"
            style={{ minHeight: "15.4rem" }}
          >
            <h2 className="fs-1 m-0">Nothing to display!</h2>
          </div>
        )
      ) : (
        <Card.Body className="pt-5" style={{ height: "15.4rem" }}>
          <DashboardTileLoader column={2} />
        </Card.Body>
      )}
    </Card>
  );
}

export default ProjectChart;
