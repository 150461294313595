import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import axios from "axios";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import ListLoading from "module/common/ListLoading";
import SimpleBar from "simplebar-react";
import ShowMore from "components/common/ShowMore";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { AiFillPlusCircle } from "react-icons/ai";
import { DeleteButton, EditButton } from "module/common/Buttons/AppButtons";
import { DeleteContext, StoreContext } from "context/Context";

function Reminders({ itemId, type }) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const navigate = useNavigate();
  const location = useLocation();
  const { dispatch } = useContext(DeleteContext);
  const pageNumber = queryParams.get("page");
  const { store } = useContext(StoreContext);
  const triggerReminderTab = store?.triggerReminderTab ?? false;
  const handleDelete = (id) => {
    dispatch({
      type: "CONFIG",
      payload: {
        target: id,
        url: "/crm/reminders/" + id,
        title: "Delete reminder",
        message: "Are you sure you want to delete this reminder?",
        onSuccess: () => {
          const dataWithoutDeletedItem = data?.data.filter(
            (account) => account.id !== id
          );
          if (dataWithoutDeletedItem.length < 1 && pageNumber > 1) {
            navigate(-1);
          } else if (dataWithoutDeletedItem.length === 0 && pageNumber < 1) {
            fetchData();
          } else {
            setData((previous) => ({
              ...previous,
              data: dataWithoutDeletedItem,
            }));
            // reset deleteContext
            dispatch({
              type: "RESET",
            });
          }
        },
      },
    });
  };

  const columns = [
    {
      width: "36.5%",
      accessor: "remarks",
      Header: "REMARKS",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2 text-uppercase",
      },
      Cell: (rowData) => {
        const { remarks, id } = rowData.row.original;
        return (
          <ShowMore
            content={remarks ? remarks : "N/A"}
            contentLimit={25}
            uniqueKey={id}
          />
        );
      },
    },
    {
      width: "16.5%",
      accessor: "is_repeating",
      Header: "IS REPEATING",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { is_repeating } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{is_repeating ? "Yes" : "No"}</h5>;
      },
    },
    {
      width: "16.5%",
      accessor: "interval",
      Header: "INTERVAL",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { interval, frequency } = rowData.row.original;
        return (
          <>
            {interval && frequency ? (
              <h5 className="mb-0 fs--1">{interval + " " + frequency}</h5>
            ) : (
              <h5>Only Once</h5>
            )}
          </>
        );
      },
    },
    {
      width: "25.5%",
      accessor: "next_reminder_date_time_formatted",
      Header: "NEXT REMINDER DATE",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { next_reminder_date_time_formatted, id } = rowData.row.original;
        return (
          <ShowMore
            content={
              next_reminder_date_time_formatted
                ? next_reminder_date_time_formatted
                : "N/A"
            }
            contentLimit={35}
            uniqueKey={id}
          />
        );
      },
    },
    {
      width: "5%",
      accessor: "none",
      Header: "",
      headerProps: {
        className: `py-3 custom-table-head-th-action`,
      },
      disableSortBy: true,
      cellProps: {
        className: `custom-table-body-td-action`,
      },
      Cell: (rowData) => {
        const { id } = rowData.row.original;
        return (
          <div className="d-flex align-items-center justify-content-center gap-2">
            <EditButton
              route={`${location.pathname}/reminder?${createSearchParams({
                ...allQueryParams,
                source: type,
                source_id: itemId,
                reminder_id: id,
                view: "table",
              })}`}
            />
            <DeleteButton onClick={() => handleDelete(id)} />
          </div>
        );
      },
    },
  ];

  const fetchData = () => {
    setIsLoading(true);
    axios
      .get(`crm/reminders`, {
        params: {
          entity_type: type,
          entity_id: itemId,
          is_active: 1,
        },
      })
      .then((res) => {
        if (res.data.success) {
          setData(res.data.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerReminderTab]);

  const handleReminder = (id) => {
    if (id) {
      navigate(
        `${location.pathname}/reminder?${createSearchParams({
          ...allQueryParams,
          source: type,
          source_id: id,
          view: "table",
        })}`
      );
    }
  };

  return (
    <>
      {!isLoading ? (
        <Row>
          <Col xs={12}>
            <Card className="d-flex shadow-none">
              <Card.Header
                className={`rounded-0 pb-2 pt-2`}
                style={{
                  backgroundColor: "#5f6f83",
                  color: "#fff",
                }}
              >
                <div className="d-flex align-items-center justify-content-between ">
                  <div className="ms-3">REMINDERS</div>

                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      handleReminder(itemId);
                    }}
                  >
                    <AiFillPlusCircle size={22} className="text-light" />
                  </div>
                </div>
              </Card.Header>

              <Card.Body
                className={`p-0 rounded-0`}
                style={{
                  height: "36.8rem",
                }}
              >
                {data.data?.length > 0 ? (
                  <div className="mt-3">
                    <AdvanceTableWrapper
                      columns={columns}
                      data={data.data}
                      sortable
                    >
                      <SimpleBar>
                        <AdvanceTable
                          table
                          headerClassName="bg-200 text-900 text-nowrap align-middle"
                          rowClassName="align-middle white-space-nowrap"
                          tableprops={{
                            size: "sm",
                            striped: true,
                            className: "fs--1 mb-0  overflow-hidden",
                          }}
                          responsive={false}
                        />
                      </SimpleBar>
                    </AdvanceTableWrapper>
                  </div>
                ) : (
                  <div className="h-100 d-flex align-items-center justify-content-center">
                    No Reminders! 🙂
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <ListLoading />
      )}
    </>
  );
}

Reminders.propTypes = {
  data: PropTypes.any,
};

export default Reminders;
