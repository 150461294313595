import React from "react";
import PropTypes from "prop-types";
import AsyncCreatableSelect from "react-select/async-creatable";
import useAutoCompleteApiCallHandler from "hooks/useAutoCompleteApiCallHandler";

const CreatableSelectCompany = ({
  handleFieldChange,
  name,
  error,
  value,
  style,
  placeholder,
  onFocus,
  keyName,
  clearable = true,
}) => {
  const {
    fetchData,
    setDefaultResult,
    loading,
    defaultOptions,
    setDefaultOptions,
  } = useAutoCompleteApiCallHandler({
    url: "crm/lead-company-autocomplete",
    setParams: (searchKey) => ({ name: searchKey }),
    setOptions: (option) => setOptionData(option),
    onFocus: onFocus,
  });

  const setOptionData = (option) => ({
    label: option.company_name,
    value: keyName ? option[keyName] : option.company_name,
  });

  const handleOptionsChange = (value) => {
    const newValue = {
      label: value,
      value: value,
      isNew: true,
    };
    setDefaultOptions((prevOptions) => [newValue, ...prevOptions]);

    handleFieldChange(newValue, {
      action: "select-option",
      name: name || "company_name",
      option: undefined,
    });
  };

  return (
    <AsyncCreatableSelect
      isClearable={clearable}
      cacheOptions
      allowCreateWhileLoading={false}
      loadOptions={fetchData}
      value={value}
      name={name || "company"}
      onChange={handleFieldChange}
      placeholder={placeholder || ""}
      onCreateOption={handleOptionsChange}
      defaultOptions={defaultOptions}
      onFocus={setDefaultResult}
      isLoading={loading}
      className={error ? "form-control ps-0 py-0 is-invalid" : "ps-0 pe-0 py-0"}
      styles={{
        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
        menu: (provided) => ({
          ...provided,
          zIndex: 9999,
          fontSize: "13px",
          borderRadius: "0px",
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? "#696c96" : "inherit",
          "&:hover": {
            backgroundColor: state.isSelected ? "#192E49" : "#e1e2ea",
          },
        }),
        control: (provided) => ({
          ...provided,
          ...style,
        }),
      }}
    />
  );
};

CreatableSelectCompany.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.object,
  keyName: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  clearable: PropTypes.bool,
};

export default CreatableSelectCompany;
