import React from "react";
import SelectDepartment from "components/form/SelectDepartment";
import SelectDesignation from "components/form/SelectDesignation";
import SelectRole from "components/form/SelectRole";
import SelectLeadStatus from "components/form/SelectLeadStatus";
import SelectUsers from "components/form/SelectUsers";
import SelectLeadSource from "components/form/SelectLeadSource";
import ColumnSelector from "module/common/Filter/ColumnSelector";
import SelectCampaignType from "components/form/SelectCampaignType";
import SelectProduct from "components/form/SelectProduct";
import SelectCampaignStatus from "components/form/SelectCampaignStatus";
import SelectOpportunityType from "components/form/SelectOpportunityType";
import SelectCustomer from "components/form/SelectCustomer";
import SelectOpportunityStage from "components/form/SelectOpportunityStage";
import SelectLead from "components/form/SelectLead";
import SelectCampaign from "components/form/SelectCampaign";
import SelectTodoStatus from "components/form/SelectTodoStatus";
import CommonSelect from "module/common/InputFields/Common/CommonSelect";
import SelectCurrency from "components/form/SelectCurrency";
import SelectProject from "components/form/SelectProject";
import SelectProjectStatus from "components/form/SelectProjectStatus";
import MultiSelectCheckBoxSprintStatus from "components/form/MultiSelectCheckBoxSprintStatus";
import SelectSprint from "components/form/SelectSprint";
import MutiSelectCheckBoxTaskStatus from "components/form/MutiSelectCheckBoxTaskStatus";
import SelectTicketType from "components/form/SelectTicketType";
import SelectTicketOrigin from "components/form/SelectTicketOrigin";
import SelectLabelsSubjectType from "components/form/SelectLabelsSubjectType";
import MultiSelectUsers from "components/form/MultiSelectUsers";
import SelectTaskStatus from "components/form/SelectTaskStatus";
import SelectSprintStatus from "components/form/SelectSprintStatus";
import SelectTeams from "components/form/SelectTeams";
import SelectTicketStatus from "components/form/SelectTicketStatus";
import SelectPriority from "components/form/SelectPriority";
import MultiSelectCheckBoxProduct from "components/form/MultiSelectCheckBoxProduct";

const componentMap = {
  common_select: CommonSelect,
  designation: SelectDesignation,
  department: SelectDepartment,
  role: SelectRole,
  lead_status: SelectLeadStatus,
  lead_source: SelectLeadSource,
  date_range_for: ColumnSelector,
  camapign_type: SelectCampaignType,
  product: SelectProduct,
  users: SelectUsers,
  campaign_status: SelectCampaignStatus,
  opportunity_type: SelectOpportunityType,
  account: SelectCustomer,
  opportunity_stage: SelectOpportunityStage,
  lead: SelectLead,
  campaign: SelectCampaign,
  todo_status: SelectTodoStatus,
  currency: SelectCurrency,
  project: SelectProject,
  project_status: SelectProjectStatus,
  sprint_status_multi: MultiSelectCheckBoxSprintStatus,
  sprint: SelectSprint,
  task_status_multi: MutiSelectCheckBoxTaskStatus,
  ticket_type: SelectTicketType,
  ticket_origin: SelectTicketOrigin,
  labels_type: SelectLabelsSubjectType,
  user_multi: MultiSelectUsers,
  task_status: SelectTaskStatus,
  sprint_status: SelectSprintStatus,
  teams: SelectTeams,
  ticket_status: SelectTicketStatus,
  priority: SelectPriority,
  product_multi_check: MultiSelectCheckBoxProduct,
};

const getCustomComponent = (
  componentName,
  name,
  value,
  handleFieldChange,
  options = [],
  isClearable,
  fetchUrl
) => {
  const Component = componentMap[componentName?.replace("_id", "")];
  return Component ? (
    <Component
      name={`${name ?? componentName}`}
      value={value}
      handleFieldChange={handleFieldChange}
      options={options}
      isClearable={isClearable}
      fetchUrl={fetchUrl}
    />
  ) : null;
};

export default getCustomComponent;
