import axios from "axios";
import { AuthWizardContext } from "context/Context";
import { showToast } from "module/common/Toast/toast";
import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { BiSolidAlarmSnooze } from "react-icons/bi";
import { TbBellRingingFilled } from "react-icons/tb";

const Reminder = () => {
  const { user } = useContext(AuthWizardContext);
  const [reminders, setReminders] = useState([]);
  const [loadingSnooze, setLoadingSnooze] = useState(false);

  useEffect(() => {
    const currentURL = window.location.href;
    const urlObject = new URL(currentURL);
    const domain = urlObject?.hostname;
    window.Echo.channel(`user_notification_${domain}_${user?.id}`).listen(
      "ReminderNotificationBroadcast",
      (notificationItem) => {
        if (notificationItem && typeof notificationItem === "object") {
          setReminders((prev) => [...prev, { ...notificationItem }]);
        }
      }
    );

    return () => {
      window.Echo.channel(
        `user_notification_${domain}_${user?.id}`
      ).stopListening("ReminderNotificationBroadcast");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  const handleClose = (id) => {
    setReminders((prev) => prev.filter((item) => item?.reminder_id !== id));
  };

  const handleSnooze = async (id) => {
    if (id) {
      setLoadingSnooze(true);
      try {
        const response = await axios({
          method: "put",
          url: `crm/reminder/snooze`,
          params: {
            id: id,
            snooze: 10,
          },
        });
        if (response?.data?.success === true) {
          showToast(response?.data?.message, "success");
          handleClose(id);
          setLoadingSnooze(false);
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      {reminders?.length > 0 &&
        reminders?.map((item, index) => (
          <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            show={reminders?.length > 0}
            key={index}
          >
            <Modal.Header className="border-bottom">
              <Modal.Title
                id="contained-modal-title-vcenter"
                className="d-flex align-items-center justify-content-center w-100"
              >
                <TbBellRingingFilled
                  size={30}
                  color="#fff"
                  className="border border-warning rounded-circle p-1 bg-warning"
                />
                <span className="ms-2 fs-1">Reminder</span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex flex-column align-items-center gap-2">
              <span className="fs-0">{item?.title}</span>
              <span>{item?.description}</span>
            </Modal.Body>
            <Modal.Footer className="border-0 mt-2">
              <Button
                size="sm"
                variant="success"
                onClick={() => handleSnooze(item?.reminder_id)}
                disabled={loadingSnooze}
              >
                <div className="d-flex align-items-center gap-2">
                  <BiSolidAlarmSnooze size={20} />
                  <span>{loadingSnooze ? "Snoozing..." : "Snooze"}</span>
                </div>
              </Button>
              <Button
                size="sm"
                variant="danger"
                onClick={() => handleClose(item?.reminder_id)}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        ))}
    </>
  );
};

export default Reminder;
