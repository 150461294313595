import { React, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { FcClearFilters } from "react-icons/fc";
import { FaFilter } from "react-icons/fa";

import { showToast } from "module/common/Toast/toast";
import removeEmptyFields from "helpers/removeEmptyFields";
import getInitializedFilters from "./getInitializedFilters";
import DateRangePicker from "module/common/Filter/DateRangePicker";
import removeRefData from "helpers/removeRefData";
import useAxisproTranslate from "hooks/useAxisproTranslate";
const CommonFilter = ({
  filterKeys,
  date_range_for,
  module,
  additionalResetParams,
  buttonColumnWidth = 2,
  inputColumnWidth = 2,
  hasZero = false,
  dateRangeProperClass = "",
}) => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const [filterFields, setFilterFields] = useState(filterKeys);
  const allQueryParams = Object.fromEntries([...queryParams]);
  const Translate = useAxisproTranslate();
  const filterItems = JSON.stringify(
    typeof allQueryParams === "object" ? allQueryParams : {}
  );
  const intializedfilter = getInitializedFilters(
    filterFields,
    setFilterFields,
    module
  );
  const filteredIntializedfilter = intializedfilter?.filter(
    (item) => !item?.inactive
  );

  function getFilterFieldsWithoutDefault(filterFields, unwantedKeys) {
    // Implement your logic to filter unwanted keys from filterFields (excluding unwantedKeys)
    const filteredFields = Object.fromEntries(
      Object.entries(filterFields).filter(
        ([key]) => !unwantedKeys.includes(key)
      )
    );
    return filteredFields;
  }

  //setting the default filters for every module //? page, view, is_default are common
  const unwantedKeys = {
    tickets: ["status"],
  };

  const { page, view, tab, is_default, ...filterFieldsWithoutPageNumber } =
    getFilterFieldsWithoutDefault(
      removeEmptyFields(removeRefData(filterFields), "", hasZero),
      unwantedKeys[module] || []
    );

  const isClearButtonNeeded =
    Object.keys(filterFieldsWithoutPageNumber)?.length > 0;

  const handleFilterApply = () => {
    if (
      (filterFields.date_range_for &&
        !date_range_for &&
        !filterFields.date_range) ||
      (!filterFields.date_range_for &&
        !date_range_for &&
        filterFields.date_range)
    ) {
      const message = filterFields.date_range
        ? "Please Select Date Field"
        : "Please Select Date Range";
      showToast(message, "error");
      return;
    }

    //deep copy
    let filterFieldsCopy = { ...filterFields };
    Object.keys(filterFieldsCopy).forEach((key) => {
      const value = filterFieldsCopy[key];
      if (key.endsWith("_ref") && typeof value === "object") {
        const cleanedValue = removeEmptyFields(value);
        filterFieldsCopy[key] =
          Object.keys(cleanedValue).length === 0 ? "" : JSON.stringify(value);
      } else if (Array.isArray(value)) {
        filterFieldsCopy[key] = value.join("/");
      }
    });
    const cleanerFilterObject = removeEmptyFields(
      filterFieldsCopy,
      "",
      hasZero
    );

    navigate(
      `?${createSearchParams(
        removeEmptyFields({
          ...cleanerFilterObject,
          page: 1,
        })
      )}`
    );
  };

  useEffect(() => {
    let filterFields = JSON.parse(filterItems);
    Object.keys(filterFields).forEach((key) => {
      if (key.endsWith("_ref")) {
        filterFields[key] = JSON.parse(filterFields[key]);
      }
    });

    setFilterFields((previous) => ({
      ...previous,
      ...filterFields,
    }));
    return () => {
      setFilterFields(filterKeys);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterItems]);

  const handleResetFilter = () => {
    setFilterFields({ ...filterKeys, ...additionalResetParams });
    let newFilters = { page, ...filterKeys, ...additionalResetParams };

    Object.keys(newFilters).forEach((key) => {
      const value = newFilters[key];
      if (key.endsWith("_ref") && typeof value === "object") {
        const cleanedValue = removeEmptyFields(value);
        newFilters[key] =
          Object.keys(cleanedValue).length === 0 ? "" : JSON.stringify(value);
      } else if (Array.isArray(value)) {
        newFilters[key] = value.join("/");
      }
    });
    const cleanerFilterObject = removeEmptyFields(newFilters, "", hasZero);

    navigate(
      `?${createSearchParams(
        removeEmptyFields({
          ...cleanerFilterObject,
          page: 1,
        })
      )}`
    );
  };

  return (
    <div className="">
      <Form
        className={"border-bottom border-top"}
        style={{ backgroundColor: "#F8F8F8" }}
      >
        <Row className="py-2 gy-2 m-0 mb-2">
          {filteredIntializedfilter?.map((item, index) => (
            <Col
              md={6}
              lg={item?.columnWidth || inputColumnWidth}
              xxl={item?.columnWidth || inputColumnWidth}
              key={index}
            >
              <Row>
                <Form.Group as={Col}>
                  <Form.Label>{item.label}</Form.Label>
                  <Col>
                    {item.type === "text" || item.type === "number" ? (
                      <Form.Control
                        type={item.type}
                        name={item.name}
                        onChange={item.onChange}
                        value={item.value}
                      />
                    ) : item.type === "switch" ? (
                      <Form.Check
                        type="switch"
                        className="custom-common-switch"
                        id="custom-switch"
                        name={item.name}
                        onChange={item.onChange}
                        checked={item.value ? parseInt(item.value) : 0}
                      />
                    ) : item.type === "custom-select" ? (
                      item.component
                    ) : item.type === "select" ? (
                      <Form.Select
                        name={item.name}
                        onChange={item.onChange}
                        value={item.value}
                      >
                        {item.options.map((option, key) => (
                          <option value={option.value} key={key}>
                            {option.label}
                          </option>
                        ))}
                      </Form.Select>
                    ) : item.type === "date-range" ? (
                      <DateRangePicker
                        formData={filterFields}
                        className={"custom-date-range-picker"}
                        setFormData={setFilterFields}
                        dateRange_form={{
                          to: "to_date",
                          from: "from_date",
                          range: "date_range",
                          month: "month",
                        }}
                        popperClassName={dateRangeProperClass}
                      />
                    ) : item.type === "check-box" ? (
                      <Form.Check
                        className="custom-common-check"
                        id="check-box"
                        name={item.name}
                        onChange={item.onChange}
                        checked={item.value ? parseInt(item.value) : 0}
                      />
                    ) : (
                      ""
                    )}
                  </Col>
                </Form.Group>
              </Row>
            </Col>
          ))}
          <Col
            md={buttonColumnWidth}
            lg={2}
            xxl={buttonColumnWidth}
            className="flex-grow-1"
          >
            <Form.Group
              as={Col}
              md={2}
              className="d-flex justify-content-end align-items-end h-100 w-100"
            >
              <div className="d-flex align-items-end gap-2">
                <Button
                  type="button"
                  variant="success"
                  size="sm"
                  className="ms-0 me-1 border-0"
                  onClick={handleFilterApply}
                  style={{
                    height: "25px",
                    width: " 70px",
                    fontSize: "10px",
                    backgroundColor: "#F5803E",
                  }}
                  title="Apply Added Filters"
                >
                  <FaFilter className="text-light" /> {Translate("Apply")}
                </Button>
                {isClearButtonNeeded && (
                  <Button
                    type="button"
                    variant="falcon-default"
                    size="sm"
                    className="ms-0 me-1"
                    onClick={handleResetFilter}
                    style={{
                      height: "25px",
                      fontSize: "10px",
                    }}
                    title="Clear All Filters"
                  >
                    <FcClearFilters size={14} className="text-light" />
                  </Button>
                )}
              </div>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default CommonFilter;
