import React, { useEffect, useState } from 'react'
import { Row } from 'react-bootstrap'

function ActivityLogLoader({ height = "30rem", items = 10 }) {
    const [loadingIndex, setLoadingIndex] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            setLoadingIndex((prev) => prev + 1);
        }, 300);
        return () => clearInterval(interval);
    }, []);

    return (
        <div>
            <Row>
                <div
                    className="widget-wrap "
                    style={{ maxHeight: height, overflow: "hidden", overflowY: "scroll" }}
                >
                    {[...Array(items).keys()].map((index) => (
                        <div className={`activity-container-div-skelton ${loadingIndex > index ? 'visible' : ''}`} key={index}>
                            <div className="activity-container-wrapper-skelton d-flex justify-content-between align-item-center">
                                <div className='w-100'>
                                    <span className="d-block">
                                        <p className="fs--1 m-0 mb-1 text-uppercase gray-600 fw-bold">
                                            <span style={{ height: "1rem", width: "50%", display: "inline-block", backgroundColor: "#D0D0D0", borderRadius: "3px" }}>
                                                &nbsp;
                                            </span>
                                        </p>
                                    </span>
                                    <span className="d-block pt-1">
                                        <p className="d-inline ps-2">
                                            <span style={{ height: "1rem", width: "80%", display: "inline-block", backgroundColor: "#D0D0D0", borderRadius: "3px" }}>
                                                &nbsp;
                                            </span>
                                        </p>
                                    </span>
                                    <span className="d-block pt-2">
                                        <span className="d-block">
                                            <span className="d-inline ps-2">
                                                <span style={{ height: "1rem", width: "12rem", display: "inline-block" }}>
                                                    &nbsp;
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </div>
                                <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                                    <span className="custom-text-muted">
                                        <span style={{ width: "6rem", display: "inline-block" }}>
                                            &nbsp;
                                        </span>
                                    </span>{" "}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </Row>
        </div>
    )
}

export default ActivityLogLoader