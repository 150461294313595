import React from "react";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts/core";
const GraphChart = ({ options }) => {
  //sample data
  //   const options = {
  //     title: {
  //       text: "Project Overview",
  //       subtext: "(In Hours)",
  //       left: "left",
  //     },
  //     color: ["#ff0000", "#00ff00", "#0000ff"],
  //     tooltip: {
  //       trigger: "axis",
  //     },
  //     xAxis: {
  //       type: "category",
  //       data: ["Mar", "Apr", "May", "Jun", "Jul", "Aug"],
  //     },
  //     yAxis: {
  //       type: "value",
  //     },
  //     series: [
  //       {
  //         name: "Project 1",
  //         type: "line",
  //         data: [30, 40, 35, 50, 49, 60],
  //       },
  //       {
  //         name: "Project 2",
  //         type: "line",
  //         data: [60, 70, 75, 50, 80, 85],
  //       },
  //       {
  //         name: "Project 3",
  //         type: "line",
  //         data: [90, 85, 70, 80, 75, 68],
  //       },
  //     ],
  //   };

  return (
    <div id="chart">
      <ReactEcharts option={options} echarts={echarts} />
    </div>
  );
};

export default GraphChart;
