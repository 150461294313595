import React from "react";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { BiRefresh } from "react-icons/bi";

const RefreshButton = ({
  size,
  variant,
  className,
  onClick,
  iconSize = 18,
  loading,
  ...rest
}) => {
  return (
    <Button
      size={size ?? "sm"}
      variant={variant ?? "primary"}
      className={`p-1 pe-2 ${className} fs--0`}
      onClick={onClick}
      disabled={loading}
      {...rest}
    >
      <BiRefresh size={iconSize} style={{ marginRight: 2 }} />
      Refresh
    </Button>
  );
};

RefreshButton.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
};

export default RefreshButton;
