import { React, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Col, Form, Row, Modal } from "react-bootstrap";
import { leadsFormKeys } from "helpers/formKeys";
import { SaveButton } from "module/common/Buttons/CommonButton";
import { showToast } from "module/common/Toast/toast";
import AppDatePicker from "components/app-date-picker/AppDatePicker";
import BackButton from "components/common/BackButton";
import SelectUsers from "components/form/SelectUsers";
import removeRefData from "helpers/removeRefData";
import { CustomBackButton } from "module/common/Buttons/AppButtons";
import { SiGoogleadmob } from "react-icons/si";
import { AuthWizardContext, StoreContext } from "context/Context";
import CreatableSelectCompany from "components/form/CreatableSelectCompany";
import SelectIndustry from "components/form/SelectIndustry";
import SelectLeadSource from "components/form/SelectLeadSource";
import PhoneNumberInput from "components/form/PhoneNumberInput";
import removeEmptyFields from "helpers/removeEmptyFields";
import { apiCall } from "helpers/apiCalls";
import SelectPriority from "components/form/SelectPriority";

function QuickLeadForm({ show, handleCloseForm, openFrom }) {
  const { user } = useContext(AuthWizardContext);
  const { itemId } = useParams();
  const [newCompany, setNewCompany] = useState(false);
  const [formError, setFormError] = useState({});
  const [formData, setFormData] = useState({
    ...leadsFormKeys,
    lead_owner: user?.id,
    lead_owner_ref: { label: user?.name, value: user?.id },
    lead_assignee: user?.id,
    lead_assignee_ref: { label: user?.name, value: user?.id },
    lead_priority: "Warm",
    lead_priority_ref: { label: "Warm", value: "Warm" },
  });
  const [loadingAutoComplete, setLoadingAutoComplete] = useState(false);
  const [clearAutoComplete, setClearAutoComplete] = useState(false);
  const [onSave, setOnSave] = useState(false);
  const [selectedType, setSelectedType] = useState("company");
  const { store, addIitemToStore } = useContext(StoreContext);
  const triggerFromQuickLinkQuickLead =
    store?.triggerFromQuickLinkQuickLead ?? false;

  const handleFieldChange = (e, action, type) => {
    let newFormData;
    let value;
    let newContactFormData;

    const updateContactInfo = (key, contactKey, value) => ({
      ...formData,
      [key]: value,
      contacts: [{ ...formData?.contacts[0], [contactKey]: value }],
    });

    if (!action) {
      if (e.target.name === "status") {
        value = e.target.checked ? "Active" : "Inactive";
      } else {
        value = e.target.value;
      }

      if (type === "contact") {
        newFormData = {
          ...formData,
          contacts: [{ ...formData?.contacts[0], [e.target.name]: value }],
        };
      } else {
        newFormData = {
          ...formData,
          [e.target.name]: value,
        };

        // autofill email and phone number to contacts
        if (e.target.name === "email" || e.target.name === "phone_no") {
          const contactKey =
            e.target.name === "email" ? "contact_email" : "contact_phone_no";
          newFormData = updateContactInfo(e.target.name, contactKey, value);
        }

        // autofill first name  to contacts
        if (e.target.name === "title" && formData?.is_company === 0) {
          newFormData = updateContactInfo(
            e.target.name,
            "contact_first_name",
            value
          );
        }
      }
    } else {
      if (action.action === "select-option") {
        value = e.code ? e.code : e.value;
        if (action.name === "company_name" && formData?.is_company === 1) {
          newContactFormData = updateContactInfo(
            action.name,
            "contact_first_name",
            value
          );
          newFormData = {
            ...newContactFormData,
            [action.name]: value,
            [action.name + "_ref"]: e,
          };
          e.isNew ? setNewCompany(true) : setNewCompany(false);
          setClearAutoComplete(false);
        } else if (action.name === "product_id") {
          const labelArray = e.map((item) => item.value);
          newFormData = {
            ...formData,
            [action.name]: labelArray,
            [action.name + "_ref"]: e,
          };
        } else {
          newFormData = {
            ...formData,
            [action.name]: value,
            [action.name + "_ref"]: e,
          };
        }
      } else if (action.action === "create-option") {
        value = e.value;
        if (action.name === "company_name" && formData?.is_company === 1) {
          newContactFormData = updateContactInfo(
            action.name,
            "contact_first_name",
            value
          );
        }
        newFormData = {
          ...newContactFormData,
          [action.name]: value,
          [action.name + "_ref"]: e,
        };
      } else if (action.action === "clear") {
        if (action.name === "product_id") {
          newFormData = {
            ...formData,
            [action.name]: [],
            [action.name + "_ref"]: [],
          };
        } else {
          newFormData = {
            ...formData,
            [action.name]: "",
            [action.name + "_ref"]: {
              label: "",
              value: "",
            },
          };
        }
      } else if (action.action === "remove-value") {
        value = e.map((item) => item.value);
        newFormData = {
          ...formData,
          [action.name]: value,
          [action.name + "_ref"]: e,
        };
      }
    }
    setFormData(newFormData);
  };

  const getLeadSource = async () => {
    const data = await apiCall({
      url: `crm/lead-sources-autocomplete`,
    });
    const emailSource = data.find((el) => el.source_name === "Email");

    if (emailSource) {
      setFormData((prev) => ({
        ...prev,
        lead_source: emailSource.id,
        lead_source_ref: {
          label: emailSource.source_name,
          value: emailSource.id,
        },
      }));
    }
  };

  useEffect(() => {
    if (show) {
      getLeadSource();
    }
    return () => {
      setOnSave(false);
      setFormData(leadsFormKeys);
      setFormError({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const handleSubmit = () => {
    if (onSave) return;
    setFormError({});
    setOnSave(true);

    let formDataWithoutRefkeys = removeEmptyFields(removeRefData(formData));
    axios({
      method: "post",
      url: `crm/lead`,
      headers: {
        Accept: "application/json",
      },
      data: formDataWithoutRefkeys,
    })
      .then((response) => {
        if (response?.data?.success === true) {
          showToast(response?.data?.message, "success");
          setFormData(leadsFormKeys);
          if (openFrom === "quick_link") {
            handleCloseForm();
            addIitemToStore(
              "triggerFromQuickLinkQuickLead",
              !triggerFromQuickLinkQuickLead
            );
          }
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          const validation_error = error.response?.data?.data?.errors
            ? error.response?.data?.data?.errors
            : null;
          validation_error && setFormError({ ...validation_error });
          showToast(error?.response?.data?.message, "error");
        } else {
          console.log(error, "error");
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      });
  };
  const handleCancel = () => {
    handleCloseForm();
  };

  const setAutoCompleteFormData = (address, website, phoneNumber) => {
    setFormData((prevData) => ({
      ...prevData,
      address: address ? address : "",
      website: website ? website : "",
      phone_no: phoneNumber ? phoneNumber : "",
      contacts: prevData?.contacts.map((contact, index) =>
        index === 0
          ? {
              ...contact,
              contact_address: address ? address : "",
              contact_website: website ? website : "",
              contact_phone_no: phoneNumber ? phoneNumber : "",
            }
          : contact
      ),
    }));
  };

  const handleAutoComplete = () => {
    setFormError({});

    const axiosConfig = !newCompany
      ? {
          method: "get",
          url: "crm/lead/company/autofill",
          params: {
            company: formData?.company_name,
          },
        }
      : {
          method: "post",
          url: `${process.env.REACT_APP_ACODAX_AI_API_HOST}find-place`,
          headers: {
            Accept: "application/json",
            "X-Acodax-Api-Key": process.env.REACT_APP_ACODAX_AI_API_KEY,
            "Content-Type": "application/json",
            settingBaseUrl: true,
            "ngrok-skip-browser-warning": true,
            withCredentials: true,
          },
          data: {
            q: formData?.company_name,
            gl: "ae",
          },
        };

    axios(axiosConfig)
      .then((response) => {
        if (response?.data) {
          const { address, website, phone_no } = response?.data?.data
            ? response?.data?.data
            : response?.data;
          const { phoneNumber } = response?.data;
          setAutoCompleteFormData(
            address,
            website,
            phone_no ? phone_no : phoneNumber
          );
          setLoadingAutoComplete(false);
          setClearAutoComplete(true);
        }
      })
      .catch((error) => {
        if (error?.response?.data && error?.response?.data?.message) {
          const validation_error =
            error.response?.data &&
            error.response?.data?.data &&
            error.response?.data?.data?.errors
              ? error.response?.data?.data?.errors
              : null;
          validation_error && setFormError({ ...validation_error });
          showToast(error?.response?.data?.message, "error");
        } else {
          console.log(error, "error");
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setLoadingAutoComplete(false);
        setClearAutoComplete(false);
      });
  };

  const handleClearAutoComplete = () => {
    setFormData((prevData) => ({
      ...prevData,
      address: "",
      website: "",
      phone_no: "",
      contacts: prevData?.contacts.map((contact, index) =>
        index === 0
          ? {
              ...contact,
              contact_address: "",
              contact_website: "",
              contact_phone_no: "",
            }
          : contact
      ),
    }));
    setClearAutoComplete(false);
  };

  const debounceHandleAutoComplete = ({ delay = 2000 }) => {
    let debounceTimer;
    setLoadingAutoComplete(true);
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      handleAutoComplete();
    }, delay);
  };

  useEffect(() => {
    if (!newCompany && formData?.company_name !== "") {
      debounceHandleAutoComplete({ delay: 1000 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.company_name, newCompany]);

  const handleTypeChange = (event) => {
    if (event.target.value === "company") {
      formData.is_company = 1;
      formData.contacts[0].contact_first_name = "";
    } else {
      formData.is_company = 0;
      formData.company_name = "";
      formData.industry = "";
      formData.annual_revenue = "";
      formData.no_of_employees = "";
      if (formData.title) {
        formData.contacts[0].contact_first_name = formData.title;
      } else {
        formData.contacts[0].contact_first_name = "";
      }
    }
    setSelectedType(event.target.value);
  };

  return (
    <>
      <Modal show={show} size="lg" className="no-border-radius-modal">
        <Modal.Header>
          <Modal.Title className="w-100 d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <SiGoogleadmob size={18} className="me-2 text-dark" />
              <span className="custom-module-header" style={{ fontSize: 16 }}>
                QUICK LEAD
              </span>
            </div>
            <div className="d-flex justify-content-end">
              <CustomBackButton
                variant="falcon-default"
                title="Close"
                onClick={handleCancel}
              />
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-1">
          <Form>
            <div className="d-flex">
              <Form.Check
                type="radio"
                label="Company"
                name="type"
                className=" me-3"
                value="company"
                checked={selectedType === "company"}
                onChange={handleTypeChange}
              />

              <Form.Check
                type="radio"
                label="Individual"
                name="type"
                value="individual"
                checked={selectedType === "individual"}
                onChange={handleTypeChange}
              />
            </div>
            <Row>
              <Col md={6}>
                {selectedType === "company" && (
                  <Form.Group as={Col} xs={12}>
                    <div className="d-flex align-items-center justify-content-between">
                      <Form.Label className="require-data">Company</Form.Label>
                      {((newCompany && formData?.company_name !== "") ||
                        loadingAutoComplete) && (
                        <div
                          className="text-primary"
                          style={{
                            cursor: loadingAutoComplete
                              ? "progress"
                              : "pointer",
                            textDecoration: "underline",
                          }}
                          onClick={
                            loadingAutoComplete
                              ? null
                              : clearAutoComplete
                              ? handleClearAutoComplete
                              : debounceHandleAutoComplete
                          }
                        >
                          <span>
                            {loadingAutoComplete
                              ? "Loading..."
                              : clearAutoComplete
                              ? "Clear"
                              : "Auto Complete"}
                          </span>
                        </div>
                      )}
                    </div>
                    <Col>
                      <CreatableSelectCompany
                        name="company_name"
                        value={formData?.company_name_ref}
                        handleFieldChange={handleFieldChange}
                        error={formError?.company_name}
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        className="d-flex p-0 m-0"
                      >
                        <span className="ms-auto">
                          {formError?.company_name}
                        </span>
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                )}
                <Form.Group
                  as={Col}
                  xs={12}
                  className={selectedType === "company" ? "mt-2" : ""}
                >
                  <Form.Label className="require-data">Job Title</Form.Label>
                  <Col>
                    <Form.Control
                      type="text"
                      name="title"
                      onChange={handleFieldChange}
                      value={formData?.title}
                      isInvalid={!!formError?.title}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className="d-flex p-0 m-0"
                    >
                      <span className="ms-auto">{formError?.title}</span>
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                {selectedType === "company" && (
                  <Form.Group as={Col} xs={12} className={"mt-2"}>
                    <Form.Label className="require-data">Industry</Form.Label>
                    <Col>
                      <SelectIndustry
                        name="industry"
                        value={formData?.industry_ref}
                        handleFieldChange={handleFieldChange}
                        error={formError?.industry}
                      />
                    </Col>
                    <Form.Control.Feedback
                      type="invalid"
                      className="d-flex p-0 m-0"
                    >
                      <span className="ms-auto">{formError?.industry}</span>
                    </Form.Control.Feedback>
                  </Form.Group>
                )}
                <Form.Group as={Col} xs={12} className="mt-2">
                  <Form.Label
                    className={`${!formData.phone_no ? "require-data" : ""}`}
                  >
                    Email
                  </Form.Label>
                  <Col>
                    <Form.Control
                      type="email"
                      name="email"
                      onChange={handleFieldChange}
                      value={formData.email}
                      isInvalid={!!formError.email}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className="d-flex p-0 m-0"
                    >
                      <span className="ms-auto">{formError.email}</span>
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Col} xs={12} className="mt-2">
                  <Form.Label
                    className={`${!formData.email ? "require-data" : ""}`}
                  >
                    Phone Number
                  </Form.Label>
                  <Col>
                    <PhoneNumberInput
                      name="phone_no"
                      onChange={handleFieldChange}
                      value={formData.phone_no}
                      isInvalid={!!formError.phone_no}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className="d-flex p-0 m-0"
                    >
                      <span className="ms-auto">{formError.phone_no}</span>
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                {selectedType === "individual" && (
                  <Form.Group as={Col} xs={12} className="mt-2">
                    <Form.Label className="require-data">
                      Generated On
                    </Form.Label>
                    <Col>
                      <AppDatePicker
                        name="generated_on"
                        yearPlaceholder="yyyy"
                        monthPlaceholder="mm"
                        dayPlaceholder="dd"
                        onChange={handleFieldChange}
                        value={formData?.generated_on}
                        isInvalid={!!formError?.generated_on}
                        showDefaultDate={true}
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        className="d-flex p-0 m-0"
                      >
                        <span className="ms-auto">
                          {formError?.generated_on}
                        </span>
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                )}
              </Col>
              <Col md={6}>
                <Form.Group as={Col} xs={12}>
                  <Form.Label className="require-data">Lead Owner</Form.Label>
                  <Col>
                    <SelectUsers
                      value={formData?.lead_owner_ref}
                      name="lead_owner"
                      handleFieldChange={handleFieldChange}
                      error={formError?.lead_owner}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className="d-flex p-0 m-0"
                    >
                      <span className="ms-auto">{formError?.lead_owner}</span>
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Col} xs={12} md={12} className="mt-2">
                  <Form.Label className="require-data">Lead Source</Form.Label>
                  <Col>
                    <SelectLeadSource
                      name="lead_source"
                      value={formData?.lead_source_ref}
                      handleFieldChange={handleFieldChange}
                      error={formError?.lead_source}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className="d-flex p-0 m-0"
                    >
                      <span className="ms-auto">{formError?.lead_source}</span>
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Col} xs={12} md={12} className="mt-2">
                  <Form.Label className="require-data">
                    Lead Priority
                  </Form.Label>
                  <Col>
                    <SelectPriority
                      name="lead_priority"
                      value={formData?.lead_priority_ref}
                      handleFieldChange={handleFieldChange}
                      error={formError?.lead_priority}
                      fetchUrl="crm/lead-priorities"
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className="d-flex p-0 m-0"
                    >
                      <span className="ms-auto">
                        {formError?.lead_priority}
                      </span>
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                {selectedType === "company" && (
                  <Form.Group as={Col} xs={12} className="mt-2">
                    <Form.Label className="require-data">
                      Generated On
                    </Form.Label>
                    <Col>
                      <AppDatePicker
                        name="generated_on"
                        yearPlaceholder="yyyy"
                        monthPlaceholder="mm"
                        dayPlaceholder="dd"
                        onChange={handleFieldChange}
                        value={formData?.generated_on}
                        isInvalid={!!formError?.generated_on}
                        showDefaultDate={true}
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        className="d-flex p-0 m-0"
                      >
                        <span className="ms-auto">
                          {formError?.generated_on}
                        </span>
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                )}
              </Col>
            </Row>
            <Form.Group className="d-flex justify-content-end gap-1 mt-3">
              <SaveButton
                handleSubmit={handleSubmit}
                onSave={onSave}
                id={itemId}
              />
              {!onSave && (
                <BackButton
                  onClick={handleCancel}
                  variant={"danger"}
                  size={"sm"}
                >
                  Cancel
                </BackButton>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default QuickLeadForm;
