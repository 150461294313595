import React from "react";
import { Card } from "react-bootstrap";
import PropTypes from "prop-types";
import PieChart from "module/common/Charts/PieChart";
import DashboardTileLoader from "module/common/Loading/DashboardTileLoader";

function AssigneeOverview({ dashboardData, loading }) {
  const optionsPie = {
    responsive: true,
    color: ["#082765", "#A8A8A8"],
    title: {
      left: "center",
      show: false,
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c} ({d}%)",
    },
    legend: {
      orient: "horizontal",
      left: "center",
      top: "top",
      data: ["Assigned", "Unassigned"],
    },
    series: [
      {
        name: "Pie Chart",
        type: "pie",
        radius: "70%",
        center: ["50%", "60%"],
        data: [
          { value: dashboardData.total_assigned_task, name: "Assigned" },
          { value: dashboardData.total_unassigned_task, name: "Unassigned" },
        ],
        label: {
          show: false,
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  return (
    <Card className="card-main rounded-0" style={{ height: "358px" }}>
      {!loading ? (
        <Card.Body>
          <Card.Title className="d-flex justify-content-start fw-bold">
            Assignee Overview
          </Card.Title>
          <div className="d-flex justify-content-center mt-3">
            <PieChart options={optionsPie} height={250} width={300} />
          </div>
        </Card.Body>
      ) : (
        <Card.Body className="p-5 ps-4 pe-4">
          <DashboardTileLoader column={3} />
        </Card.Body>
      )}
    </Card>
  );
}

AssigneeOverview.propTypes = {
  dashboardData: PropTypes.any,
  loading: PropTypes.bool,
};

export default AssigneeOverview;
