import PropTypes from "prop-types";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AddMemberForm from "../ProjectForm/AddMemberForm";
import { showToast } from "module/common/Toast/toast";
import axios from "axios";
import { FaUserPlus } from "react-icons/fa";
import paramsArrayBuilder from "helpers/paramsArrayBuilder";
import removeRefData from "helpers/removeRefData";
const { Modal } = require("react-bootstrap");

const AddMembersModal = ({
  formData,
  setFormData,
  members,
  setMembers,
  getData,
}) => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const [formError, setFormError] = useState({});
  const [onSave, setOnSave] = useState(false);
  const formStatus = queryParams.get("add_members");

  const handleFieldChange = (e, action) => {
    let newFormData;
    let value;

    if (action) {
      if (action.action === "select-option") {
        const memberArray = e.map((item) => ({ member_id: item.value }));
        value = memberArray;
      }
      if (action.action === "clear") {
        value = [];
      }
    }

    newFormData = {
      ...formData,
      [action.name]: value,
      [action.name + "_ref"]: e,
    };
    setFormData(newFormData);
  };

  // Line number 53 to 64 is used to create an array with already included members and newly added members - No duplicate entry - so i already added memeber is selected which will not pass to backend - Need to fix that in auto complete

  const handleSubmit = (e) => {
    e.preventDefault();
    if (onSave) return;
    setFormError({});
    setOnSave(true);
    const includedMembersId = members?.map(({ user_id }) => ({
      member_id: user_id,
    }));
    includedMembersId?.forEach(({ member_id }) => {
      const exists = formData?.members?.some(
        (obj) => obj?.member_id === member_id
      );
      if (!exists) {
        formData?.members?.push({ member_id });
      }
    });
    formData.members = [...formData?.members];
    let formDataWithoutRefkeys = paramsArrayBuilder(removeRefData(formData));
    axios({
      method: "post",
      url: `crm/project/add-project-members`,
      params: formDataWithoutRefkeys,
    })
      .then((response) => {
        if (response.data.success === true) {
          showToast(response.data.message, "success");
          getData();
          setFormData({
            ...formData,
            members: response?.data?.data?.team_members?.map((item) => ({
              member_id: item.id,
            })),
            members_ref: [],
          });
          navigate(-1);
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
      })
      .catch((error) => {
        if (error.response.data && error.response.data.message) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
      })
      .finally(() => {
        setOnSave(false);
      });
  };

  return (
    <Modal
      show={formStatus}
      onHide={() => navigate(-1)}
      backdrop="static"
      className="project-form-style no-border-radius-modal"
    >
      <Modal.Header closeButton style={{ backgroundColor: "#EDF2F9" }}>
        <Modal.Title className="d-flex  align-items-center pt-2 pb-2">
          <FaUserPlus size={18} className="me-2 text-dark" />
          <div className="">ADD MEMBERS</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddMemberForm
          handleFieldChange={handleFieldChange}
          formData={formData}
          formError={formError}
          onSave={onSave}
          members={members}
          handleSubmit={handleSubmit}
        />
      </Modal.Body>
    </Modal>
  );
};
AddMembersModal.propTypes = {
  show: PropTypes.string,
  assignees: PropTypes.array,
};
export default AddMembersModal;
