const defaultTaskStatusFilter = [
  "to_do",
  "in_progress",
  "in_review",
  "hold",
  "need_clarification",
  "bug",
  "reopened",
];

export default defaultTaskStatusFilter;
