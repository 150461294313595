import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import PropTypes from "prop-types";
import FileUploadItem from "./FileUploadItem";
import { showToast } from "module/common/Toast/toast";

const FileController = ({
  error,
  note,
  onChange,
  className,
  limit,
  limitFeature = false,
  limitFileSizeInMB = 0,
  limitFileType = [],
  clearFiles,
}) => {
  const [filesArray, setFilesArray] = useState([]);
  const fileInputRef = useRef(null);
  const triggerFileInput = (e) => {
    e.preventDefault();
    fileInputRef && fileInputRef.current.click();
  };

  const handleFileUpload = (e) => {
    if (e.target.files.length > 0) {
      let refFilesArray = [];
      Object.keys(e.target.files).forEach((index) => {
        if (
          !limit ||
          (limit > 0 &&
            refFilesArray.filter((item) => item.isValid).length +
              filesArray.filter((item) => item.isValid).length <
              limit)
        ) {
          let fileItem = e.target.files[index];
          let fileExtension = fileItem?.name?.split(".")?.pop()?.toLowerCase();
          let fileSizeInMB = (fileItem.size / Math.pow(1024, 2)).toFixed(2);
          let fileIsValid = true;
          let fileErrors = [];
          if (limitFileSizeInMB > 0 && fileSizeInMB > limitFileSizeInMB) {
            fileIsValid = false;
            fileErrors.push("File size is too large");
          }

          if (
            limitFileType &&
            Array.isArray(limitFileType) &&
            limitFileType.length > 0 &&
            !limitFileType.includes(fileExtension)
          ) {
            fileIsValid = false;
            fileErrors.push(
              `Invalid type, this are the allowed file types ${limitFileType.join(
                ","
              )}`
            );
          }

          refFilesArray.push({
            file: fileItem,
            sizeInMB: fileSizeInMB,
            isValid: fileIsValid,
            errors: fileErrors,
          });
        }
      });

      if (Object.keys(e.target.files).length + filesArray.length > limit) {
        showToast(
          `You have exceeded the file upload limit, the maximum limit allowed is ${limit}.`,
          "error"
        );
      }

      // reset file input
      if (fileInputRef && fileInputRef.current) {
        fileInputRef.current.value = "";
      }

      let newArrayResult = refFilesArray.concat(filesArray);
      setFilesArray(newArrayResult);

      onChange(
        newArrayResult.reduce((initalArray, item) => {
          if (item.isValid) {
            initalArray.push(item.file);
          }
          return initalArray;
        }, [])
      );
    }
  };

  const handleClearLoadedFiles = () => {
    setFilesArray([]);
  };

  useEffect(() => {
    handleClearLoadedFiles();
  }, [clearFiles]);

  return (
    <div className={`file-upload-cover ${className}`}>
      <Form.Group controlId="cashierForm.ControlInput7">
        <Form.Label>Attach File(s)</Form.Label>
        <div className="d-flex flex-row">
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={triggerFileInput}
            style={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              border: "1px dashed",
            }}
          >
            <FontAwesomeIcon icon="upload" size="xs" />
          </Button>
          <Dropdown>
            <Dropdown.Toggle
              variant="outline-secondary"
              id="dropdown-basic"
              size="sm"
              style={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                border: "1px dashed",
              }}
            >
              Upload File
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={triggerFileInput}>
                Attach from Desktop
              </Dropdown.Item>
              {!limitFeature && (
                <>
                  <Dropdown.Item href="#/action-2">
                    Attach from Cloud
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-3">
                    Attach from Documents
                  </Dropdown.Item>
                </>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Form.Control
          multiple
          type="file"
          rows={5}
          name="file"
          onChange={handleFileUpload}
          isInvalid={!!error}
          ref={fileInputRef}
          hidden
        />
        <small className="text-muted">{note}</small>
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      </Form.Group>

      {filesArray.length > 0 && (
        <Form.Group
          className="mt-3"
          style={{ width: "100%", maxWidth: "600px" }}
        >
          {filesArray.map((fileItem, key) => (
            <FileUploadItem
              fileItem={fileItem}
              key={key}
              index={key}
              setFilesArray={setFilesArray}
              onChange={onChange}
              limitFeature={limitFeature}
            />
          ))}
        </Form.Group>
      )}
    </div>
  );
};

FileController.propTypes = {
  error: PropTypes.any,
  note: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  limit: PropTypes.number,
  limitFeature: PropTypes.bool,
  limitFileSizeInMB: PropTypes.number,
  limitFileType: PropTypes.array,
};

export default FileController;
