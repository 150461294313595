import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
window.Pusher = Pusher;
const echo = new Echo({
    broadcaster: "reverb",
    key: process.env.REACT_APP_WEBSOCKET_KEY,
    wsHost: process.env.REACT_APP_WEBSOCKET_HOST,
    wsPort: process.env.REACT_APP_WEBSOCKET_PORT,
    forceTLS: process.env.REACT_APP_WEBSOCKET_FORCE_TLS,
    disableStats: true,
});
export default echo;
