import React from "react";
import { Card, Col, Placeholder, Row } from "react-bootstrap";

function ListLoading({ style, className }) {
  const number = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  return (
    <Card.Body
      style={style ? { ...style } : { height: "84.7vh", overflow: "hidden" }}
      className={`pt-1 pb-0 ${className ? className : ""}`}
    >
      {number.map((key) => (
        <Row
          key={key}
          className="mb-1 d-flex bg-light align-items-center p-2"
          style={{
            flexFlow: "row",
            minHeight: "4.5rem",
          }}
        >
          <Col lg={12} md={12} className="p-0">
            <div>
              <Placeholder
                as="p"
                animation="glow"
                className="m-0"
                style={{ height: "20px" }}
              >
                <Placeholder
                  className="w-50"
                  size="sm"
                  bg="secondary"
                  style={{ opacity: "0.01", borderRadius: "10px" }}
                />
              </Placeholder>
            </div>
            <div>
              <Placeholder
                as="p"
                animation="glow"
                className="m-0"
                style={{ height: "20px" }}
              >
                <Placeholder
                  className="w-75"
                  size="sm"
                  bg="secondary"
                  style={{ opacity: "0.01", borderRadius: "10px" }}
                />
              </Placeholder>
            </div>
            <div>
              <Placeholder
                as="p"
                animation="glow"
                className="m-0"
                style={{ height: "20px" }}
              >
                <Placeholder
                  className="w-25"
                  size="sm"
                  bg="secondary"
                  style={{ opacity: "0.01", borderRadius: "10px" }}
                />
              </Placeholder>
            </div>
          </Col>
        </Row>
      ))}
    </Card.Body>
  );
}

export default ListLoading;
