import React, { useContext, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import NavbarTop from "components/navbar/top/NavbarTop";
import NavbarVertical from "components/navbar/vertical/NavbarVertical";
import AppContext, { AuthWizardContext } from "context/Context";
import classNames from "classnames";
import { Modal } from "react-bootstrap";
import LockScreenForm from "module/Authentication/LockScreenForm";
import DeleteWarning from "module/common/Warnings/DeleteWarning";
import PasswordExpiry from "module/common/PassworExpiry/PassworExpiry";

const MainLayout = () => {
  const { hash, pathname } = useLocation();
  const isKanban = pathname.includes("kanban");
  // const isChat = pathname.includes('chat');

  const {
    config: { isFluid, navbarPosition },
  } = useContext(AppContext);

  const { user } = useContext(AuthWizardContext);

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: "start", behavior: "smooth" });
        }
      }
    }, 0);
  }, [hash]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <div className={isFluid ? "container-fluid" : "container"}>
        {(navbarPosition === "vertical" || navbarPosition === "combo") && (
          <NavbarVertical />
        )}
        <div
          className={classNames("content d-flex flex-column", {
            "pb-0": isKanban,
          })}
        >
          <NavbarTop />
          <PasswordExpiry>
            <Outlet />
          </PasswordExpiry>
        </div>
      </div>

      <Modal
        show={user && user.lockScreen}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title>Session Out!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <small>Enter your password to access the admin.</small>
          <LockScreenForm className="mt-0" />
        </Modal.Body>
      </Modal>
      <DeleteWarning />
    </>
  );
};

export default MainLayout;
