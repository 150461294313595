import { React, useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import PropTypes from "prop-types";

import { AuthWizardContext, StoreContext } from "context/Context";
import LoadingCommon from "components/common/loading-screen/LoadingCommon";
import { useLocation } from "react-router-dom";

const InitStore = ({ children }) => {
  const [loadComplete, setLoadComplete] = useState(false);
  const { user } = useContext(AuthWizardContext);
  const { addIitemToStore } = useContext(StoreContext);
  const location = useLocation();
  const actualPath = location?.pathname?.split("/")[1];
  const customizedColumnsFromStore = JSON.parse(
    localStorage.getItem(`CUSTOMIZED_COLUMNS_${actualPath?.toUpperCase()}`)
  );
  const custimizedColumnsTemp = customizedColumnsFromStore?.find((item) =>
    item?.hasOwnProperty(user?.id)
  );

  const custimizedColumns =
    custimizedColumnsTemp && Object.keys(custimizedColumnsTemp)?.length > 0
      ? custimizedColumnsTemp[user?.id]
      : [];

  const getCurrentDate = () => {
    const current = new Date();
    const currentDate = `${current.getFullYear()}-${(
      "0" +
      (current.getMonth() + 1)
    ).slice(-2)}-${("0" + current.getDate()).slice(-2)}`;
    addIitemToStore("currentDate", currentDate);
  };

  const globalConfigurations = () => {
    addIitemToStore("globalConfigurations", {
      base_currency: user?.branch?.base_currency ?? "AED",
    });
  };

  const getCustomizedColumns = () => {
    addIitemToStore(
      `CUSTOMIZED_COLUMNS_${actualPath?.toUpperCase()}`,
      custimizedColumns
    );
  };

  const init = async () => {
    if (!loadComplete) {
      getCurrentDate();
      globalConfigurations();
      getCustomizedColumns();
      setLoadComplete(true);
    }
  };

  useEffect(() => {
    init();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !loadComplete ? (
    <Container
      className="d-flex flex-row justify-content-center align-items-center"
      style={{ height: "100vh", width: "100vw" }}
    >
      <LoadingCommon />
    </Container>
  ) : (
    children
  );
};

InitStore.propTypes = {
  children: PropTypes.element.isRequired,
};

export default InitStore;
