import { FaBan } from "react-icons/fa";
import { GrInProgress } from "react-icons/gr";
import { MdOutlinePendingActions, MdVerifiedUser } from "react-icons/md";
import { RiTodoLine } from "react-icons/ri";
import { TbPlaceholder } from "react-icons/tb";
const getSprintStatusWithProps = (status, type, iconSize = 25) => {
    const statusWithColor = [
        {
            key: "planning",
            value: "#29C3BE",
            icon: <RiTodoLine color={"#29C3BE"} size={iconSize} />,
        },
        {
            key: "pending",
            value: "#808080",
            icon: <MdOutlinePendingActions size={iconSize + 1} color={"#808080"} />,
        },
        {
            key: "in_progress",
            value: "#75CDFF",
            icon: <GrInProgress size={iconSize - 3} color={"#75CDFF"} />,
        },
        {
            key: "completed",
            value: "#07bc0cb8",
            icon: <MdVerifiedUser size={iconSize} color={"#07bc0cb8"} />,
        },
        {
            key: "hold",
            value: "#FFA636",
            icon: <TbPlaceholder size={iconSize} color={"#FFA636"} />,
        },
        {
            key: "cancelled",
            value: "#eb7936",
            icon: <FaBan color={"#eb7936"} size={iconSize} />,
        },
    ];

    if (status) {
        const foundObject = statusWithColor.find((obj) => obj.key === status);
        if (type === "icon") {
            return foundObject?.icon;
        } else {
            return status ? foundObject?.value ?? "grey" : foundObject?.icon;
        }
    } else {
        if (!type) {
            return statusWithColor;
        }
    }
};

export default getSprintStatusWithProps;