import { React } from "react";
import { Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import {
  MdOutlineIncompleteCircle,
  MdOutlinePayment,
  MdOutlineQueryStats,
} from "react-icons/md";
import { GrCurrency } from "react-icons/gr";

const OtherDetailsCollapse = ({ data }) => {
  const Translate = useAxisproTranslate();

  return (
    <Row className="gy-3 border-bottom pb-3 mt-1">
      <Col sm={6}  className="mt-1">
        <div className="d-flex align-items-center">
          <MdOutlineIncompleteCircle
            size={17}
            className="me-1 text-mute"
            color="#0A6847"
          />
          <div>
            <span
              className="lead-information text-center"
              style={{ color: "rgb(97,111,166)" }}
            >
              {Translate("Client Type")} :
            </span>
            <span
              className="text-dark ms-2 fw-bold"
              style={{ fontSize: "12px" }}
            >
              {data.customer_type ? data.customer_type : "N/A"}
            </span>
          </div>
        </div>
      </Col>
      <Col sm={6} className="mt-1">
        <div className="d-flex align-items-center">
          <GrCurrency size={14} className="me-1 text-mute" color="#3FA2F6" />
          <div>
            <span
              className="lead-information text-center"
              style={{ color: "rgb(97,111,166)" }}
            >
              {Translate("Currency Code")} :
            </span>
            <span
              className="text-dark ms-2 fw-bold"
              style={{ fontSize: "12px" }}
            >
              {data.currency_code ? data.currency_code : "N/A"}
            </span>
          </div>
        </div>
      </Col>
      <Col sm={6} className="mt-1">
        <div className="d-flex align-items-center">
          <MdOutlinePayment
            size={16}
            className="me-1 text-mute"
            color="#FFA38F"
          />
          <div>
            <span
              className="lead-information text-center"
              style={{ color: "rgb(97,111,166)" }}
            >
              {Translate("Payment Terms")} :
            </span>
            <span
              className="text-dark ms-2 fw-bold"
              style={{ fontSize: "12px" }}
            >
              {data.payment_term_name ? data.payment_term_name : "N/A"}
            </span>
          </div>
        </div>
      </Col>
      <Col sm={6} className="mt-1">
        <div className="d-flex">
          <MdOutlineQueryStats
            size={17}
            className="me-1 text-mute"
            color="#E4003A"
          />
          <div>
            <span
              className="lead-information text-center"
              style={{ color: "rgb(97,111,166)" }}
            >
              {Translate("Credit Status")} :
            </span>
            <span
              className="text-dark ms-2 fw-bold"
              style={{ fontSize: "12px" }}
            >
              {data.credit_status_name ? data.credit_status_name : "N/A"}
            </span>
          </div>
        </div>
      </Col>
    </Row>
  );
};

OtherDetailsCollapse.propTypes = {
  data: PropTypes.any,
};

export default OtherDetailsCollapse;
