import React from "react";
import "./RibbonButtons.scss"; // Import your SCSS file
import RibbonButtonLoader from "./RibbonButtonLoader";
import CommonFieldEditForm from "../Forms/CommonFieldEditForm";

const RibbonButtons = ({ dataToRibbonButton }) => {
  const ribbonDataArray = dataToRibbonButton?.ribbonData || [];
  return (
    <>
      {dataToRibbonButton?.isLoading && !dataToRibbonButton?.isLoadingNeeded ? (
        <RibbonButtonLoader items={10} />
      ) : (
        <div className="btn-ribbon-group">
          {ribbonDataArray?.map((item, index) => (
            <div
              key={index}
              onClick={() => {
                item?.label !== dataToRibbonButton?.itemRef?.label &&
                  dataToRibbonButton?.handleSelect(item);
              }}
              className={`btn-ribbon-wrapper ${
                item?.label === dataToRibbonButton?.itemRef?.label
                  ? "active visible"
                  : "visible"
              }`}
            >
              <button
                className={`fw-bold btn-ribbon text-capitalize ${
                  item?.label === dataToRibbonButton?.itemRef?.label
                    ? "active"
                    : ""
                }`}
              >
                {item?.label}
              </button>
            </div>
          ))}
        </div>
      )}
      {dataToRibbonButton?.showStatusModal && (
        <CommonFieldEditForm
          dataForEditModal={dataToRibbonButton}
          IconComponent={dataToRibbonButton?.IconComponent}
        ></CommonFieldEditForm>
      )}
    </>
  );
};

export default RibbonButtons;
