const setNewFormData = (data) => {
  let formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (Array.isArray(data[key])) {
      data[key].forEach((item, index) => {
        if (item && typeof item === "object") {
          if (Object.keys(item).length > 0) {
            Object.keys(item).forEach((keyName) => {
              let keyName_ref = keyName;
              if (keyName_ref.startsWith("[")) {
                keyName_ref = keyName_ref.substring(1, keyName_ref.length);
              }
              if (keyName_ref.endsWith("]")) {
                keyName_ref = keyName_ref.substring(0, keyName_ref.length - 1);
              }
              formData.append(
                `${key}[${index}][${keyName_ref}]`,
                item[keyName]
              );
            });
          } else if (!Array.isArray(item)) {
            formData.append(`${key}[${index}]`, item);
          }
        } else {
          formData.append(`${key}[${index}]`, item);
        }
      });
    } else {
      formData.append(key, data[key]);
    }
  });
  return formData;
};

export default setNewFormData;
