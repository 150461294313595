import { React, useState } from "react";
import {} from "react-router-dom";
import axios from "axios";
import { Col, Form, Row, Modal, Button } from "react-bootstrap";
import { SaveButton } from "module/common/Buttons/CommonButton";
import { showToast } from "module/common/Toast/toast";
import removeRefData from "helpers/removeRefData";

function CommonFieldEditForm({ dataForEditModal, children, IconComponent }) {
  const [onSave, setOnSave] = useState(false);
  const [formError, setFormError] = useState({});

  const handleSubmit = () => {
    if (onSave) return;
    setOnSave(true);
    setFormError({});
    let formDataWithoutRefKeys = removeRefData(dataForEditModal?.data);

    axios({
      method: "post",
      url: "crm/" + dataForEditModal?.apiUrl,
      headers: {
        Accept: "application/json",
      },
      data: formDataWithoutRefKeys,
    })
      .then((response) => {
        if (response?.data?.success === true) {
          showToast(response?.data?.message, "success");
          if (dataForEditModal?.setIsLoadingNeeded) {
            dataForEditModal?.setIsLoadingNeeded(true);
          }
          if (dataForEditModal?.isLoadingNeeded !== false) {
            dataForEditModal?.getData();
          }
          dataForEditModal?.close();
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          const validation_error = error?.response?.data?.data?.errors
            ? error?.response?.data?.data?.errors
            : null;
          validation_error && setFormError({ ...validation_error });
          showToast(error?.response?.data?.message, "error");
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      });
  };

  return (
    <Modal
      show
      onHide={dataForEditModal?.close}
      size="md"
      backdrop="static"
      className="no-border-radius-modal"
    >
      <Modal.Header closeButton>
        {IconComponent && (
          <IconComponent size={22} className="me-2 text-dark" />
        )}
        <span className="custom-module-header" style={{ fontSize: "15px" }}>
          {dataForEditModal?.heading}
        </span>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {children && (
            <Row>
              <Col>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput3"
                >
                  <Form.Label>{dataForEditModal?.label}</Form.Label>
                  {children && children}
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-flex p-0 m-0"
                  >
                    <span className="ms-auto">
                      {formError?.stage_to || formError?.status_id}
                    </span>
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Col>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="description"
                    onChange={dataForEditModal?.handleFieldChange}
                  />
                </Col>
                <Form.Control.Feedback
                  type="invalid"
                  className="d-flex p-0 m-0"
                >
                  <span className="ms-auto">{formError?.description}</span>
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Form.Group className="d-flex justify-content-end gap-1 p-3 border-top">
        <SaveButton
          handleSubmit={handleSubmit}
          onSave={onSave}
          id={dataForEditModal?.id}
        />
        {!onSave && (
          <Button
            variant={"danger"}
            size={"sm"}
            onClick={dataForEditModal?.close}
          >
            Cancel
          </Button>
        )}
      </Form.Group>
    </Modal>
  );
}

export default CommonFieldEditForm;
