import React from "react";
import PropTypes from "prop-types";
import { Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

const AdvanceTable = ({
  tablebodyClassName,
  headerStyle,
  getTableProps,
  headers,
  page,
  prepareRow,
  headerClassName,
  rowClassName,
  tableprops,
}) => {
  const navigate = useNavigate();
  const updatedHeaderStyle = { ...headerStyle };
  const location = useLocation();

  function replaceRouteId(route, routeId, isStore = false) {
    const regex = /routeId/gi;
    const generatedRoute = route.replace(regex, routeId);
    if (isStore) {
      localStorage.setItem(
        "actualRoute",
        `${location.pathname}${location.search}`
      );
    }
    return generatedRoute;
  }

  return (
    <Table
      {...getTableProps(tableprops)}
      hover
      className={`advance-table-styles`}
    >
      <thead className={`${headerClassName} bg-200`}>
        <tr className="custom-table-tr" style={{ width: "100%" }}>
          {headers.map((column, index) => (
            <th
              key={index}
              {...column.getHeaderProps(
                column.getSortByToggleProps(column.headerProps)
              )}
              style={{
                updatedHeaderStyle,
                width: column?.width ? column?.width : "",
              }}
              className={
                index === headers.length - 1 ? "sticky-column-header" : ""
              }
            >
              {column.render("Header")}
              {column.canSort ? (
                column.isSorted ? (
                  column.isSortedDesc ? (
                    <span className="sort desc" />
                  ) : (
                    <span className="sort asc" />
                  )
                ) : (
                  <span className="sort" />
                )
              ) : (
                ""
              )}
            </th>
          ))}
        </tr>
      </thead>
      {page?.length > 0 ? (
        <tbody className={tablebodyClassName}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                key={i}
                className={`${rowClassName} align-middle white-space-nowrap advance-table-row`}
                {...row.getRowProps()}
                style={{ width: "100%", cursor: "pointer" }}
              >
                {row.cells.map((cell, index) => {
                  return (
                    <td
                      key={index}
                      {...cell.getCellProps(cell.column.cellProps)}
                      className={`py-1 ${
                        index === row.cells.length - 1 ? "sticky-column" : ""
                      }`}
                      onClick={() =>
                        cell?.column?.retrieveData
                          ? cell?.column?.retrieveData(row?.original)
                          : cell?.column?.Header === "" &&
                            !cell?.column?.routeTo
                          ? ""
                          : cell?.column?.routeTo
                          ? row?.original?.id
                            ? navigate(
                                replaceRouteId(
                                  cell.column.routeTo,
                                  row?.original?.id
                                )
                              )
                            : ""
                          : cell?.column?.customRouteTo
                          ? row.original[cell.column.customRouteTo.key]
                            ? navigate(
                                replaceRouteId(
                                  cell.column.customRouteTo.route,
                                  row.original[cell.column.customRouteTo.key],
                                  cell.column.customRouteTo.islocallyStored
                                )
                              )
                            : ""
                          : ""
                      }
                    >
                      <div
                        style={{ whiteSpace: "break-spaces" }}
                        className={`${
                          !cell?.column?.Header
                            ? `d-flex align-items-center justify-content-end`
                            : ``
                        }`}
                      >
                        {cell.render("Cell")}
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      ) : (
        <tbody>
          <td
            style={{ backgroundColor: "#e307272e" }}
            className="text-center  p-3 fs--1 "
            rowSpan={headers?.length ?? 12}
            colSpan={headers?.length ?? 12}
          >
            No Data Available Let's Add It 🙂{" "}
          </td>
        </tbody>
      )}
    </Table>
  );
};
AdvanceTable.propTypes = {
  getTableProps: PropTypes.func,
  headers: PropTypes.array,
  page: PropTypes.array,
  prepareRow: PropTypes.func,
  headerClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  tableprops: PropTypes.object,
  responsive: PropTypes.bool,
};

export default AdvanceTable;
