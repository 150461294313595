import React from "react";
import { Card } from "react-bootstrap";
import PropTypes from "prop-types";

import GaugeEChart from "module/common/Charts/GaugeEChart";
import DashboardTileLoader from "module/common/Loading/DashboardTileLoader";

function TaskCompletion({ dashboardData, loading }) {
  return (
    <Card className="card-main rounded-0 mt-2">
      {!loading ? (
        <Card.Body>
          <Card.Title className="d-flex justify-content-start fw-bold">
            Tasks Completion (%)
          </Card.Title>
          <div className="d-flex justify-content-center mt-3">
            <GaugeEChart
              height="190px"
              width="190px"
              value={dashboardData.tasks_complete_percentage}
              borderRadius="50%"
            />
          </div>
        </Card.Body>
      ) : (
        <Card.Body className="p-4">
          <DashboardTileLoader column={2} />
        </Card.Body>
      )}
    </Card>
  );
}

TaskCompletion.propTypes = {
  dashboardData: PropTypes.any,
  loading: PropTypes.bool,
};

export default TaskCompletion;
