import {
  MdBackHand,
  MdCancel,
  MdLockOpen,
  MdOutlineDoneOutline,
} from "react-icons/md";
const getProjectStatusWithProps = (status, type, iconSize = 25) => {
  const statusWithColor = [
    {
      key: "open",
      value: "#0275d8",
      icon: <MdLockOpen size={iconSize} className="mb-1 text-primary" />,
    },
    {
      key: "completed",
      value: "#5cb85c",
      icon: (
        <MdOutlineDoneOutline
          size={iconSize}
          className="mb-1 text-success"
        />
      ),
    },
    {
      key: "hold",
      value: "#f0ad4e",
      icon: (
        <MdBackHand size={iconSize - 3} className="mb-1 text-warning" />
      ),
    },
    {
      key: "cancelled",
      value: "#d9534f",
      icon: <MdCancel size={iconSize} className="mb-1 text-danger" />,
    },
  ];

  if (status) {
    const foundObject = statusWithColor.find((obj) => obj.key === status);
    if (type === "icon") {
      return foundObject?.icon;
    } else {
      return status ? foundObject?.value ?? "grey" : foundObject?.icon;
    }
  } else {
    if (!type) {
      return statusWithColor;
    }
  }
};

export default getProjectStatusWithProps;
